import { faker } from '@faker-js/faker';
faker.setLocale('en_IND');

export default class TemplatesConfig {
  static samplingRetries = 5;

  static classes = [
    {
      id: 'WildString',
      prefix: '',
      isNumber: false,
      generate: (except: string[]) => 'TODO',
      description: 'Any string',
    },
    {
      id: 'SingleDigit',
      prefix: 'SingleDigit',
      isNumber: true,
      generate: (except: number[]) => {
        // If possible, choose a random number between 0 and 9 which is not in except.
        let random = Math.floor(Math.random() * 10);
        let samplingRetries = 0;
        while (except.includes(random) && samplingRetries < TemplatesConfig.samplingRetries) {
          samplingRetries += 1;
          random = Math.floor(Math.random() * 10);
        }
        return random;
      },
      description: 'Any single digit number [0, 9]',
    },
    {
      id: 'DoubleDigit',
      prefix: 'DoubleDigit',
      isNumber: true,
      generate: (except: number[]) => {
        // If possible, choose a random number between 10 and 99 which is not in except.
        let random = Math.floor(Math.random() * 90) + 10;
        let samplingRetries = 0;
        while (except.includes(random) && samplingRetries < TemplatesConfig.samplingRetries) {
          samplingRetries += 1;
          random = Math.floor(Math.random() * 90) + 10;
        }
        return random;
      },
      description: 'Any double digit number [10, 99]',
    },
    {
      id: 'TillDoubleDigit',
      prefix: 'TillDoubleDigit',
      isNumber: true,
      generate: (except: number[]) => {
        // If possible, choose a random number between 0 and 99 which is not in except.
        let random = Math.floor(Math.random() * 99);
        let samplingRetries = 0;
        while (except.includes(random) && samplingRetries < TemplatesConfig.samplingRetries) {
          samplingRetries += 1;
          random = Math.floor(Math.random() * 99);
        }
        return random;
      },
      description: 'Any number till double digit [0, 99]',
    },
    {
      id: 'TripleDigit',
      prefix: 'TripleDigit',
      isNumber: true,
      generate: (except: number[]) => {
        // If possible, choose a random number between 100 and 999 which is not in except.
        let random = Math.floor(Math.random() * 900) + 100;
        let samplingRetries = 0;
        while (except.includes(random) && samplingRetries < TemplatesConfig.samplingRetries) {
          samplingRetries += 1;
          random = Math.floor(Math.random() * 900) + 100;
        }
        return random;
      },
      description: 'Any triple digit number [100, 999]',
    },
    {
      id: 'TillTripleDigit',
      prefix: 'TillTripleDigit',
      isNumber: true,
      generate: (except: number[]) => {
        // If possible, choose a random number between 0 and 999 which is not in except.
        let random = Math.floor(Math.random() * 999);
        let samplingRetries = 0;
        while (except.includes(random) && samplingRetries < TemplatesConfig.samplingRetries) {
          samplingRetries += 1;
          random = Math.floor(Math.random() * 999);
        }
        return random;
      },
      description: 'Any number till triple digit [0, 999]',
    },
    {
      id: 'Name',
      prefix: 'Name',
      isNumber: false,
      generate: (except: string[]) => {
        // If possible, choose a random number between 0 and 999 which is not in except.
        let random = faker.name.firstName();
        console.log(random);
        let samplingRetries = 0;
        while (except.includes(random) && samplingRetries < TemplatesConfig.samplingRetries) {
          samplingRetries += 1;
          random = faker.name.firstName();
        }
        return random;
      },
      description: 'Any name',
    },
  ];

  static functions = [
    {
      id: 'AddFn',
      prefix: 'Add',
      evaluate: (args: number[]) => args.reduce((a, b) => a + b, 0).toString(),
      validNumParams: (numParams: number) => numParams > 1,
      description: 'Add all the numbers, can supply any number of arguments, ',
    },
    {
      id: 'SubtractFn',
      prefix: 'Subtract',
      evaluate: (args: number[]) => (args[0] - args[1]).toString(),
      validNumParams: (numParams: number) => numParams === 2,
      description: 'Subtract the second number from the first, can have only 2 args',
    },
    {
      id: 'MultiplyFn',
      prefix: 'Multiply',
      evaluate: (args: number[]) => args.reduce((a, b) => a * b, 1).toString(),
      validNumParams: (numParams: number) => numParams > 1,
      description: 'Multiply all the numbers, can supply any number of arguments',
    },
    {
      id: 'DivideFn',
      prefix: 'Divide',
      evaluate: (args: number[]) => (args[0] / args[1]).toString(),
      validNumParams: (numParams: number) => numParams === 2,
      description: 'Divide the first number by the second, can have only 2 args',
    },
    {
      id: 'RemainderFn',
      prefix: 'Remainder',
      evaluate: (args: number[]) => (args[0] % args[1]).toString(),
      validNumParams: (numParams: number) => numParams === 2,
      description: 'Find the remainder of the first number divided by the second, can have only 2 args',
    },
    {
      id: 'ReduceFractionFn',
      prefix: 'ReduceFraction',
      evaluate: (args: number[]) => {
        const a = args[0];
        const b = args[1];
        const gcd = (a: number, b: number): number => (b ? gcd(b, a % b) : a);
        const divisor = gcd(a, b);
        return `${a / divisor}/${b / divisor}`;
      },
      validNumParams: (numParams: number) => numParams === 2,
      description: 'Reduce the fraction to its simplest form, can only have 2 args',
    },
  ];
}
