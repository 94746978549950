import React from 'react';
import { IComprehension } from '../../../../lambda/question/question-schema';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import { AppContext } from '../../core/context';
import { handleComprehensionFormSubmission } from '../../utils/comprehension';
import { ErrorMessage } from '../common/error-message';

export default function ImageConsistentForm({
  comprehension,
  refreshComprehension,
}: {
  comprehension: IComprehension;
  refreshComprehension: () => void;
}) {
  const [formError, setFormError] = React.useState('');
  const [edit, setEdit] = React.useState(false);
  const { state } = React.useContext(AppContext);

  let imageConsistent: boolean | undefined = comprehension.imageConsistent;
  let imageConsistentAuthor: string | undefined = comprehension.imageConsistentAuthor;
  let imageConsistentDescription: string | undefined = comprehension.imageConsistentDescription;
  const userEmail = state.userInfo?.info.email;

  return (
    <div className="row border p-4 border-secondary">
      <ErrorMessage error={formError} />
      <div className="container">
        <div className="row">
          <div className="col-4">
            <h5>Image Consistent</h5>
          </div>
          <div className="col-7">
            {imageConsistent === undefined ? (
              <h6 className="text-warning">Image Consistency Unknown</h6>
            ) : (
              <h6 className="text-success">
                Image Consistent: {String(imageConsistent)}, marked by {imageConsistentAuthor},{' '}
                {imageConsistentDescription}
              </h6>
            )}
          </div>
          <div className="col-1">
            <button type="button" className="btn btn-primary" onClick={() => setEdit(!edit)}>
              Edit
            </button>
          </div>
        </div>
        {edit && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setFormError('');
              const formData = Object.fromEntries(new FormData(e.target as HTMLFormElement));
              const imageConsistent = formData.imageConsistent as string;
              const reason = formData.reason as string;
              if (imageConsistent === 'default') {
                setFormError('Please select an option');
                return;
              }
              const imageConsistentBool = imageConsistent === 'true';
              if (!imageConsistentBool && !reason) {
                setFormError('Please provide a reason');
                return;
              }

              Cloud.post(
                {
                  action: PublicConstants.ACTION_UPDATE_COMPREHENSION,
                  // @ts-ignore
                  data: {
                    id: comprehension._id,
                    update: PublicConstants.UPDATE_IMAGE_CONSISTENCY,
                    imageConsistent: imageConsistentBool,
                    imageConsistentAuthor: userEmail,
                    imageConsistentDescription: reason,
                  },
                },
                PublicConstants.API_QUESTION,
              ).then((response) => {
                handleComprehensionFormSubmission(response, refreshComprehension, setFormError, setEdit);
              });
            }}
          >
            <div className="row">
              <div className="col-12">
                <label htmlFor="imageConsistent" className="form-label">
                  Image Consistent (If question has all images or if some are missing)
                </label>
                <select
                  className="form-select"
                  id="imageConsistent"
                  name="imageConsistent"
                  defaultValue={'default'}
                  required
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="IncorrectReason" className="form-label">
                  Details (Required if question has figures missing)
                </label>
                <textarea
                  className="form-control"
                  id="IncorrectReason"
                  rows={2}
                  placeholder="The diagram of the triangle is missing ..."
                  name="reason"
                ></textarea>
              </div>
            </div>
            <button type="submit" className="col-12 mt-3 btn btn-primary">
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
