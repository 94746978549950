/*
 * --------------------------------------------------------------------------- *
 * File: upload.tsx                                                            *
 * Project: faculty-dashboard                                                  *
 * Created Date: 18 Feb 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Sat Mar 11 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import { AppContext } from '../../core/context';

const reader = new FileReader();

function Upload() {
  const { state, setState } = React.useContext(AppContext);

  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [refreshCount, setRefreshCount] = useState(0);
  const [showUploadXlsx, setShowUploadXlsx] = useState(false);
  const [chapterList, setChapterList] = useState<string[]>([]);
  const [subjectList, setSubjectList] = useState<string[]>([]);
  const [selectedChapter, setSelectedChapter] = useState<string>('');
  const [newChapter, setNewChapter] = useState<string>('');

  const config = useRef<any>({});

  useEffect(() => {
    Cloud.post(
      { action: PublicConstants.ACTION_GET_BATCH_LIST, data: { subject: state.userInfo?.info.subject } },
      PublicConstants.API_CONTENT,
    ).then((response) => {
      if (response.success) {
        if (response.value && response.value.length) {
          const sortedList = response.value.sort((a: any, b: any) => {
            return moment(a.date).isBefore(b.date) ? 1 : -1;
          });
          setUploadedFiles(sortedList);
        } else {
          setUploadedFiles([]);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCount]);

  useEffect(() => {
    Cloud.getConfig()
      .then((response) => {
        config.current = response;
      })
      .then((x) => setRefreshCount(refreshCount + 1));
    if (state.userInfo?.info.subject) {
      Cloud.getConfig()
        .then((response) => {
          return response.subjectChapters[state.userInfo?.info.subject || ''];
        })
        .then((subjectChapters) => {
          const apiResponse = Cloud.post(
            { action: PublicConstants.ACTION_GET_CHAPTERS, data: { subject: state.userInfo?.info.subject } },
            PublicConstants.API_CONTENT,
          );
          const sc = new Promise((resolve) => {
            return resolve(subjectChapters || []);
          });
          return Promise.all([sc, apiResponse]);
        })
        .then(([subjectChapters, response]) => {
          if (response.success) {
            const uploadedChapters = response.value.map((sc: string) => sc.split('::')[1]) || [];
            // @ts-ignore
            const newChapterList = subjectChapters.filter((c) => !uploadedChapters.includes(c));
            setChapterList(newChapterList);
          }
        });
    } else {
      setChapterList([]);
    }
    setSelectedChapter('');
    setNewChapter('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const upload = async (f: File) => {
    await new Promise((resolve) => {
      reader.onload = async (x) => {
        await Cloud.uploadFile(f.name, state.userInfo?.info.subject || '', selectedChapter, x.target?.result);
        resolve('');
      };
      reader.readAsArrayBuffer(f);
    });
    setRefreshCount(refreshCount + 1);
  };

  const onDrop = async (acceptedFiles: File[]) => {
    for (let i = 0; i < acceptedFiles.length; i++) {
      await upload(acceptedFiles[i]);
    }
    setShowUploadXlsx(false);
  };

  const uploadTranslationFiles = async (files: File[], { batchId }: any) => {
    await new Promise((resolve) => {
      reader.onload = async (x) => {
        await Cloud.uploadTranslationFile(batchId, x.target?.result);
        resolve('');
      };
      reader.readAsArrayBuffer(files[0]);
    });
  };

  const downloadXlsx = async (batchId: string, fileName: string) => {
    const cr = await Cloud.post(
      { action: PublicConstants.ACTION_GET_XLSX_DOWNLOAD_URL, data: { batchId } },
      PublicConstants.API_CONTENT,
    );
    if (cr.success) {
      const link = document.createElement('a');
      link.href = cr.value;
      link.download = fileName;
      link.target = '_blank';
      link.click();
      document.removeChild(link);
    }
  };

  function clearGraph(batchId: any) {
    Cloud.post({ action: PublicConstants.ACTION_CLEAR_GRAPH, data: { batchId } }, PublicConstants.API_CONTENT).then(
      (response) => {
        if (response.success) {
          alert('Graph cleared successfully');
        }
      },
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 pt-4">
          <h1>Upload Content</h1>
          <hr />
        </div>
        <div className="col-12 right">
          <button className="btn btn-secondary wide my-3" onClick={() => setShowUploadXlsx(!showUploadXlsx)}>
            {showUploadXlsx ? 'Hide Upload XLSX' : 'Upload XLSX'}
          </button>
        </div>
        {showUploadXlsx && (
          <>
            <div className="col-12">
              You have permissions to load content for subject: {state.userInfo?.info.subject}. Choose the appropriate
              chapter from the list below or create a new chapter by typing in the name. Upload the XLSX in the provided
              format after the chapter is selected.
            </div>
            <div className="col-md-4 col-lg-3">
              <label>Chapter</label>
              <Select
                options={chapterList.map((c) => ({ value: c, label: c }))}
                value={{ value: selectedChapter, label: selectedChapter }}
                onChange={(selectedOption) => setSelectedChapter(selectedOption?.value || '')}
              />
            </div>
            <div className="col-12">
              {selectedChapter ? (
                <Dropzone onDrop={onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="p-5 my-2 border border-black border-rounded click">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className="my-2 px-12 py-12 border-black text-center">
                          Drag and drop the content XLSX files here, or click to select files
                        </p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              ) : (
                <p>Please select subject and chapter</p>
              )}
            </div>
            <hr />
          </>
        )}
      </div>
      <div className="row">
        <div className="col-12">
          <h3>Uploaded Files</h3>
          <hr />
          <div className="right">
            <button className="btn btn-secondary btn-sm" onClick={() => setRefreshCount(refreshCount + 1)}>
              Refresh
            </button>
          </div>
        </div>

        <div className="col-12 py-2">
          <table className="table">
            <thead className="bg-light border">
              <tr>
                <th>Subject</th>
                <th>Chapter</th>
                <th>Xlsx File</th>
                <th>Dependency</th>
                <th>Confirm</th>
                <th>State</th>
              </tr>
            </thead>
            <tbody>
              {uploadedFiles.map((uf: any, i: number) => (
                <tr key={i} className="border">
                  <td>{uf.subject}</td>
                  <td>{uf.chapter}</td>
                  <td>
                    {uf.status === 'Loaded' && (
                      <>
                        <button
                          className="btn btn-sm btn-outline-secondary"
                          onClick={() => downloadXlsx(uf.batchId, uf.fileName)}
                          data-bs-toggle="tooltip"
                          title={'Download XLSX'}
                        >
                          <i className="bi bi-save"></i>
                        </button>
                        &nbsp;
                        <Dropzone onDrop={(files) => uploadTranslationFiles(files, { batchId: uf.batchId })}>
                          {({ getRootProps, getInputProps }) => (
                            <button
                              {...getRootProps()}
                              className="btn btn-sm btn-outline-secondary"
                              data-bs-toggle="tooltip"
                              title={'Update XLSX'}
                            >
                              <input {...getInputProps()} />
                              <i className="bi bi-cloud-upload"></i>
                            </button>
                          )}
                        </Dropzone>
                      </>
                    )}
                  </td>
                  {/* <td>{moment(uf.createTs).format('YYYY-MM-DD HH:mm:ss')}</td> */}
                  <td>
                    {uf.status === 'Loaded' ? (
                      <>
                        <button
                          className="btn btn-sm btn-outline-secondary mx-1"
                          data-bs-toggle="tooltip"
                          title={'Edit Graph'}
                          onClick={() => {
                            setState({ ...state, showNow: PublicConstants.SHOW_NOW_GRAPH, batchId: uf.batchId });
                          }}
                        >
                          <i className="bi bi-snow"></i>
                        </button>
                        <button
                          className="btn btn-sm btn-outline-secondary mx-1"
                          data-bs-toggle="tooltip"
                          title={'Clear Graph'}
                          onClick={() => {
                            clearGraph(uf.batchId);
                          }}
                        >
                          <i className="bi bi-x"></i>
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td>
                    {uf.status === 'Loaded' && (
                      <button
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() =>
                          setState({
                            ...state,
                            showNow: PublicConstants.SHOW_NOW_CONFIRM_CONTENT_HTML,
                            batchId: uf.batchId,
                          })
                        }
                      >
                        <i className="bi bi-file-check"></i>
                      </button>
                    )}
                  </td>
                  <td data-bs-toggle="tooltip" title={uf.errorMessage}>
                    {uf.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Upload;
