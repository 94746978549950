/*
 * --------------------------------------------------------------------------- *
 * File: template-question.tsx                                                 *
 * Project: faculty-dashboard                                                  *
 * Created Date: 02 Mar 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Mon Mar 13 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import invariant from 'tiny-invariant';
import QuestionEditor from '../editor/QuestionEditor';
import parse from 'html-react-parser';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import Select from 'react-select';

import makeAnimated from 'react-select/animated';
import { ErrorMessage } from '../common/error-message';
import { AppContext } from '../../core/context';
import useConcepts from '../../hooks/useConcepts';
import useKatex from '../../hooks/useKatex';
import { ConvertToLexicalHTML } from '../editor/Editor';
import { useKatexStore, useTemplateStore } from '../../store';
import {
  castChunksToQuestion,
  checkTemplateQuestion,
  chunkify,
  ChunkType,
  conceptsCanopy,
  evaluateFunctions,
  QuestionType,
  validateConceptsIndependent,
} from '../../utils/utils';
import useSubjectChapters from '../../hooks/useSubjectsChapters';
import { TranslatedQuestion } from '../question/schema';
import TemplatesConfig from './template-classes';
import { substituteTemplate } from '../../utils/questions';
import RenderQuestion from '../question/question/render-question';
import { WarnMessage } from '../common/warn-message';

export const animatedComponents = makeAnimated();

const calculationDifficultyOptions = [
  { value: 'default', label: 'Select Difficulty' },
  { value: '1', label: '< 15 seconds calculation' },
  { value: '2', label: '< 1 min calculation' },
  { value: '3', label: '< 3 min calculation' },
  { value: '4', label: '< 10 min calculation' },
  { value: '5', label: '> 10 min calculation' },
];

export default function TemplateQuestion() {
  const [contentHTML, setContentHTML] = useState<string>('');
  const { state: userState } = React.useContext(AppContext);
  const [numImages, setNumImages] = useState<number>(0);
  const reRenderKatex = useKatexStore((state) => state.reRenderKatex);

  const userEmail = userState.userInfo?.info.email;
  const possibleParameterValues = useTemplateStore((state) => state.possibleParameterValues);
  const setPossibleParameterValues = useTemplateStore((state) => state.setPossibleParameterValues);
  const parameters = useTemplateStore((state) => state.parameters);
  const setParameters = useTemplateStore((state) => state.setParameters);
  const formulae = useTemplateStore((state) => state.formulae);
  const setFormulae = useTemplateStore((state) => state.setFormulae);
  const [chunks, setChunks] = useState<Map<ChunkType, string>>(new Map());
  const [numEquations, setNumEquations] = useState<number>(0);
  const [selectedConceptsErrors, setSelectedConceptsErrors] = React.useState<string[]>([]);
  const [selectedOptionsArray, setSelectedOptionsArray] = React.useState<{ value: string; label: string }[][]>([]);
  const { concepts, reachableNodes, weights, conceptsFetchError, dropDownOptions: options } = useConcepts();
  // Error for number of equation
  const [numEquationError, setNumEquationError] = React.useState('');
  const [questionType, setQuestionType] = React.useState<QuestionType>('scq');
  const [displayIndex, setDisplayIndex] = React.useState<number>(-1);
  const [chunksValid, setChunksValid] = React.useState<boolean>(false);
  const [chunksError, setChunksError] = React.useState<string>('');
  const [showComplexityWarning, setShowComplexityWarning] = React.useState<boolean>(false);
  const [editParameterIndex, setEditParameterIndex] = React.useState<number>(-1);

  useEffect(() => {
    if (isNaN(numEquations) || numEquations <= 0) return;
    const result = [];
    for (let i = 0; i < numEquations; i++) {
      result.push([]);
    }
    setSelectedOptionsArray(result);

    // Also set the errors to empty.
    setSelectedConceptsErrors(new Array(numEquations).fill(''));
  }, [numEquations]);

  useEffect(() => {
    if (!isNaN(numEquations) && numEquations > 0) {
      setSelectedConceptsErrors(new Array(numEquations).fill(''));
    }

    selectedOptionsArray.forEach((selectedOptions, index) => {
      const error = validateConceptsIndependent(selectedOptions, reachableNodes);
      if (error) {
        setSelectedConceptsErrors((prev) => {
          prev[index] = error;
          return prev;
        });
      }
    });
  }, [selectedOptionsArray, concepts, reachableNodes, numEquations]);

  const handleChangeEq = (options: any, idx: number) => {
    // Check the validity and if not show the error and disable the submit button.
    const newSelectedOptions = [...selectedOptionsArray];
    newSelectedOptions[idx] = options;
    console.log(newSelectedOptions);
    setSelectedOptionsArray(newSelectedOptions);
  };

  useEffect(() => {
    console.log('running useEffect', questionType);
    const { chunks, parameters: params, formulae: form } = chunkify(contentHTML);
    setParameters(params);
    setFormulae(form);
    setChunks(chunks);

    const error = checkTemplateQuestion(chunks, params, form, questionType);
    if (error) {
      setChunksValid(false);
      setChunksError(error);
      return;
    }

    setChunksValid(true);
    setChunksError('');
  }, [contentHTML, setParameters, setFormulae, questionType]);

  // Subjects and Chapters Code.
  const [subChapTypeError, setSubChapTypeError] = React.useState<string>('');
  const { subjectList, chapterList } = useSubjectChapters();

  const questionTypes = [
    { value: 'scq', label: 'Single Choice Correct' },
    { value: 'mcq', label: 'Multiple Choice Correct' },
    { value: 'subjective', label: 'Subjective' },
    { value: 'integerType', label: 'Integer Answer' },
    { value: 'assertionReason', label: 'Assertion Reasoning' },
    { value: 'fillInTheBlanks', label: 'Fill in the Blanks' },
    { value: 'matrixMatch', label: 'Matrix Match' },
    { value: 'trueFalse', label: 'True False' },
  ];

  // Concepts Code.
  const [selectedOptions, setSelectedOptions] = React.useState<{ value: string; label: string }[]>([]);
  const [conceptsError, setConceptsError] = React.useState('');

  // Whenever selected options change, verify that their is no error,
  // i.e. no child parent concepts are together.
  useEffect(() => {
    setConceptsError('');
    const error = validateConceptsIndependent(selectedOptions, reachableNodes);
    if (error) {
      setConceptsError(error);
      return;
    }

    const equationConcepts = selectedOptionsArray.map((ed) => ed.map((c) => c)).flat();

    const equationConceptsCanopy = conceptsCanopy(equationConcepts, reachableNodes);
    for (let i = 0; i < equationConceptsCanopy.length; i++) {
      // In the selectedOptions atleast one should be an ancestor of the concepts3[i].
      let found = false;
      for (let j = 0; j < selectedOptions.length; j++) {
        if (reachableNodes && reachableNodes[equationConceptsCanopy[i].value]?.includes(selectedOptions[j].value)) {
          found = true;
          break;
        }
      }
      if (!found) {
        setConceptsError(
          `Concept ${equationConceptsCanopy[i].label} is not included in any of the selected concepts. Please select it or one of its ancestors.`,
        );
        return;
      }
    }
  }, [selectedOptions, selectedOptionsArray, concepts, reachableNodes]);

  const handleChange = (options: any) => {
    // Check the validity and if not show the error and disable the submit button.
    setSelectedOptions(options);
  };

  const saveQuestion = async (e: any) => {
    // Invoke an API to add to MongoDB
    e.preventDefault();
    const resp = castChunksToQuestion(chunks, questionType);
    if (resp instanceof Error) {
      alert(resp.message);
      return;
    }

    if (parameters.length === 0) {
      alert('The question has no template parameters');
      return;
    }

    if (possibleParameterValues.length === 0) {
      alert('The question has no possible parameter values');
      return;
    }

    const formData = Object.fromEntries(new FormData(e.target as HTMLFormElement));
    const subject = formData.subject;
    const chapter = formData.chapter;
    const numEquations = Number(formData.numEquations);
    const numEquationsDescription = formData.equationsDescription;
    // Check obvious errors
    if (subject === 'default' || chapter === 'default') {
      setSubChapTypeError('Please select a subject, chapter and type');
      return;
    }
    setSubChapTypeError('');

    if (numEquations < 0) {
      setNumEquationError('Number of equations cannot be negative');
      return;
    }
    if (isNaN(numEquations)) {
      setNumEquationError(`${formData.numEquations} is not a valid number`);
      return;
    }
    if (numEquationsDescription === '') {
      setNumEquationError('Please describe the number of equations');
      return;
    }
    setNumEquationError('');

    // Parse the dom and see if number of images match
    {
      const doc = new DOMParser().parseFromString(contentHTML, 'text/html');
      const images = doc.getElementsByTagName('img');
      if (images.length !== numImages) {
        alert(`Number of equations (${numEquations}) does not match number of images (${images.length})`);
        return;
      }
    }

    if (selectedOptions.length === 0) {
      setConceptsError('Please select concepts');
      return;
    }
    setConceptsError('');
    const concepts = selectedOptions.map((so) => ({
      id: so.value,
      label: so.label,
      weight: [{ weight: weights[so.value], algorithm: '1 + sum-over-children' }],
    }));

    const equationsDescription: {
      description: string;
      concepts: { id: string; label: string; weight: { weight: number; algorithm: string }[] }[];
    }[] = [];
    for (let i = 0; i < numEquations; i++) {
      equationsDescription.push({
        description: formData[`equations${i}`] as string,
        concepts: selectedOptionsArray[i].map((c) => {
          return {
            id: c.value,
            label: c.label,
            weight: [
              {
                weight: weights[c.value],
                algorithm: '1 + sum-over-children',
              },
            ],
          };
        }),
      });
    }

    console.log({ possibleParameterValues });
    const response = await Cloud.post(
      {
        action: PublicConstants.ACTION_ADD_TEMPLATE_QUESTION,
        data: {
          parameters,
          formulae,
          content: resp,
          parameterValues: possibleParameterValues,
          subject,
          chapter,
          type: questionType,
          numEquations,
          equationsDescription,
          concepts,
          author: userEmail,
        },
      },
      PublicConstants.API_QUESTION,
    );

    console.log({ response });
    if (response.success) {
      alert('Question added successfully');
      setParameters([]);
      setFormulae([]);
      setPossibleParameterValues([]);
      setContentHTML('');
      setShowComplexityWarning(false);
    } else {
      alert('Error adding question');
    }
  };

  let viewQuestion: TranslatedQuestion | null | Error = null;
  if (displayIndex !== -1 && possibleParameterValues[displayIndex]) {
    viewQuestion = castChunksToQuestion(chunks, questionType);
    if (!(viewQuestion instanceof Error)) {
      let parameterMap: { [key: string]: string } = {};
      let evalMap: { [key: string]: string } = {};
      for (let i = 0; i < parameters.length; i++) {
        parameterMap[parameters[i].name] = possibleParameterValues[displayIndex].values[i];
      }
      for (let i = 0; i < formulae.length; i++) {
        evalMap[formulae[i]] = possibleParameterValues[displayIndex].evals[i];
      }
      viewQuestion = substituteTemplate(viewQuestion, parameterMap, evalMap);
    }
  }

  console.log(formulae);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 pt-4">
          <h1>Template Question</h1>
          <hr />
          {chunksError && <ErrorMessage error={chunksError} />}
          {showComplexityWarning && <WarnMessage warning="Don't forget to specify the complexity in all variations" />}
          {editParameterIndex !== -1 && (
            <>
              <div
                className="modal w-full"
                style={editParameterIndex !== -1 ? { display: 'block' } : { display: 'none' }}
              >
                <form className="modal-content modal-animate">
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => setEditParameterIndex(-1)}
                  ></button>
                  <div className="container p-4 w-full">
                    <h3>Edit Parameters {editParameterIndex + 1}</h3>
                    <div className="row" style={parameters.length ? {} : { display: 'none' }}>
                      {possibleParameterValues[editParameterIndex].values.map((ifv, index) => {
                        return (
                          <div className="col-4" key={index}>
                            <label htmlFor="FormControlInput1" className="form-label p-0 m-0">
                              {parameters[index].name}
                            </label>
                            <input
                              type="text"
                              className="form-control p-1 mt-0"
                              placeholder={parameters[index].name}
                              value={ifv}
                              onChange={(e) => {
                                possibleParameterValues[editParameterIndex].values[index] = e.target.value;
                                possibleParameterValues[editParameterIndex].evals = evaluateFunctions(
                                  formulae,
                                  parameters,
                                  possibleParameterValues[editParameterIndex].values,
                                );

                                setPossibleParameterValues([...possibleParameterValues]);
                              }}
                            />
                          </div>
                        );
                      })}
                      <div className="col-4">
                        <label htmlFor="CalculationDifficulty" className="form-label p-0 m-0">
                          Complexity
                        </label>
                        <select
                          defaultValue={'default'}
                          id="CalculationDifficulty"
                          className="form-select"
                          name="calculation-difficulty"
                          placeholder="complexity"
                          value={possibleParameterValues[editParameterIndex].complexity}
                          onChange={(e) => {
                            possibleParameterValues[editParameterIndex].complexity = parseInt(e.target.value);
                            setPossibleParameterValues([...possibleParameterValues]);
                          }}
                        >
                          {calculationDifficultyOptions.map((o) => (
                            <option key={o.value} value={o.value}>
                              {o.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
          <div className="row">
            <MobileDisplay chunks={chunks} parameters={parameters} formulae={formulae} />
            <div className="col-sm-6 col-md-8 col-lg-9 border rounded">
              <QuestionEditor updateHTML={setContentHTML} initialHTML={''} />
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const formData = Object.fromEntries(new FormData(e.target as HTMLFormElement));
                  const numVariations = parseInt(formData.numVariations as string);
                  if (isNaN(numVariations) || numVariations < 0) {
                    alert(`Invalid number of variations ${numVariations}`);
                    return;
                  }
                  // Generate variations.
                  let variations: { values: string[]; evals: string[]; complexity: number }[] = [];
                  for (let i = 0; i < numVariations; i++) {
                    let possibleValues: { values: string[]; evals: string[]; complexity: number } = {
                      values: [],
                      evals: [],
                      complexity: 0,
                    };
                    for (let j = 0; j < parameters.length; j++) {
                      const p = parameters[j];
                      const value = TemplatesConfig.classes
                        .find((c) => c.id === p.class)
                        ?.generate([])
                        .toString();
                      console.log(value, p.name, p.class);
                      invariant(value !== undefined, `Invalid value `);
                      possibleValues.values.push(value);
                    }

                    possibleValues.evals = evaluateFunctions(formulae, parameters, possibleValues.values);

                    variations.push(possibleValues);
                  }
                  console.log({ variations });
                  setPossibleParameterValues(variations);
                  setShowComplexityWarning(true);
                }}
              >
                <div className="row">
                  <div className="col-6">
                    <input type="number" name="numVariations" placeholder="Number of Variations" />
                  </div>
                  <div className="col-6">
                    <button
                      type="submit"
                      className="btn btn-primary col-12 mt-2 p-2"
                      disabled={checkTemplateQuestion(chunks, parameters, formulae, questionType) !== ''}
                    >
                      Generate
                    </button>
                  </div>
                </div>
              </form>

              {
                <div className="row mx-auto mt-2">
                  {parameters.length > 0 &&
                    possibleParameterValues.map((ppv, index) => (
                      <div className="border row py-1" key={index}>
                        <div className=" d-flex">
                          {ppv.values.map((value: string, ppvIndex: number) => (
                            <span key={ppvIndex} className="mx-1 col-6 border">
                              {parameters[ppvIndex]?.name} - <b>{value}</b>
                            </span>
                          ))}
                        </div>
                        <div className=" d-flex">
                          {ppv.evals.map((value: string, ppvIndex: number) => (
                            <span key={ppvIndex} className="mx-1 col-12 border">
                              {formulae[ppvIndex]} - <b>{value}</b>
                            </span>
                          ))}
                        </div>

                        <div className="d-flex justify-content-end mt-2">
                          <div className="col-3">Complexity: {ppv.complexity}</div>
                          <div className="col-1">
                            <button
                              className="btn text-danger p-0 m-0"
                              onClick={(e) => {
                                const sp = possibleParameterValues;
                                sp.splice(index, 1);
                                setPossibleParameterValues([...sp]);
                              }}
                            >
                              <i className="bi bi-trash" />
                            </button>
                          </div>
                          <div className="col-1">
                            <button
                              className="btn text-primary p-0 m-0"
                              onClick={(e) => {
                                setEditParameterIndex(index);
                              }}
                            >
                              <i className="bi bi-pencil" />
                            </button>
                          </div>
                          <div className="col-1">
                            <button
                              className="btn btn-primary"
                              onClick={(e) => {
                                console.log(index);
                                reRenderKatex();
                                setDisplayIndex(index);
                              }}
                            >
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              }
            </div>
            <div>
              <div className="row">
                {displayIndex !== -1 && possibleParameterValues[displayIndex] && viewQuestion && (
                  <div className="col-12">
                    {viewQuestion instanceof Error ? (
                      <div className="alert alert-danger">{viewQuestion.message}</div>
                    ) : (
                      <div className="row">
                        <h5>Question</h5>
                        <RenderQuestion
                          question={viewQuestion}
                          readonly={true}
                          updateQuestion={(q: TranslatedQuestion) => {}}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <form className="mb-4" onSubmit={saveQuestion}>
            <div className="row">
              <ErrorMessage error={subChapTypeError} />
              <div className="col-4">
                <label htmlFor="Subject" className="form-label">
                  Subject
                </label>
                <select defaultValue={'default'} id="Subject" className="form-select" name="subject">
                  {subjectList.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-4">
                <label htmlFor="Chapter" className="form-label">
                  Chapter
                </label>
                <select defaultValue={'default'} id="Chapter" className="form-select" name="chapter">
                  {chapterList.map((o: { value: string; label: string }) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-4">
                <label htmlFor="Type" className="form-label">
                  Question Type
                </label>
                <select
                  id="Type"
                  className="form-select"
                  value={questionType}
                  onChange={(e) => setQuestionType(e.target.value as QuestionType)}
                >
                  {questionTypes.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="m-1">
              <ErrorMessage error={conceptsFetchError} />
              <ErrorMessage error={numEquationError} />
              <label htmlFor="FormControlInput1" className="form-label">
                Number of Equations
              </label>
              <input
                type="number"
                name="numEquations"
                value={numEquations}
                onChange={(e) => setNumEquations(parseInt(e.target.value))}
                className="form-control"
                id="FormControlInput1"
                placeholder="1,2,3..."
                required={true}
              />
            </div>
            {Array.from({ length: numEquations }, (_, i) => i).map((i) => {
              return (
                <div key={i}>
                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <label htmlFor={`FormControlInput${i}`} className="form-label">
                          Description of equation {i + 1}
                        </label>
                        <input
                          type="text"
                          name={`equations${i}`}
                          className="form-control mt-0 py-2"
                          id={`FormControlInput${i}`}
                          placeholder="1,2,3..."
                          required={true}
                        />
                      </div>
                      <div className="col-6">
                        <ErrorMessage error={selectedConceptsErrors[i]} />
                        <label htmlFor="ConceptTags" className="form-label">
                          Concept Tags for equation ${i + 1}
                        </label>
                        <Select
                          defaultValue={[]}
                          isMulti
                          name="colors"
                          options={options}
                          components={animatedComponents}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(options: any) => handleChangeEq(options, i)}
                          required={true}
                          id="ConceptTags"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <ErrorMessage error={conceptsError} />
            <label htmlFor="ConceptTags" className="form-label">
              Concept Tags
            </label>
            <Select
              defaultValue={[]}
              isMulti
              options={options}
              components={animatedComponents}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleChange}
              required={true}
              id="ConceptTags"
            />
            <div className="m-1">
              <label htmlFor="NumImagesInput" className="form-label">
                Number of Images
              </label>
              <input
                type="number"
                name="numImages"
                value={numImages}
                onChange={(e) => setNumImages(parseInt(e.target.value))}
                className="form-control"
                id="NumImagesInput"
                placeholder="1,2,3..."
                required={true}
              />
            </div>
            <button
              type="submit"
              className="btn btn-secondary wide mt-3"
              disabled={checkTemplateQuestion(chunks, parameters, formulae, questionType) !== ''}
            >
              Add Question
            </button>
          </form>
        </div>
      </div>
      <hr />
      <TemplatesConfigInfo />
    </div>
  );
}

// function ParameterDetails() {
//   const [complexity, setComplexity] = React.useState<string>('default');
//   const parameters = useTemplateStore((state) => state.parameters);
//   const possibleParameterValues = useTemplateStore((state) => state.possibleParameterValues);
//   const setPossibleParameterValues = useTemplateStore((state) => state.setPossibleParameterValues);
//   const [inFlightValues, setInFlightValues] = React.useState<string[]>([]);

//   React.useEffect(() => {
//     setInFlightValues(new Array(parameters.length).fill(''));
//   }, [parameters]);

//   if (parameters.length < inFlightValues.length || parameters.length < possibleParameterValues[0]?.values.length) {
//     return null;
//   }

//   return (
//     <>
//       <div className="container">
//         <div className="row">
//           <div className="col-12">
//             <hr />
//             <h5>Possible Values</h5>
//           </div>
//         </div>
//         <div className="row" style={parameters.length ? {} : { display: 'none' }}>
//           {inFlightValues.map((ifv, index) => {
//             return (
//               <div className="col-3" key={index}>
//                 <label htmlFor="FormControlInput1" className="form-label p-0 m-0">
//                   {parameters[index].name}
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control p-1 mt-0"
//                   placeholder={parameters[index].name}
//                   value={ifv}
//                   onChange={(e) => {
//                     setInFlightValues([
//                       ...inFlightValues.slice(0, index),
//                       e.target.value,
//                       ...inFlightValues.slice(index + 1),
//                     ]);
//                   }}
//                 />
//               </div>
//             );
//           })}
//           <div className="col-3">
//             <label htmlFor="CalculationDifficulty" className="form-label p-0 m-0">
//               Complexity
//             </label>
//             <select
//               defaultValue={'default'}
//               id="CalculationDifficulty"
//               className="form-select"
//               name="calculation-difficulty"
//               placeholder="complexity"
//               value={complexity}
//               onChange={(e) => setComplexity(e.target.value)}
//             >
//               {calculationDifficultyOptions.map((o) => (
//                 <option key={o.value} value={o.value}>
//                   {o.label}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <div className="col-3">
//             <button
//               className="btn btn-secondary"
//               onClick={(e) => {
//                 if (complexity === 'default' || inFlightValues.some((v) => v === '')) {
//                   alert('All values must be filled');
//                   return;
//                 }

//                 const sp = possibleParameterValues;
//                 sp.push({ values: inFlightValues, complexity: parseInt(complexity) });
//                 setPossibleParameterValues([...sp]);
//                 setInFlightValues(new Array(parameters.length).fill(''));
//                 setComplexity('default');
//               }}
//             >
//               Add
//             </button>
//           </div>
//         </div>
//         <div className="row mx-auto mt-2">
//           {possibleParameterValues.map((ppv, index) => (
//             <div className="border row py-1" key={index}>
//               <div className="col-8 d-flex">
//                 {ppv.values.map((value: string, ppvIndex: number) => (
//                   <span key={ppvIndex} className="mx-1">
//                     {parameters[ppvIndex].name} - {value} |
//                   </span>
//                 ))}
//               </div>
//               <div className="col-3">Complexity: {ppv.complexity}</div>
//               <div className="col-1">
//                 <button
//                   className="btn text-danger p-0 m-0"
//                   onClick={(e) => {
//                     const sp = possibleParameterValues;
//                     sp.splice(index, 1);
//                     setPossibleParameterValues([...sp]);
//                   }}
//                 >
//                   <i className="bi bi-trash" />
//                 </button>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// }

function MobileDisplay({
  chunks,
  parameters,
  formulae,
}: {
  chunks: Map<ChunkType, string>;
  parameters: { name: string; class: string }[];
  formulae: string[];
}) {
  return (
    <div className="col-sm-6 col-md-4 col-lg-3 shadow border rounded">
      <>
        {Array.from(chunks.entries()).map((chunk, index) => {
          return (
            <div key={index}>
              <ChunkDisplay type={chunk[0]} value={chunk[1]} />
            </div>
          );
        })}
        <hr />
        {parameters.length > 0 && <h5>Parameters</h5>}
        {parameters.map((p, index) => (
          <div key={index}>{p.name}</div>
        ))}
        <hr />
        {formulae.length > 0 && <h5>Formulae</h5>}
        {formulae.map((f, index) => (
          <div key={index}>{f}</div>
        ))}
      </>
    </div>
  );
}

function ChunkDisplay({ type, value }: { type: string; value: string }) {
  useKatex();

  return (
    <div className="row">
      <div className="col-12">
        <h5>{type}</h5>
        {parse(value)}
      </div>
    </div>
  );
}

function TemplatesConfigInfo() {
  // From the TemplatesConfig make two tables of variables and their descriptions and functions and their descriptions

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Templates Configuration</h2>
          <hr />
          <h4 className="mt-2"> Variables </h4>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Variable</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              {TemplatesConfig.classes.map((v) => (
                <tr key={v.id}>
                  <td>{v.id}</td>
                  <td>{v.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr />
          <h4 className="mt-2"> Functions </h4>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Function</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              {TemplatesConfig.functions.map((v) => (
                <tr key={v.id}>
                  <td>{v.id}</td>
                  <td>{v.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
