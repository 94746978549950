import { create } from 'zustand';
import { ConceptGraph } from '../../lib/globaltypes';
import { Cloud } from './core/cloud';
import { PublicConstants } from './core/constants';

interface KatexStore {
  refetchCount: number;
  reRenderKatex: () => void;
}

export const useKatexStore = create<KatexStore>((set) => ({
  refetchCount: 0,
  reRenderKatex: () => set((state) => ({ refetchCount: state.refetchCount + 1 })),
}));

interface templateStore {
  parameters: { name: string; class: string }[];
  setParameters: (parameters: { name: string; class: string }[]) => void;
  formulae: string[];
  setFormulae: (formulae: string[]) => void;
  possibleParameterValues: { values: string[]; evals: string[]; complexity: number }[];
  setPossibleParameterValues: (
    possibleParameterValues: { values: string[]; evals: string[]; complexity: number }[],
  ) => void;
}

export const useTemplateStore = create<templateStore>((set) => ({
  parameters: [],
  setParameters: (parameters: { name: string; class: string }[]) => set((state) => ({ ...state, parameters })),
  formulae: [],
  setFormulae: (formulae: string[]) => set((state) => ({ ...state, formulae })),
  possibleParameterValues: [],
  setPossibleParameterValues: (possibleParameterValues: { values: string[]; evals: string[]; complexity: number }[]) =>
    set((state) => ({ ...state, possibleParameterValues })),
}));
