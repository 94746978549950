import React from 'react';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import { IQuestion } from '../../../../lambda/question/question-schema';
import { ErrorMessage } from '../common/error-message';
import { AppContext } from '../../core/context';
import { languageCodeToString } from '../../utils/utils';
import invariant from 'tiny-invariant';
import { handleQuestionFormSubmission } from '../../utils/questions';
import SoundRecorder from '../audio/recorder';

export function CorrectnessForm({
  question,
  lang,
  refreshQuestion,
}: {
  question: IQuestion;
  lang: 'en' | 'hi' | 'te';
  refreshQuestion: () => void;
}) {
  const [formError, setFormError] = React.useState('');
  const { state } = React.useContext(AppContext);
  const [edit, setEdit] = React.useState(false);
  const [audioURL, setAudioURL] = React.useState('');

  let correctnessknown: boolean = false;
  let correctnessValueKnown: boolean = false; // Only valid if correctnessknown is true
  let correctnessAuthor: string = ''; // Only valid if correctnessknown is true
  let correctnessReason: string = ''; // Only valid if correctnessknown is true
  let correctnessAudioURL: string = ''; // Only valid if correctnessknown is true
  if (lang === 'en') {
    if (question.correctness === undefined || question.correctness === null) {
      correctnessknown = false;
    } else {
      correctnessknown = true;
      correctnessValueKnown = question.correctness;
      correctnessAuthor = question.correctnessAuthor ?? '';
      correctnessReason = question.correctnessReason ?? '';
      correctnessAudioURL = question.correctnessAudioURL ?? '';
    }
  } else {
    let translations = question.translationCorrectness;
    translations.forEach((translation) => {
      if (translation.lang === lang) {
        correctnessknown = true;
        correctnessValueKnown = translation.correctness;
        correctnessAuthor = translation.author ?? '';
        correctnessReason = translation.description ?? '';
        correctnessAudioURL = translation.correctnessAudioURL ?? '';
      }
    });
  }

  return (
    <div className="row border p-4 border-secondary">
      <ErrorMessage error={formError} />
      <div className="container">
        <div className="row">
          <div className="col-4">
            <h5>{languageCodeToString(lang)} Question Correctness</h5>
            {lang === 'en' ? (
              <p>Is the question, answer & solution correct?</p>
            ) : (
              <p>Is the translation of the question, answer and solution correct in {languageCodeToString(lang)}</p>
            )}
          </div>
          <div className="col-7">
            {!correctnessknown ? (
              <h6 className="text-warning">Unknown</h6>
            ) : (
              <>
                <h6 className="text-success">
                  Correctness: {String(correctnessValueKnown)} marked by {correctnessAuthor}{' '}
                  {correctnessReason ? `for reason ${correctnessReason}` : ''}{' '}
                </h6>
                {correctnessAudioURL && <audio src={correctnessAudioURL} controls />}
              </>
            )}
          </div>
          <div className="col-1">
            <button type="button" className="btn btn-primary" onClick={() => setEdit(!edit)}>
              Edit
            </button>
          </div>
        </div>
      </div>

      {edit && (
        <div className="col-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setFormError('');
              const formData = Object.fromEntries(new FormData(e.target as HTMLFormElement));
              const correct = formData.correct as string;
              const reason = formData.reason as string;
              if (correct === 'default') {
                setFormError('Please select a value');
                return;
              }
              invariant(correct === '1' || correct === '2', 'Correctness should be 1 or 2');
              if (correct === '2' && !reason && !audioURL) {
                setFormError('If a question is marked incorrect a reason (text or audio) should be provided');
                return;
              }

              Cloud.post(
                {
                  action: PublicConstants.ACTION_UPDATE_QUESTION,
                  // @ts-ignore
                  data: {
                    id: question._id,
                    update: PublicConstants.UPDATE_CORRECTNESS,
                    lang: lang,
                    value: correct === '1' ? true : false,
                    author: state.userInfo?.info.email,
                    audioURL: audioURL,
                    reason: reason,
                  },
                },
                PublicConstants.API_QUESTION,
              ).then((response) => {
                handleQuestionFormSubmission(response, refreshQuestion, setFormError, setEdit);
                setAudioURL('');
              });
            }}
          >
            <label htmlFor="CorrectnessStatus" className="form-label">
              Correctness status
            </label>
            <select defaultValue={'default'} id="CorrectnessStatus" className="form-select" name="correct">
              <option value="default">Open this select menu</option>
              <option value="1">Correct</option>
              <option value="2">Incorrect</option>
            </select>
            <div className="mb-3">
              <label htmlFor="IncorrectReason" className="form-label">
                Details (Required if question is incorrect)
              </label>
              <textarea
                className="form-control"
                id="IncorrectReason"
                rows={2}
                placeholder="Question is incorrect because answer should be B instead of A as..."
                name="reason"
              ></textarea>
              <SoundRecorder onUpload={(url) => setAudioURL(url)} />
            </div>

            <button type="submit" className="col-12 btn btn-primary">
              Submit Correctness status
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
