/*
 * --------------------------------------------------------------------------- *
 * File: coursebuilder.tsx                                                     *
 * Project: faculty-dashboard                                                  *
 * Created Date: 18 Feb 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Sat Mar 25 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import { nanoid } from 'nanoid';
import React, { useEffect, useRef, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import { AppContext } from '../../core/context';
import { BlankCourse, Course, Lesson, Section } from '../../core/types';
import Dropzone from 'react-dropzone';

const reader = new FileReader();

function CourseBuilder() {
  const { state } = React.useContext(AppContext);
  const selectedSectionIndex = useRef<number>(0);
  const selectedLessonIndex = useRef<number>(0);
  const [refreshCount, setRefreshCount] = useState(0);
  const [allCourses, setAllCourses] = useState<Course[]>([]);
  const [showImportBlock, setShowImportBlock] = useState(false);
  const [expandImportCourseId, setExpandImportCourseId] = useState<string>('');
  const [expandImportSectionId, setExpandImportSectionId] = useState<string>('');
  const [cqFilterString, setCqFilterString] = useState<string>('');
  const [showSectionRenameText, setShowSectionRenameText] = useState(false);
  const [showLessonRenameText, setShowLessonRenameText] = useState(false);
  const [sectionNewNameValue, setSectionNewNameValue] = useState('');
  const [lessonNewNameValue, setLessonNewNameValue] = useState('');

  const expandedCourseForImport = useRef<Course>(BlankCourse);
  const course = useRef<Course>(BlankCourse);
  const [contentQuanta, setContentQuanta] = useState<any[]>([]);

  useEffect(() => {
    Cloud.post(
      { action: PublicConstants.ACTION_GET_SUBJECT_QUANTAS, data: { subject: state.userInfo?.info.subject } },
      PublicConstants.API_COURSE,
    ).then((response) => {
      if (response.success) setContentQuanta(response.value);
    });
    loadCourseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (expandImportCourseId) {
      Cloud.post(
        { action: PublicConstants.ACTION_GET_COURSE, data: { id: expandImportCourseId } },
        PublicConstants.API_COURSE,
      ).then((response) => {
        if (response.success) {
          expandedCourseForImport.current = response.value;
          setRefreshCount(refreshCount + 1);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandImportCourseId]);

  const loadCourseList = () => {
    Cloud.post(
      { action: PublicConstants.ACTION_LIST_COURSES, data: { subject: state.userInfo?.info.subject } },
      PublicConstants.API_COURSE,
    ).then((response) => {
      if (response.success) setAllCourses(response.value);
    });
  };

  const saveCourse = async () => {
    // If you don't have a course id, then create a new one
    let n = course.current.id || nanoid();
    if (!course.current.id) {
      n = `${state.userInfo?.info.subject}::${n}`;
    }
    course.current = { ...course.current, id: n, lastUpdated: Date.now() };

    if (
      course.current.id &&
      course.current.name &&
      course.current.description &&
      course.current.keywords &&
      course.current.logo &&
      course.current.keywords.length > 0
    ) {
      const response = await Cloud.post(
        { action: PublicConstants.ACTION_SAVE_COURSE, data: course.current },
        PublicConstants.API_COURSE,
      );
      if (response.success) {
        // Saved successfully
        loadCourseList();
        alert('Saved successfully');
      }
    } else {
      alert('Please fill in all the details');
    }
  };

  const loadCourse = async (id: string) => {
    const response = await Cloud.post(
      { action: PublicConstants.ACTION_GET_COURSE, data: { id } },
      PublicConstants.API_COURSE,
    );
    if (response.success) {
      course.current = response.value;
      updateStatistics();
      setRefreshCount(refreshCount + 1);
    }
  };

  const updateStatistics = () => {
    course.current.contentQuanta = 0;
    course.current.questions = 0;
    course.current.size = 0;
    course.current.videoCount = 0;
    course.current.sectionList.forEach((s: Section) => {
      s.lessonList.forEach((l: Lesson) => {
        course.current.contentQuanta += l.contentQuanta.length;
        contentQuanta.forEach((cq: any) => {
          if (l.contentQuanta.includes(cq.id)) {
            course.current.size += Math.floor(cq.size / 10);
            course.current.size += cq.videoSize || 0;
            course.current.videoCount += cq.videos?.length || 0;
          }
        });
      });
    });
  };

  const importCourse = async (id: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Create a new copy of this course?')) {
      const response = await Cloud.post(
        { action: PublicConstants.ACTION_GET_COURSE, data: { id } },
        PublicConstants.API_COURSE,
      );
      if (response.success) {
        const c = response.value;
        c.id = `${state.userInfo?.info.subject}::${nanoid()}`;
        c.name = `Copy of ${c.name}`;
        c.lastUpdated = Date.now();
        c.sectionList.forEach((s: any) => {
          s.id = nanoid();
          s.lessonList.forEach((l: any) => {
            l.id = nanoid();
          });
        });
        course.current = c;
        updateStatistics();
        setRefreshCount(refreshCount + 1);
      }
    }
  };

  const importSection = async (s: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Clone the section into this course?')) {
      const c = expandedCourseForImport.current;

      const section = JSON.parse(JSON.stringify(c.sectionList.find((x) => x.id === s)));
      if (section) {
        section.id = nanoid();
        section.lessonList.forEach((l: any) => {
          l.id = nanoid();
        });
        course.current.sectionList.push(section);
        selectedSectionIndex.current = course.current.sectionList.length - 1;
        updateStatistics();
        setRefreshCount(refreshCount + 1);
      }
    }
  };

  const importLesson = async (l: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Clone the lesson into this section?')) {
      const c = expandedCourseForImport.current;
      const section = c.sectionList.find((x) => x.id === expandImportSectionId);
      if (section) {
        const lesson = JSON.parse(JSON.stringify(section.lessonList.find((x) => x.id === l)));
        if (lesson) {
          lesson.id = nanoid();
          course.current.sectionList[selectedSectionIndex.current].lessonList.push(lesson);
          updateStatistics();
          setRefreshCount(refreshCount + 1);
        }
      }
    }
  };

  const cqDisplayName = (cqId: string) => {
    const q = cqId.split('::');
    return `${q[1]} - ${q[2]}`;
  };

  const addCqToLesson = (cqId: string) => {
    const lesson = course.current.sectionList[selectedSectionIndex.current].lessonList[selectedLessonIndex.current];
    lesson.contentQuanta.push(cqId);
    updateStatistics();
    setRefreshCount(refreshCount + 1);
  };

  const removeCqFromLesson = (s: number, l: number, cqId: string) => {
    const lesson = course.current.sectionList[s].lessonList[l];
    lesson.contentQuanta = lesson.contentQuanta.filter((x) => x !== cqId);
    updateStatistics();
    setRefreshCount(refreshCount + 1);
  };

  const moveUpCqInLesson = (s: number, l: number, cqi: number) => {
    const lesson = course.current.sectionList[s].lessonList[l];
    const cq = lesson.contentQuanta[cqi];
    lesson.contentQuanta[cqi] = lesson.contentQuanta[cqi - 1];
    lesson.contentQuanta[cqi - 1] = cq;
    setRefreshCount(refreshCount + 1);
  };

  const moveDownCqInLesson = (s: number, l: number, cqi: number) => {
    const lesson = course.current.sectionList[s].lessonList[l];
    const cq = lesson.contentQuanta[cqi];
    lesson.contentQuanta[cqi] = lesson.contentQuanta[cqi + 1];
    lesson.contentQuanta[cqi + 1] = cq;
    setRefreshCount(refreshCount + 1);
  };

  const moveUpLesson = (s: number, l: number) => {
    const lesson = course.current.sectionList[s].lessonList[l];
    course.current.sectionList[s].lessonList[l] = course.current.sectionList[s].lessonList[l - 1];
    course.current.sectionList[s].lessonList[l - 1] = lesson;
    setRefreshCount(refreshCount + 1);
  };

  const moveDownLesson = (s: number, l: number) => {
    const lesson = course.current.sectionList[s].lessonList[l];
    course.current.sectionList[s].lessonList[l] = course.current.sectionList[s].lessonList[l + 1];
    course.current.sectionList[s].lessonList[l + 1] = lesson;
    setRefreshCount(refreshCount + 1);
  };

  const moveUpSection = (s: number) => {
    const section = course.current.sectionList[s];
    course.current.sectionList[s] = course.current.sectionList[s - 1];
    course.current.sectionList[s - 1] = section;
    setRefreshCount(refreshCount + 1);
  };

  const moveDownSection = (s: number) => {
    const section = course.current.sectionList[s];
    course.current.sectionList[s] = course.current.sectionList[s + 1];
    course.current.sectionList[s + 1] = section;
    setRefreshCount(refreshCount + 1);
  };

  const filteredContentQuanta = () => {
    if (!cqFilterString) return contentQuanta.slice(0, 10);
    else
      return contentQuanta
        .filter((x) => cqDisplayName(x.id).toLowerCase().includes(cqFilterString.toLowerCase()))
        .slice(0, 10);
  };

  const sectionLessonCqList = (l: Lesson, i: number, j: number) => (
    <ul key={`ul-${l.id}`} className="list-group px-3">
      {l.contentQuanta.map((cq, cqi) => (
        <li key={cq}>
          <button
            className="mx-1 my-2 px-2 py-0 btn btn-sm btn-secondary"
            onClick={() => {
              removeCqFromLesson(i, j, cq);
            }}
          >
            -
          </button>
          {cqDisplayName(cq)}
          {cqi > 0 && (
            <button
              className="mx-1 my-2 px-2 py-0 btn btn-sm"
              onClick={() => {
                moveUpCqInLesson(i, j, cqi);
              }}
            >
              <i className="bi bi-arrow-up" />
            </button>
          )}
          {cqi < l.contentQuanta.length - 1 && (
            <button
              className="mx-1 my-2 px-2 py-0 btn btn-sm"
              onClick={() => {
                moveDownCqInLesson(i, j, cqi);
              }}
            >
              <i className="bi bi-arrow-down" />
            </button>
          )}
        </li>
      ))}
    </ul>
  );

  const upload = async (f: File) => {
    reader.readAsDataURL(f);
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      // @ts-ignore
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = async function () {
        var height = image.height;
        var width = image.width;
        if (height !== 300 || width !== 400) {
          alert('Logo image must be 400px wide and 300px high.');
          return false;
        }
        await new Promise((resolve) => {
          reader.onload = async (x) => {
            Cloud.uploadImageFile(f.name.split('.').pop() || '.jpg', x.target?.result as ArrayBuffer)
              .then((n) => (course.current.logo = n))
              .then((x) => resolve(''))
              .catch((e) => alert('Error uploading image'));
          };
          reader.readAsArrayBuffer(f);
        });
        setRefreshCount(refreshCount + 1);
      };
    };
  };

  const onImageDrop = async (acceptedFiles: File[]) => {
    for (let i = 0; i < acceptedFiles.length; i++) {
      await upload(acceptedFiles[i]);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 my-2 mx-0 pt-4 p-0">
          <h1>Courses</h1>
          <hr />
          <table className="table">
            <thead className="bg-secondary text-white">
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Last Updated</th>
              </tr>
            </thead>
            <tbody>
              {allCourses.map((c) => (
                <tr
                  key={c.id}
                  onClick={() => loadCourse(c.id)}
                  className="click"
                  style={c.id === course.current.id ? { backgroundColor: '#eeeef8' } : { backgroundColor: '#ffffff' }}
                >
                  <td>{c.name}</td>
                  <td>{c.description}</td>
                  <td>
                    {new Date(c.lastUpdated).toLocaleDateString()} {new Date(c.lastUpdated).toLocaleTimeString()}
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={3}>
                  <button
                    className="btn btn-light border border-secondary wide"
                    onClick={() => {
                      course.current = { ...BlankCourse, id: `${state.userInfo?.info.subject}::${nanoid()}` };
                      setRefreshCount(refreshCount + 1);
                    }}
                  >
                    Create New Course
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {course.current.id && (
        <div className="row p-2 border rounded my-3">
          <div className="col-12">
            <h3>Course Details</h3>
            <div className="container">
              <div className="row p-2 border rounded my-3">
                <div className="col-md-6"></div>
                <div className="col-md-6 right"></div>
                <div className="col-md-6">
                  <label>Name</label>
                  <input
                    type="text"
                    className="pt-2 pb-1 mt-0 mb-0"
                    placeholder="Course Name"
                    value={course.current.name}
                    onChange={(e) => {
                      course.current = { ...course.current, name: e.target.value };
                      setRefreshCount(refreshCount + 1);
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label>Keywords</label>
                  <input
                    type="text"
                    className="pt-2 pb-1 mt-0 mb-0"
                    placeholder="List of Keywords separated by comma"
                    value={course.current.keywords.join(',')}
                    onChange={(e) => {
                      course.current = {
                        ...course.current,
                        keywords: e.target.value
                          .toLowerCase()
                          .split(',')
                          .map((s) => s.trim()),
                      };
                      setRefreshCount(refreshCount + 1);
                    }}
                  />
                </div>
                <div className="col-12">
                  <label>Description</label>
                  <input
                    type="text"
                    className="pt-2 pb-1 mt-0 mb-0"
                    placeholder="Description"
                    value={course.current.description}
                    onChange={(e) => {
                      course.current = { ...course.current, description: e.target.value };
                      setRefreshCount(refreshCount + 1);
                    }}
                  />
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <img
                    src={`https://${PublicConstants.CONTENT_SUB_DOMAIN}.${PublicConstants.BASE_DOMAIN}/${course.current.logo}`}
                    alt="Course Logo Missing"
                    className="img-fluid pt-2"
                  />
                </div>
                <div className="col-sm-6 col-md-8 col-lg-9">
                  <Dropzone onDrop={onImageDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <section className="p-5 my-2 border border-black border-rounded click">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p className="p-0 m-0 border-black text-center">
                            Drag and drop the course logo image here, or click to select files. Any image of size
                            400x300 pixels
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-12">
                  <button className="btn btn-secondary my-3" onClick={() => setShowImportBlock(!showImportBlock)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-cloud-download-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"
                      />
                    </svg>{' '}
                    Import Course / Section / Lesson
                  </button>
                </div>
                {showImportBlock && (
                  <>
                    <ul className="list-group">
                      {allCourses
                        .filter((c) => course.current.id !== c.id)
                        .map((c) => (
                          <li key={c.id} className="py-1">
                            <button
                              className="btn btn-sm py-0"
                              onClick={() => {
                                setExpandImportSectionId('');
                                setExpandImportCourseId(c.id);
                              }}
                            >
                              {expandImportCourseId === c.id ? '-' : '+'}
                            </button>
                            {c.name}{' '}
                            <button className="btn btn-sm btn-secondary py-0 mx-2" onClick={() => importCourse(c.id)}>
                              Import
                            </button>
                            {expandImportCourseId === c.id && (
                              <ul className="list-group px-3">
                                {expandedCourseForImport.current.sectionList.map((s, i) => (
                                  <li key={s.id} className="py-1">
                                    <button
                                      className="btn btn-sm py-0"
                                      onClick={() => {
                                        if (selectedSectionIndex.current < course.current.sectionList.length) {
                                          setExpandImportSectionId(s.id);
                                        } else {
                                          alert('Please select a section to import individual lessons');
                                        }
                                      }}
                                    >
                                      {expandImportSectionId === s.id ? '-' : '+'}
                                    </button>
                                    {s.name}{' '}
                                    <button
                                      className="btn btn-sm btn-secondary py-0 mx-2"
                                      onClick={() => importSection(s.id)}
                                    >
                                      Import
                                    </button>
                                    {expandImportSectionId === s.id && (
                                      <ul className="list-group px-5">
                                        {expandedCourseForImport.current.sectionList[i].lessonList.map((l) => (
                                          <li key={l.id} className="py-1">
                                            {l.name}
                                            <button
                                              className="btn btn-sm btn-secondary py-0 mx-2"
                                              onClick={() => importLesson(l.id)}
                                            >
                                              Import
                                            </button>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                    </ul>
                  </>
                )}
              </div>
              <div className="row p-2">
                <div className="col-md-6 border rounded py-4">
                  <h4>Build Course</h4>
                  <hr />
                  <label>Sections</label>
                  <CreatableSelect
                    options={course.current.sectionList.map((s: Section) => ({ value: s.id, label: s.name }))}
                    onCreateOption={(e) => {
                      const section: Section = { id: nanoid(), name: e, lessonList: [], description: '' };
                      const c = { ...course.current, sectionList: [...course.current.sectionList, section] };
                      course.current = c;
                      selectedSectionIndex.current = c.sectionList.length - 1;
                      selectedLessonIndex.current = 0;
                      setRefreshCount(refreshCount + 1);
                    }}
                    value={
                      selectedSectionIndex.current < course.current.sectionList.length
                        ? {
                            value: course.current.sectionList[selectedSectionIndex.current].id,
                            label: course.current.sectionList[selectedSectionIndex.current].name,
                          }
                        : null
                    }
                    onChange={(e) => {
                      if (e) {
                        course.current.sectionList.forEach((s, i) =>
                          s.id === e.value ? (selectedSectionIndex.current = i) : null,
                        );
                      }
                      setRefreshCount(refreshCount + 1);
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Section Description"
                    disabled={selectedSectionIndex.current >= course.current.sectionList.length}
                    value={
                      selectedSectionIndex.current < course.current.sectionList.length
                        ? course.current.sectionList[selectedSectionIndex.current]?.description
                        : ''
                    }
                    onChange={(e) => {
                      if (selectedSectionIndex.current < course.current.sectionList.length) {
                        const section = course.current.sectionList[selectedSectionIndex.current];
                        course.current = {
                          ...course.current,
                          sectionList: course.current.sectionList.map((s) =>
                            s.id === section.id ? { ...s, description: e.target.value } : s,
                          ),
                        };
                        setRefreshCount(refreshCount + 1);
                      }
                    }}
                  />
                  {showSectionRenameText && (
                    <input
                      type="text"
                      placeholder="New name for section"
                      value={sectionNewNameValue}
                      onChange={(e) => setSectionNewNameValue(e.target.value)}
                    />
                  )}
                  <button
                    className="btn btn-secondary btn-sm"
                    disabled={selectedSectionIndex.current >= course.current.sectionList.length}
                    onClick={() => {
                      if (showSectionRenameText && sectionNewNameValue) {
                        const section = course.current.sectionList[selectedSectionIndex.current];
                        course.current.sectionList.forEach((s) => {
                          if (s.id === section.id) {
                            s.name = sectionNewNameValue;
                          }
                        });
                        setShowSectionRenameText(false);
                      } else {
                        setSectionNewNameValue('');
                        setShowSectionRenameText(true);
                      }
                    }}
                  >
                    {showSectionRenameText ? 'Update' : 'Rename'}
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-secondary btn-sm"
                    disabled={selectedSectionIndex.current >= course.current.sectionList.length}
                    onClick={() => {
                      if (selectedSectionIndex.current < course.current.sectionList.length) {
                        const section = course.current.sectionList[selectedSectionIndex.current];
                        course.current.sectionList = course.current.sectionList.filter((s) => s.id !== section.id);
                        selectedSectionIndex.current = 0;
                      }
                      setRefreshCount(refreshCount + 1);
                    }}
                  >
                    Delete
                  </button>
                  <br />
                  <label>Lessons</label>
                  <CreatableSelect
                    options={course.current.sectionList[selectedSectionIndex.current]?.lessonList?.map((s: Lesson) => ({
                      value: s.id,
                      label: s.name,
                    }))}
                    isDisabled={selectedSectionIndex.current >= course.current.sectionList.length}
                    onCreateOption={(e) => {
                      const lesson: Lesson = { id: nanoid(), name: e, contentQuanta: [], description: '' };
                      let section = { ...course.current.sectionList[selectedSectionIndex.current] };
                      section = { ...section, lessonList: [...section.lessonList, lesson] };
                      course.current = {
                        ...course.current,
                        sectionList: course.current.sectionList.map((s) => (s.id === section.id ? section : s)),
                      };
                      selectedLessonIndex.current = section.lessonList.length - 1;
                      setRefreshCount(refreshCount + 1);
                    }}
                    value={
                      selectedSectionIndex.current < course.current.sectionList.length &&
                      selectedLessonIndex.current <
                        course.current.sectionList[selectedSectionIndex.current].lessonList.length
                        ? {
                            value:
                              course.current.sectionList[selectedSectionIndex.current].lessonList[
                                selectedLessonIndex.current
                              ].id,
                            label:
                              course.current.sectionList[selectedSectionIndex.current].lessonList[
                                selectedLessonIndex.current
                              ].name,
                          }
                        : null
                    }
                    onChange={(e) => {
                      if (e) {
                        course.current.sectionList[selectedSectionIndex.current].lessonList.forEach((l, i) =>
                          l.id === e.value ? (selectedLessonIndex.current = i) : null,
                        );
                      }
                      setRefreshCount(refreshCount + 1);
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Lesson Description"
                    disabled={
                      selectedSectionIndex.current >= course.current.sectionList.length ||
                      selectedLessonIndex.current >=
                        course.current.sectionList[selectedSectionIndex.current].lessonList.length
                    }
                    value={
                      selectedSectionIndex.current < course.current.sectionList.length &&
                      selectedLessonIndex.current <
                        course.current.sectionList[selectedSectionIndex.current].lessonList.length
                        ? course.current.sectionList[selectedSectionIndex.current].lessonList[
                            selectedLessonIndex.current
                          ].description
                        : ''
                    }
                    onChange={(e) => {
                      const lesson =
                        course.current.sectionList[selectedSectionIndex.current].lessonList[
                          selectedLessonIndex.current
                        ];
                      lesson.description = e.target.value;
                      const c = { ...course.current };
                      course.current = {
                        ...c,
                        sectionList: c.sectionList.map((s, i) =>
                          i === selectedSectionIndex.current
                            ? { ...s, lessonList: s.lessonList.map((l) => (l.id === lesson.id ? lesson : l)) }
                            : s,
                        ),
                      };
                      setRefreshCount(refreshCount + 1);
                    }}
                  />
                  {showLessonRenameText && (
                    <input
                      type="text"
                      placeholder="New name for lesson"
                      value={lessonNewNameValue}
                      onChange={(e) => setLessonNewNameValue(e.target.value)}
                    />
                  )}
                  <button
                    className="btn btn-secondary btn-sm"
                    disabled={
                      selectedSectionIndex.current >= course.current.sectionList.length ||
                      selectedLessonIndex.current >=
                        course.current.sectionList[selectedSectionIndex.current].lessonList.length
                    }
                    onClick={() => {
                      if (showLessonRenameText && lessonNewNameValue) {
                        const lesson =
                          course.current.sectionList[selectedSectionIndex.current].lessonList[
                            selectedLessonIndex.current
                          ];
                        lesson.name = lessonNewNameValue;
                        setShowLessonRenameText(false);
                      } else {
                        setLessonNewNameValue('');
                        setShowLessonRenameText(true);
                      }
                    }}
                  >
                    {showLessonRenameText ? 'Update' : 'Rename'}
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-secondary btn-sm"
                    disabled={
                      selectedSectionIndex.current >= course.current.sectionList.length ||
                      selectedLessonIndex.current >=
                        course.current.sectionList[selectedSectionIndex.current].lessonList.length
                    }
                    onClick={() => {
                      const lesson =
                        course.current.sectionList[selectedSectionIndex.current].lessonList[
                          selectedLessonIndex.current
                        ];
                      const c = { ...course.current };
                      course.current = {
                        ...c,
                        sectionList: c.sectionList.map((s, i) =>
                          i === selectedSectionIndex.current
                            ? {
                                ...s,
                                lessonList: s.lessonList.filter((l) => l.id !== lesson.id),
                              }
                            : s,
                        ),
                      };
                      selectedLessonIndex.current = 0;
                      setRefreshCount(refreshCount + 1);
                    }}
                  >
                    Delete
                  </button>
                  {selectedSectionIndex.current < course.current.sectionList.length &&
                  selectedLessonIndex.current <
                    course.current.sectionList[selectedSectionIndex.current].lessonList.length ? (
                    <div className="col-12 pt-4">
                      <hr />
                      <p>
                        Search, identify, and add content to the lesson{' '}
                        <b>
                          {
                            course.current.sectionList[selectedSectionIndex.current].lessonList[
                              selectedLessonIndex.current
                            ].name
                          }
                        </b>{' '}
                        in section <b>{course.current.sectionList[selectedSectionIndex.current].name}</b>
                      </p>
                      <label className="mx-2 pt-0 mt-0">Search</label>
                      <input
                        type="text"
                        className="pt-2 pb-1 mt-0 mb-0"
                        placeholder="Search Content Quantum"
                        value={cqFilterString}
                        onChange={(e) => {
                          setCqFilterString(e.target.value);
                        }}
                      />
                      <ul className="p-0 m-0 list-group">
                        {filteredContentQuanta().map((cq) => (
                          <li key={cq.id}>
                            <button className="btn btn-sm btn-secondary my-2 mx-1" onClick={() => addCqToLesson(cq.id)}>
                              +
                            </button>
                            {cqDisplayName(cq.id)}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <div className="col-12 py-4 px-0"></div>
                  )}
                </div>
                <div className="col-md-6 py-4 border rounded">
                  <h4>Generated Course</h4>
                  <hr />
                  Content Quanta: {course.current.contentQuanta}
                  <br />
                  Questions: {course.current.questions}
                  <br />
                  Size: {course.current.size}
                  <br />
                  Videos: {course.current.videoCount}
                  <hr />
                  <ul className="p-2 m-0 list-group">
                    {course.current.sectionList.map((s, i) => (
                      <li key={s.id}>
                        {s.name}
                        {i < course.current.sectionList.length - 1 && (
                          <button className="btn btn-sm" onClick={() => moveDownSection(i)}>
                            <i className="bi bi-arrow-down" />
                          </button>
                        )}
                        {i > 0 && (
                          <button className="btn btn-sm" onClick={() => moveUpSection(i)}>
                            <i className="bi bi-arrow-up" />
                          </button>
                        )}
                        <ul className="list-group px-3">
                          {course.current.sectionList[i].lessonList.map((l, j) => (
                            <>
                              <li key={`li-${l.id}`}>
                                {l.name}
                                {j < course.current.sectionList[i].lessonList.length - 1 && (
                                  <button className="btn btn-sm" onClick={() => moveDownLesson(i, j)}>
                                    <i className="bi bi-arrow-down" />
                                  </button>
                                )}
                                {j > 0 && (
                                  <button className="btn btn-sm" onClick={() => moveUpLesson(i, j)}>
                                    <i className="bi bi-arrow-up" />
                                  </button>
                                )}
                              </li>
                              {sectionLessonCqList(l, i, j)}
                            </>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-12 right">
                  <button className="btn btn-success btn-lg" onClick={saveCourse}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseBuilder;
