export const handleComprehensionFormSubmission = (
  response: any,
  refreshComprehension: () => void,
  setFormError: React.Dispatch<React.SetStateAction<string>>,
  setEdit: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  refreshComprehension();
  if (!response.success) {
    setFormError('Error updating number of equations, please retry');
    return;
  }

  setEdit(false);
};
