import { useEffect, useState } from 'react';
import { languageCodeToString, parseAnswerHTML } from '../../../utils/utils';
import { ErrorMessage } from '../../common/error-message';
import type { FIBTranslatedQuestion } from '../schema';
import TaggedStmtV2 from './tagged-stmt-v2';
import { WarnMessage } from '../../common/warn-message';
import { ProblemSaveEditStrip } from './problem-save-edit-strip';
import invariant from 'tiny-invariant';

export default function FIBQuestion({
  q,
  readonly,
  updateQuestion,
}: {
  q: FIBTranslatedQuestion;
  readonly: boolean;
  updateQuestion: (question: FIBTranslatedQuestion, updateInfo: any) => void;
}) {
  const [question, setQuestion] = useState<FIBTranslatedQuestion>(q);
  const [editing, setEditing] = useState(false);
  const [unCommmitedChanges, setUnCommittedChanges] = useState(false);
  useEffect(() => {
    setQuestion(q);
  }, [q]);

  const setUnCommited = () => {
    if (!unCommmitedChanges) {
      setUnCommittedChanges(true);
    }
  };

  const saveQuestion = () => {
    updateQuestion(question, { type: 'save' });
    setUnCommittedChanges(false);
  };
  return (
    <>
      <ErrorMessage error={question.err_conversion} />
      {unCommmitedChanges && <WarnMessage warning={'Uncommited Changes'} />}
      <ProblemSaveEditStrip
        readonly={readonly}
        editing={editing}
        setEditing={setEditing}
        save={() => {
          saveQuestion();
          setUnCommittedChanges(false);
        }}
      />
      <h3> heading="English Problem" </h3>
      <TaggedStmtV2
        tag="Problem"
        htmlContent={question.stmt}
        readonly={editing}
        onUpdate={(s: string) => {
          question.stmt = s;
          setQuestion(question);
          setUnCommited();
        }}
      />
      <TaggedStmtV2
        tag="Answer"
        htmlContent={question.ans}
        readonly={editing}
        onUpdate={(s: string) => {
          const resp = parseAnswerHTML(s || '', 'fillInTheBlank');
          if (resp.error) {
            alert(resp.error);
            return;
          }
          invariant(resp.fbAnswer, 'resp.fbAnswer is null');
          question.ans = resp.fbAnswer;
          setQuestion(question);
          setUnCommited();
        }}
      />
      <TaggedStmtV2
        tag="Solution"
        htmlContent={question.sol}
        readonly={editing}
        onUpdate={(s: string) => {
          question.sol = s;
          setQuestion(question);
          setUnCommited();
        }}
      />
      {question.translations.map((translation, index) => (
        <div key={index}>
          <h3>{`${languageCodeToString(translation.lang)} Problem`}</h3>
          <TaggedStmtV2
            tag="Problem"
            htmlContent={translation.stmt}
            readonly={editing}
            onUpdate={(s: string) => {
              question.translations[index].stmt = s;
              setQuestion(question);
              setUnCommited();
            }}
          />
          <TaggedStmtV2
            tag="Answer"
            htmlContent={translation.ans}
            readonly={editing}
            onUpdate={(s: string) => {
              const resp = parseAnswerHTML(s || '', 'fillInTheBlank');
              if (resp.error) {
                alert(resp.error);
                return;
              }
              invariant(resp.fbAnswer, 'resp.fbAnswer is null');
              question.translations[index].ans = resp.fbAnswer;
              setQuestion(question);
              setUnCommited();
            }}
          />
          <TaggedStmtV2
            tag="Solution"
            htmlContent={translation.sol}
            readonly={editing}
            onUpdate={(s: string) => {
              question.translations[index].sol = s;
              setQuestion(question);
              setUnCommited();
            }}
          />
        </div>
      ))}
    </>
  );
}
