import { useEffect, useState } from 'react';
import invariant from 'tiny-invariant';
import { htmlToString, languageCodeToString, parseAnswerHTML } from '../../../utils/utils';
import { ErrorMessage } from '../../common/error-message';
import type { SCQTranslatedQuestion } from '../schema';
import TaggedStmtV2 from './tagged-stmt-v2';
import { WarnMessage } from '../../common/warn-message';
import { ProblemSaveEditStrip } from './problem-save-edit-strip';

export default function SCQQuestion({
  q,
  readonly,
  updateQuestion,
}: {
  q: SCQTranslatedQuestion;
  readonly: boolean;
  updateQuestion: (question: SCQTranslatedQuestion) => void;
}) {
  const [question, setQuestion] = useState<SCQTranslatedQuestion>(q);
  const [editing, setEditing] = useState(false);
  const [unCommmitedChanges, setUnCommittedChanges] = useState(false);
  useEffect(() => {
    setQuestion(q);
  }, [q]);

  const setUnCommited = () => {
    if (!unCommmitedChanges) {
      setUnCommittedChanges(true);
    }
  };

  const saveQuestion = () => {
    updateQuestion(question);
    setUnCommittedChanges(false);
  };
  return (
    <>
      <ErrorMessage error={question.err_conversion} />
      {unCommmitedChanges && <WarnMessage warning={'Uncommited Changes'} />}
      <ProblemSaveEditStrip readonly={readonly} editing={editing} setEditing={setEditing} save={saveQuestion} />
      <div className="d-flex justify-content-between">
        <h4>English Problem</h4>
      </div>
      <TaggedStmtV2
        tag="Problem"
        htmlContent={question.stmt}
        readonly={editing}
        onUpdate={(s: string) => {
          question.stmt = s;
          setQuestion(question);
          setUnCommited();
        }}
      />
      {question.options &&
        question.options.map((option, index) => (
          <TaggedStmtV2
            key={index}
            tag={`Option ${index + 1}`}
            htmlContent={option}
            readonly={editing}
            onUpdate={(s: string) => {
              invariant(question.options, 'question.options is null');
              question.options[index] = s;
              setQuestion(question);
              setUnCommited();
            }}
          />
        ))}
      {!question.options && (
        <div className="m-4">
          <b>English Options are absent</b>
        </div>
      )}
      <TaggedStmtV2
        tag="Answer"
        htmlContent={question.obj_ans.toString()}
        readonly={editing}
        onUpdate={(s: string) => {
          const resp = parseAnswerHTML(s || '', 'scq');
          if (resp.error) {
            alert(resp.error);
            return;
          }
          invariant(resp.scqAnswer, 'resp.scqAnswer is null');
          question.obj_ans = resp.scqAnswer;
          setQuestion(question);
          setUnCommited();
        }}
      />
      <TaggedStmtV2
        tag="Solution"
        htmlContent={question.sol}
        readonly={editing}
        onUpdate={(s: string) => {
          question.sol = s;
          setQuestion(question);
          setUnCommited();
        }}
      />

      {question.translations.map((translation, index) => (
        <div key={index}>
          <h4>{`${languageCodeToString(translation.lang)} Problem`}</h4>
          <TaggedStmtV2
            tag="Problem"
            htmlContent={translation.stmt}
            readonly={editing}
            onUpdate={(s: string) => {
              question.translations[index].stmt = s;
              setQuestion(question);
              setUnCommited();
            }}
          />
          {translation.options &&
            translation.options.map((option, optionIndex) => (
              <TaggedStmtV2
                key={optionIndex}
                tag={`Option ${optionIndex + 1}`}
                htmlContent={option}
                readonly={editing}
                onUpdate={(s: string) => {
                  // @ts-ignore
                  question.translations[index].options[optionIndex] = s;
                  setQuestion(question);
                  setUnCommited();
                }}
              />
            ))}
          {!translation.options && (
            <div className="m-4">
              <b>{languageCodeToString(translation.lang)} Options are absent</b>
            </div>
          )}
          <TaggedStmtV2
            tag="Answer"
            htmlContent={translation.obj_ans.toString()}
            readonly={editing}
            onUpdate={(s: string) => {
              const resp = parseAnswerHTML(s || '', 'scq');
              if (resp.error) {
                alert(resp.error);
                return;
              }
              invariant(resp.scqAnswer, 'resp.scqAnswer is null');
              question.translations[index].obj_ans = resp.scqAnswer;
              setQuestion(question);
              setUnCommited();
            }}
          />
          <TaggedStmtV2
            tag="Solution"
            htmlContent={translation.sol}
            readonly={editing}
            onUpdate={(s: string) => {
              question.translations[index].sol = s;
              setQuestion(question);
              setUnCommited();
            }}
          />
        </div>
      ))}
    </>
  );
}
