import { z } from 'zod';

const commonSchema = z.object({
  source: z.string(),
  revise: z.boolean(),
  pyq: z.number() /* Implicit mapping: IITAdvanced = 1, IITMains = 2, NEET = 3 */,
  pyq_year: z.number() /* If 0 then not an exam */,
  pyq_details: z.string(),
});

const scqTexSchema = z.object({
  en_stmt: z.string(),
  en_sol: z.string(),
  en_options: z.array(z.string()),
  en_obj_ans: z.number().min(0).max(5), // If it is 0 then the answer is not known.
  hn_stmt: z.string(),
  hn_sol: z.string(),
  hn_options: z.array(z.string()).nullable(),
  hn_obj_ans: z.number().min(0).max(5), // If it is 0 then the answer is not known.
});

const scqHTMLSchema = scqTexSchema.extend({
  err_conversion: z.string().nullable(),
});

const scqSchema = z.object({
  type: z.literal(0),
  common: commonSchema,
  tex_elements: scqTexSchema,
  html_elements: scqHTMLSchema,
});

const scqTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('scq'),
  stmt: z.string(),
  sol: z.string(),
  options: z.array(z.string()),
  obj_ans: z.number().min(0).max(5), // If it is 0 then the answer is not known.
  translations: z
    .object({
      lang: z.string(),
      stmt: z.string(),
      sol: z.string(),
      options: z.array(z.string()),
      obj_ans: z.number().min(0).max(5),
      mode: z.union([z.literal('machine'), z.literal('semi'), z.literal('human')]),
    })
    .array(),
});

const mcqTexSchema = z.object({
  en_stmt: z.string(),
  en_sol: z.string(),
  en_options: z.array(z.string()),
  en_obj_ans: z.number().min(1).max(5).array(), // If len is 0 then the answer is not known.
  hn_stmt: z.string(),
  hn_sol: z.string(),
  hn_options: z.array(z.string()).nullable(),
  hn_obj_ans: z.number().min(1).max(5).array(), // If len is 0 then the answer is not known.
});

const mcqHTMLSchema = mcqTexSchema.extend({
  err_conversion: z.string().nullable(),
});

const mcqSchema = z.object({
  type: z.literal(1),
  common: commonSchema,
  tex_elements: mcqTexSchema,
  html_elements: mcqHTMLSchema,
});

const mcqTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('mcq'),
  stmt: z.string(),
  sol: z.string(),
  options: z.array(z.string()),
  obj_ans: z.number().min(1).max(5).array(), // If len is 0 then the answer is not known.
  translations: z
    .object({
      lang: z.string(),
      stmt: z.string(),
      sol: z.string(),
      options: z.array(z.string()),
      obj_ans: z.number().min(1).max(5).array(),
      mode: z.union([z.literal('machine'), z.literal('semi'), z.literal('human')]),
    })
    .array(),
});

const subjectiveTexSchema = z.object({
  en_stmt: z.string(),
  en_sol: z.string(),
  en_ans: z.string(),
  hn_stmt: z.string(),
  hn_sol: z.string(),
  hn_ans: z.string(),
});

const subjectiveHTMLSchema = subjectiveTexSchema.extend({
  err_conversion: z.string().nullable(),
});

const subjectiveSchema = z.object({
  type: z.literal(4),
  common: commonSchema,
  tex_elements: subjectiveTexSchema,
  html_elements: subjectiveHTMLSchema,
});

const subjectiveTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('subjective'),
  stmt: z.string(),
  sol: z.string(),
  ans: z.string(),
  translations: z
    .object({
      lang: z.string(),
      stmt: z.string(),
      sol: z.string(),
      ans: z.string(),
      mode: z.union([z.literal('machine'), z.literal('semi'), z.literal('human')]),
    })
    .array(),
});

const integerTexSchema = z.object({
  en_stmt: z.string(),
  en_sol: z.string(),
  hn_stmt: z.string(),
  hn_sol: z.string(),
  int_ans: z.number(),
  ans_absent: z.boolean(),
});

const integerHTMLSchema = integerTexSchema.extend({
  err_conversion: z.string().nullable(),
});

const integerSchema = z.object({
  type: z.literal(6),
  common: commonSchema,
  tex_elements: integerTexSchema,
  html_elements: integerHTMLSchema,
});

const integerTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('integerType'),
  stmt: z.string(),
  sol: z.string(),
  int_ans: z.number(),
  ans_absent: z.boolean(),
  translations: z
    .object({
      lang: z.string(),
      stmt: z.string(),
      sol: z.string(),
      int_ans: z.number(),
      ans_absent: z.boolean(),
      mode: z.union([z.literal('machine'), z.literal('semi'), z.literal('human')]),
    })
    .array(),
});

const arTexSchema = z.object({
  en_assr: z.string(),
  en_rsn: z.string(),
  en_sol: z.string(),
  hn_assr: z.string(),
  hn_rsn: z.string(),
  hn_sol: z.string(),
  ar_ans: z.number().min(0).max(5),
});

const arHTMLSchema = arTexSchema.extend({
  err_conversion: z.string().nullable(),
});

const arSchema = z.object({
  type: z.literal(7),
  common: commonSchema,
  tex_elements: arTexSchema,
  html_elements: arHTMLSchema,
});

const arTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('assertionReason'),
  assr: z.string(),
  rsn: z.string(),
  sol: z.string(),
  ar_ans: z.number().min(0).max(5),
  translations: z
    .object({
      lang: z.string(),
      assr: z.string(),
      rsn: z.string(),
      sol: z.string(),
      ar_ans: z.number().min(0).max(5),
      mode: z.union([z.literal('machine'), z.literal('semi'), z.literal('human')]),
    })
    .array(),
});

const matrixMatchTexSchema = z.object({
  en_colA: z.string().array(),
  en_colB: z.string().array(),
  hn_colA: z.string().array().nullable(),
  hn_colB: z.string().array().nullable(),
  en_sol: z.string(),
  hn_sol: z.string(),
  ans_absent: z.boolean(),
  mat_ans: z.number().array().array(),
});

const matrixMatchHTMLSchema = matrixMatchTexSchema.extend({
  err_conversion: z.string().nullable(),
});

const matrixMatchSchema = z.object({
  type: z.literal(5),
  common: commonSchema,
  tex_elements: matrixMatchTexSchema,
  html_elements: matrixMatchHTMLSchema,
});

const matrixMatchTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('matrixMatch'),
  colA: z.string().array(),
  colB: z.string().array(),
  sol: z.string(),
  ans_absent: z.boolean(),
  mat_ans: z.number().array().array(),
  stmt: z.string().optional(),
  translations: z
    .object({
      lang: z.string(),
      stmt: z.string().optional(),
      colA: z.string().array(),
      colB: z.string().array(),
      sol: z.string(),
      ans_absent: z.boolean(),
      mat_ans: z.number().array().array(),
      mode: z.union([z.literal('machine'), z.literal('semi'), z.literal('human')]),
    })
    .array(),
});

const tfTexSchema = z.object({
  en_stmt: z.string(),
  en_sol: z.string(),
  hn_stmt: z.string(),
  hn_sol: z.string(),
  tf_ans: z.boolean(),
  ans_absent: z.boolean(),
});

const tfHTMLSchema = tfTexSchema.extend({
  err_conversion: z.string().nullable(),
});

const tfSchema = z.object({
  type: z.literal(3),
  common: commonSchema,
  tex_elements: tfTexSchema,
  html_elements: tfHTMLSchema,
});

const tfTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('trueFalse'),
  stmt: z.string(),
  sol: z.string(),
  tf_ans: z.boolean(),
  ans_absent: z.boolean(),
  translations: z
    .object({
      lang: z.string(),
      stmt: z.string(),
      sol: z.string(),
      tf_ans: z.boolean(),
      ans_absent: z.boolean(),
      mode: z.union([z.literal('machine'), z.literal('semi'), z.literal('human')]),
    })
    .array(),
});

const fibTexSchema = z.object({
  en_stmt: z.string(),
  en_sol: z.string(),
  hn_stmt: z.string(),
  hn_sol: z.string(),
  en_ans: z.string(),
  hn_ans: z.string(),
});

const fibHTMLSchema = fibTexSchema.extend({
  err_conversion: z.string().nullable(),
});

const fibSchema = z.object({
  type: z.literal(2),
  common: commonSchema,
  tex_elements: fibTexSchema,
  html_elements: fibHTMLSchema,
});

const fibTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  common: commonSchema,
  type: z.literal('fillInTheBlank'),
  stmt: z.string(),
  sol: z.string(),
  ans: z.string(),
  translations: z
    .object({
      lang: z.string(),
      stmt: z.string(),
      sol: z.string(),
      ans: z.string(),
    })
    .array(),
});

const questionSchema = z.union([
  scqSchema,
  mcqSchema,
  subjectiveSchema,
  integerSchema,
  arSchema,
  matrixMatchSchema,
  tfSchema,
  fibSchema,
]);

const questionTranslatedSchema = z.union([
  scqTranslatedSchema,
  mcqTranslatedSchema,
  subjectiveTranslatedSchema,
  integerTranslatedSchema,
  arTranslatedSchema,
  matrixMatchTranslatedSchema,
  tfTranslatedSchema,
  fibTranslatedSchema,
]);

const passageTexSchema = z.object({
  en_pssg: z.string(),
  hn_pssg: z.string(),
});

const passageHTMLSchema = passageTexSchema.extend({
  err_conversion: z.string().nullable(),
});

const comprehensionSchema = z.object({
  tex_elements: passageTexSchema,
  html_elements: passageHTMLSchema,
  questions: questionSchema.array(),
});

const comprehensionTranslatedSchema = z.object({
  err_conversion: z.string().nullable(),
  passage: z.string(),
  questions: questionTranslatedSchema.array(),
  translations: z
    .object({
      lang: z.string(),
      passage: z.string(),
      mode: z.union([z.literal('machine'), z.literal('human'), z.literal('semi')]),
    })
    .array(),
});

const convertedQuestionsSchema = questionSchema.array().nullable();
const convertedComprehensionsSchema = comprehensionSchema.array().nullable();
const parseAPIResponseSchema = z.object({
  questions: z.any(),
  comprehensions: z.any(),
  convertedQuestions: convertedQuestionsSchema.optional(),
  convertedComprehensions: convertedComprehensionsSchema.optional(),
  numQuestions: z.coerce.number().min(0),
});

type SCQConvertedQuestion = z.infer<typeof scqSchema>;
type SCQTranslatedQuestion = z.infer<typeof scqTranslatedSchema>;
type MCQConvertedQuestion = z.infer<typeof mcqSchema>;
type MCQTranslatedQuestion = z.infer<typeof mcqTranslatedSchema>;
type ARConvertedQuestion = z.infer<typeof arSchema>;
type ARTranslatedQuestion = z.infer<typeof arTranslatedSchema>;
type MatrixMatchConvertedQuestion = z.infer<typeof matrixMatchSchema>;
type MatrixMatchTranslatedQuestion = z.infer<typeof matrixMatchTranslatedSchema>;
type TFConvertedQuestion = z.infer<typeof tfSchema>;
type TFTranslatedQuestion = z.infer<typeof tfTranslatedSchema>;
type FIBConvertedQuestion = z.infer<typeof fibSchema>;
type FIBTranslatedQuestion = z.infer<typeof fibTranslatedSchema>;
type SubjectiveConvertedQuestion = z.infer<typeof subjectiveSchema>;
type SubjectiveTranslatedQuestion = z.infer<typeof subjectiveTranslatedSchema>;
type IntegerConvertedQuestion = z.infer<typeof integerSchema>;
type IntegerTranslatedQuestion = z.infer<typeof integerTranslatedSchema>;

type ConvertedQuestion = z.infer<typeof questionSchema>;
type TranslatedQuestion = z.infer<typeof questionTranslatedSchema>;
type ConvertedComprehension = z.infer<typeof comprehensionSchema>;
type TranslatedComprehension = z.infer<typeof comprehensionTranslatedSchema>;
type ConvertedQuestions = z.infer<typeof convertedQuestionsSchema>;
type ConvertedComprehensions = z.infer<typeof convertedComprehensionsSchema>;
type ParseAPIResponse = z.infer<typeof parseAPIResponseSchema>;

export {
  questionSchema,
  questionTranslatedSchema,
  convertedQuestionsSchema,
  comprehensionSchema,
  comprehensionTranslatedSchema,
  convertedComprehensionsSchema,
  parseAPIResponseSchema,
};

export type {
  ConvertedQuestions,
  ConvertedComprehensions,
  ParseAPIResponse,
  ConvertedQuestion,
  ConvertedComprehension,
  SCQConvertedQuestion,
  MCQConvertedQuestion,
  ARConvertedQuestion,
  MatrixMatchConvertedQuestion,
  TFConvertedQuestion,
  FIBConvertedQuestion,
  SubjectiveConvertedQuestion,
  IntegerConvertedQuestion,
  TranslatedQuestion,
  SCQTranslatedQuestion,
  MCQTranslatedQuestion,
  ARTranslatedQuestion,
  MatrixMatchTranslatedQuestion,
  TFTranslatedQuestion,
  FIBTranslatedQuestion,
  SubjectiveTranslatedQuestion,
  IntegerTranslatedQuestion,
  TranslatedComprehension,
};
