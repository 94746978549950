/*
 * --------------------------------------------------------------------------- *
 * File: cloud.ts                                                              *
 * Project: faculty-dashboard                                                  *
 * Created Date: 18 Feb 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Sun Mar 12 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import axios from 'axios';
import { PublicConstants } from './constants';

/**
 * Interface to all API calls to the cloud
 */
export class Cloud {
  static apiBaseUrl = `https://api.${PublicConstants.BASE_DOMAIN}`;
  static configUrl = `https://cdn.${PublicConstants.BASE_DOMAIN}/config.json?v=${Date.now()}`;

  static token = '';

  static setAuthToken(token: string): void {
    Cloud.token = token;
  }

  static async uploadFile(name: string, subject: string, chapter: string, data: any): Promise<void> {
    const response = await this.post(
      { action: PublicConstants.ACTION_SIGNED_URL, data: { name, subject, chapter } },
      PublicConstants.API_CONTENT,
    );
    if (response.success) {
      await axios.put(response.value.url, data);
    }
  }

  static async uploadTranslationFile(batchId: string, data: any): Promise<void> {
    const response = await this.post(
      { action: PublicConstants.ACTION_SIGNED_URL_TRANSLATION, data: { batchId } },
      PublicConstants.API_CONTENT,
    );
    if (response.success) {
      await axios.put(response.value.url, data);
    }
  }

  static async uploadImageFile(type: string, data: any): Promise<string> {
    const response = await this.post(
      { action: PublicConstants.ACTION_GET_IMAGE_SIGNED_URL, data: type },
      PublicConstants.API_COURSE,
    );
    if (response.success) {
      console.log(response);
      await axios.put(response.value.url, data);
      return response.value.name;
    }
    return '';
  }

  static async uploadAudioBlob(type: string, data: any): Promise<string> {
    const response = await this.post(
      { action: PublicConstants.ACTION_GET_AUDIO_SIGNED_URL, data: { type } },
      PublicConstants.API_CONTENT,
    );
    if (response.success) {
      await axios.put(response.value.url, data);
      return response.value.name;
    }
    return '';
  }

  static async post(request: CloudRequest, api: string): Promise<CloudResponse> {
    console.log('Cloud.post', api, Cloud.token);
    const response = await axios.post(`${this.apiBaseUrl}/${api.toLowerCase()}`, request, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cloud.token,
      },
    });
    const data = response.data as CloudResponse;
    return data;
  }

  static async getConfig(): Promise<Config> {
    const response = await axios.get(this.configUrl);
    const data = response.data as Config;
    return data;
  }

  static async getContentFile(batchId: string, languageId: string, contentId: string): Promise<any> {
    const response = await axios.get(
      `https://${PublicConstants.CONTENT_SUB_DOMAIN}.${PublicConstants.BASE_DOMAIN}/contents/${batchId}/${
        languageId === 'en' ? '' : languageId + '/'
      }${contentId}.json?v=${Date.now()}`,
    );

    if (response.status === 200) {
      return response.data;
    }
    console.log(JSON.stringify(response));
    return '';
  }
}

export interface CloudRequest {
  action: string;
  data: unknown;
}

export interface CloudResponse {
  success: boolean;
  value: any;
}

export interface Config {
  answerTemplates: any;
  subjects: string[];
  languages: string[];
  subjectChapters: { [key: string]: string[] };
  holidays: any;
  hardHolidays: string[];
  targetConfigurations: any;
  examConfigurations: any;
}
