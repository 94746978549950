import React from 'react';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import { IQuestion } from '../../../../lambda/question/question-schema';
import { ErrorMessage } from '../common/error-message';
import { AppContext } from '../../core/context';
import { handleQuestionFormSubmission } from '../../utils/questions';

const overallDifficultyOptions = [
  { value: 'default', label: 'Select Difficulty' },
  { value: '1', label: 'Board level' },
  { value: '2', label: 'Exercise 1 level' },
  { value: '3', label: 'Exercise 2 level/JEE Advanced level' },
  { value: '4', label: 'ALP/Olympiad Level' },
];

const calculationDifficultyOptions = [
  { value: 'default', label: 'Select Difficulty' },
  { value: '1', label: '< 15 seconds calculation' },
  { value: '2', label: '< 1 min calculation' },
  { value: '3', label: '< 3 min calculation' },
  { value: '4', label: '< 10 min calculation' },
  { value: '5', label: '> 10 min calculation' },
];

export function DifficultyLevelsForm({
  question,
  refreshQuestion,
}: {
  question: IQuestion;
  refreshQuestion: () => void;
}) {
  const [formError, setFormError] = React.useState('');
  const { state } = React.useContext(AppContext);
  const [edit, setEdit] = React.useState(false);

  let overallDifficultyKnown: boolean = false;
  let calculationDifficultyKnown: boolean = false;
  let overallDifficultyValue: number = -1;
  let calculationDifficultyValue: number = -1;
  if (question) {
    let difficulty = question.subjectiveOverallDifficulty.filter(
      (difficulty) => difficulty.author === state.userInfo?.info.email,
    );
    if (difficulty.length === 0) {
      overallDifficultyKnown = false;
    } else {
      overallDifficultyKnown = true;
      overallDifficultyValue = difficulty[0].difficulty;
    }

    difficulty = question.subjectiveCalculationDifficulty.filter(
      (difficulty) => difficulty.author === state.userInfo?.info.email,
    );
    if (difficulty.length === 0) {
      calculationDifficultyKnown = false;
    } else {
      calculationDifficultyKnown = true;
      calculationDifficultyValue = difficulty[0].difficulty;
    }
  }

  const userEmail = state.userInfo?.info.email;
  return (
    <div className="row border p-4 border-secondary">
      <ErrorMessage error={formError} />
      <div className="container">
        <div className="row">
          <div className="col-4">
            <h5>Subjective Difficulty</h5>
          </div>
          <div className="col-7">
            {!overallDifficultyKnown ? (
              <h6 className="text-warning">Overall difficulty not selected</h6>
            ) : (
              <h6 className="text-success">
                Overall difficulty selected:{' '}
                {overallDifficultyOptions.filter((v) => Number(v.value) === overallDifficultyValue)[0].label}
              </h6>
            )}
            {'  '}
            {!calculationDifficultyKnown ? (
              <h6 className="text-warning">Calculation difficulty not selected</h6>
            ) : (
              <h6 className="text-success">
                Calculation difficulty selected:{' '}
                {calculationDifficultyOptions.filter((v) => Number(v.value) === calculationDifficultyValue)[0].label}{' '}
              </h6>
            )}
          </div>
          <div className="col-1">
            <button type="button" className="btn btn-primary" onClick={() => setEdit(!edit)}>
              Edit
            </button>
          </div>
        </div>
        {edit && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setFormError('');
              const formData = Object.fromEntries(new FormData(e.target as HTMLFormElement));
              const overallDifficulty = formData['difficulty-level'] as string;
              const calculationDifficulty = formData['calculation-difficulty'] as string;
              if (overallDifficulty === 'default' || calculationDifficulty === 'default') {
                setFormError('Please select a valid option');
                return;
              }

              Cloud.post(
                {
                  action: PublicConstants.ACTION_UPDATE_QUESTION,
                  // @ts-ignore
                  data: {
                    id: question._id,
                    update: PublicConstants.UPDATE_SUBJECTIVE_DIFFICULTY,
                    overallDifficulty,
                    calculationDifficulty,
                    author: userEmail,
                  },
                },
                PublicConstants.API_QUESTION,
              ).then((response) => {
                handleQuestionFormSubmission(response, refreshQuestion, setFormError, setEdit);
              });
            }}
          >
            <div className="row">
              <div className="col-6">
                <label htmlFor="OverallDifficulty" className="form-label">
                  Overall difficulty
                </label>
                <select defaultValue={'default'} id="OverallDifficulty" className="form-select" name="difficulty-level">
                  {overallDifficultyOptions.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-6">
                <label htmlFor="CalculationDifficulty" className="form-label">
                  Calculation difficulty
                </label>
                <select
                  defaultValue={'default'}
                  id="CalculationDifficulty"
                  className="form-select"
                  name="calculation-difficulty"
                >
                  {calculationDifficultyOptions.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <button type="submit" className="col-12 mt-3 btn btn-primary">
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
