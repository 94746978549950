/*
 * --------------------------------------------------------------------------- *
 * File: target.tsx                                                            *
 * Project: faculty-dashboard                                                  *
 * Created Date: 22 Feb 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Sat Mar 25 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import { nanoid } from 'nanoid';
import React, { useEffect, useRef } from 'react';
import { BlankTarget, Course, Target, Exam, BlankExam, Holiday } from '../../core/types';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { Cloud, Config } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import moment from 'moment';
import Select from 'react-select';
import Dropzone from 'react-dropzone';

const reader = new FileReader();

function TargetBuilder() {
  const wipTarget = useRef<Target>(BlankTarget());
  const targetList = useRef<Target[]>([]);
  const allCourses = useRef<any>({});
  const newExam = useRef<Exam>(BlankExam());
  const config = useRef<Config>();
  const [refreshCount, setRefreshCount] = React.useState(0);
  const [examFrequency, setExamFrequency] = React.useState(7);
  const [examCycleOffset, setExamCycleOffset] = React.useState(1);
  const [examCount, setExamCount] = React.useState(1);
  const clashes = useRef<string[]>([]);
  const [expandHolidays, setExpandHolidays] = React.useState(false);
  const [expandExams, setExpandExams] = React.useState(false);

  const saveTarget = async (params: any): Promise<boolean> => {
    if (wipTarget.current.name === '') {
      alert('Please enter a name');
      return false;
    }
    if (wipTarget.current.description === '') {
      alert('Please enter a description');
      return false;
    }
    if (wipTarget.current.logo === '') {
      alert('Please upload a logo');
      return false;
    }
    if (wipTarget.current.courseList.length === 0) {
      alert('Please select at least one course');
      return false;
    }
    if (wipTarget.current.examList.length === 0) {
      alert('Please add at least one exam');
      return false;
    }
    if (clashes.current.length > 0) {
      alert('Please resolve the following exam clashes: ' + clashes.current.join(', '));
      return false;
    }

    if (wipTarget.current.id) {
      const index = targetList.current.findIndex((t) => t.id === wipTarget.current.id);
      if (index !== -1) targetList.current[index] = wipTarget.current;
      else targetList.current.push(wipTarget.current);
    }
    const response = await Cloud.post(
      { action: PublicConstants.ACTION_SAVE_TARGET, data: wipTarget.current },
      PublicConstants.API_COURSE,
    );
    if (response.success && !params.silent) {
      alert('Saved Successfully');
      setRefreshCount(refreshCount + 1);
    }
    return true;
  };

  const loadTargets = async () => {
    const response = await Cloud.post(
      { action: PublicConstants.ACTION_GET_TARGET_LIST, data: {} },
      PublicConstants.API_COURSE,
    );
    if (response.success) {
      targetList.current = response.value;
    }
  };

  const loadCourses = async () => {
    const response = await Cloud.post(
      { action: PublicConstants.ACTION_GET_ALL_COURSES, data: {} },
      PublicConstants.API_COURSE,
    );
    if (response.success) {
      response.value.forEach((course: Course) => {
        allCourses.current[course.id] = course;
      });
    }
  };

  const upload = async (f: File) => {
    reader.readAsDataURL(f);
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      // @ts-ignore
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = async function () {
        var height = image.height;
        var width = image.width;
        if (height !== 300 || width !== 400) {
          alert('Logo image must be 400px wide and 300px high.');
          return false;
        }
        await new Promise((resolve) => {
          reader.onload = async (x) => {
            Cloud.uploadImageFile(f.name.split('.').pop() || '.jpg', x.target?.result as ArrayBuffer)
              .then((n) => (wipTarget.current.logo = n))
              .then((x) => resolve(''))
              .catch((e) => alert('Error uploading image'));
          };
          reader.readAsArrayBuffer(f);
        });
        setRefreshCount(refreshCount + 1);
      };
    };
  };

  const onImageDrop = async (acceptedFiles: File[]) => {
    for (let i = 0; i < acceptedFiles.length; i++) {
      await upload(acceptedFiles[i]);
    }
  };

  useEffect(() => {
    loadTargets()
      .then(() => loadCourses())
      .then(() => {
        setRefreshCount(refreshCount + 1);
      });
    Cloud.getConfig().then((x) => {
      config.current = x;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Refresh the counts
    let dirty = false;
    let n = wipTarget.current.courseList.reduce((s, a) => s + allCourses.current[a].contentQuanta, 0);
    if (n !== wipTarget.current.statistics.contentQuanta) {
      wipTarget.current.statistics.contentQuanta = n;
      dirty = true;
    }
    n = wipTarget.current.courseList.reduce((s, a) => s + allCourses.current[a].questions, 0);
    if (n !== wipTarget.current.statistics.questions) {
      wipTarget.current.statistics.questions = n;
      dirty = true;
    }
    n = wipTarget.current.courseList.reduce((s, a) => s + allCourses.current[a].size, 0);
    if (n !== wipTarget.current.statistics.netEffort) {
      console.log('netEffort', n, wipTarget.current.statistics.netEffort);
      wipTarget.current.statistics.netEffort = n;
      dirty = true;
    }
    n = wipTarget.current.courseList.reduce((s, a) => s + allCourses.current[a].videoCount, 0);
    if (n !== wipTarget.current.statistics.videoCount) {
      console.log('videoCount', n, wipTarget.current.statistics.videoCount);
      wipTarget.current.statistics.videoCount = n;
      dirty = true;
    }
    n = wipTarget.current.courseList.length;
    if (n !== wipTarget.current.statistics.courses) {
      wipTarget.current.statistics.courses = n;
      dirty = true;
    }
    n = wipTarget.current.examList.length;
    if (n !== wipTarget.current.statistics.exams) {
      wipTarget.current.statistics.exams = n;
      dirty = true;
    }
    let subjects: any = {};
    Object.keys(allCourses.current)
      .map((key) => key.split('::')[0])
      .forEach((subject) => {
        subjects[subject] = true;
      });
    n = wipTarget.current.courseList.length;
    if (n !== wipTarget.current.statistics.subjects) {
      wipTarget.current.statistics.subjects = n;
      dirty = true;
    }

    if (dirty) {
      setRefreshCount(refreshCount + 1);
    }
  }, [refreshCount]);

  const checkClashes = () => {
    console.log('Checking the clashes');
    clashes.current = [];
    for (var i = 0; i < wipTarget.current.examList.length; i++) {
      for (var j = 0; j < wipTarget.current.holidays.length; j++) {
        if (wipTarget.current.examList[i].date === wipTarget.current.holidays[j].date) {
          clashes.current.push(wipTarget.current.examList[i].date);
        }
      }
    }
  };

  const addNewExam = () => {
    if (!newExam.current.type) {
      alert('Please select exam configuration');
      return;
    }
    wipTarget.current.examList.push({
      id: nanoid(),
      type: newExam.current.type,
      date: getDateString(new Date().getTime()),
    });
    wipTarget.current.examList.sort((a, b) => a.date.localeCompare(b.date));
    checkClashes();
    setRefreshCount(refreshCount + 1);
  };

  const addExamsToSchedule = () => {
    if (!newExam.current.type) {
      alert('Please select exam configuration');
      return;
    }
    let start = new Date(wipTarget.current.startDate);
    start.setDate(start.getDate() + examCycleOffset);
    let end = new Date(wipTarget.current.targetDate);
    let count = 0;
    while (start < end && count < examCount) {
      wipTarget.current.examList.push({
        id: nanoid(),
        type: newExam.current.type,
        date: getDateString(start.getTime()),
      });
      start.setDate(start.getDate() + examFrequency);
      count++;
    }
    wipTarget.current.examList.sort((a, b) => a.date.localeCompare(b.date));
    checkClashes();
    setRefreshCount(refreshCount + 1);
  };

  const clearExamSchedule = () => {
    console.log('Clear Schedule');
    wipTarget.current.examList = [];
    checkClashes();
    setRefreshCount(refreshCount + 1);
  };

  const loadNationalHolidays = async () => {
    let holidays: Holiday[] = [];
    Object.keys(config.current?.holidays).forEach((key) => {
      config.current?.holidays[key].forEach((a: any) => {
        const d = Date.parse(a.date);
        if (d >= wipTarget.current.startDate && d <= wipTarget.current.targetDate)
          holidays.push({ date: a.date, name: a.name } as Holiday);
      });
    });
    wipTarget.current.holidays = holidays;
    checkClashes();
    setRefreshCount(refreshCount + 1);
  };

  const getDateString = (epoch: number) => {
    let date = new Date(epoch);
    let m: any = date?.getMonth() + 1;
    if (m < 10) m = '0' + m;
    let d: any = date?.getDate();
    if (d < 10) d = '0' + d;
    return `${date?.getFullYear()}-${m}-${d}`;
  };

  const publishTarget = async () => {
    const goodToPublish = await saveTarget({ silent: true });
    if (!goodToPublish) return;
    const response = await Cloud.post(
      {
        action: PublicConstants.ACTION_PUBLISH_TARGET,
        data: { id: wipTarget.current.id },
      },
      PublicConstants.API_COURSE,
    );
    if (response.success) {
      wipTarget.current.published = true;
      setRefreshCount(refreshCount + 1);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 pt-4">
          <h1>Targets</h1>
          <hr />
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Target Date</th>
                <th>Start Date</th>
                <th>Target Exam</th>
                <th>Students</th>
              </tr>
            </thead>
            <tbody>
              {targetList.current.map((target, i) => (
                <tr
                  key={target.id}
                  style={
                    target.id === wipTarget.current.id ? { backgroundColor: '#eeeef8' } : { backgroundColor: '#ffffff' }
                  }
                  className="click"
                  onClick={() => {
                    wipTarget.current = JSON.parse(JSON.stringify(targetList.current[i]));
                    setRefreshCount(refreshCount + 1);
                  }}
                >
                  <td>{target.name}</td>
                  <td>{moment.unix(target.targetDate / 1000).format('DD MMM YYYY')}</td>
                  <td>{moment.unix(target.startDate / 1000).format('DD MMM YYYY')}</td>
                  <td>{config.current?.targetConfigurations[target.targetExam]?.title}</td>
                  <td>{target.statistics.students}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={5}>
                  <button
                    className="btn btn-secondary wide"
                    onClick={() => {
                      wipTarget.current = { ...BlankTarget(), id: nanoid() };
                      setRefreshCount(refreshCount + 1);
                    }}
                  >
                    Create Target
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {wipTarget.current.id && (
        <>
          <div className="row">
            <div className="col-12">
              <h3>Target Details</h3>
              <hr />
            </div>
            <div className="col-6">
              <label>Name</label>
              <input
                className="py-2 my-0 pb-1"
                readOnly={wipTarget.current.published}
                type="text"
                placeholder="Target Name"
                value={wipTarget.current.name}
                onChange={(e) => {
                  wipTarget.current.name = e.target.value;
                  setRefreshCount(refreshCount + 1);
                }}
              />
            </div>
            <div className="col-6">
              <label>Target Exam</label>
              <Select
                isDisabled={wipTarget.current.published}
                options={Object.keys(config.current?.targetConfigurations).map((a: string) => ({
                  value: a,
                  label: config.current?.targetConfigurations[a]?.title,
                }))}
                value={{
                  value: wipTarget.current.targetExam,
                  label: config.current?.targetConfigurations[wipTarget.current.targetExam]?.title,
                }}
                onChange={(e) => {
                  wipTarget.current.targetExam = e?.value || '';
                  setRefreshCount(refreshCount + 1);
                }}
              />
            </div>
            <div className="col-10">
              <label>Description</label>
              <input
                type="text"
                readOnly={wipTarget.current.published}
                className="py-2 my-0 pb-1"
                placeholder="Target Description"
                value={wipTarget.current.description}
                onChange={(e) => {
                  wipTarget.current.description = e.target.value;
                  setRefreshCount(refreshCount + 1);
                }}
              />
            </div>
            <div className="col-2 pt-4 right">
              <button className="btn btn-secondary mt-2" onClick={publishTarget} disabled={wipTarget.current.published}>
                Publish
              </button>
            </div>
            <div
              className="col-12"
              style={wipTarget.current.logo ? { display: 'block', textAlign: 'center' } : { display: 'none' }}
            >
              <img
                src={`https://${PublicConstants.CONTENT_SUB_DOMAIN}.${PublicConstants.BASE_DOMAIN}/${wipTarget.current.logo}`}
                alt="Course Logo Missing"
                className="img-fluid pt-2"
              />
            </div>
            <div className="col-12">
              <Dropzone onDrop={onImageDrop} disabled={wipTarget.current.published}>
                {({ getRootProps, getInputProps }) => (
                  <section className="p-5 my-2 border border-black border-rounded click">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p className="p-0 m-0 border-black text-center">
                        Drag and drop the course logo image here, or click to select files. Any image of size 400x300
                        pixels
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="col-12">
              <div className="container">
                <div className="row p-2 my-3 border rounded">
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label>Start Date</label>
                    <DatePicker
                      disabled={wipTarget.current.published}
                      selected={new Date(wipTarget.current.startDate)}
                      onChange={(date) => {
                        wipTarget.current.startDate = date?.getTime() || 0;
                        setRefreshCount(refreshCount + 1);
                      }}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label>Target Date</label>
                    <DatePicker
                      disabled={wipTarget.current.published}
                      selected={new Date(wipTarget.current.targetDate)}
                      onChange={(date) => {
                        wipTarget.current.targetDate = date?.getTime() || 0;
                        setRefreshCount(refreshCount + 1);
                      }}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label>Subjects</label>
                    <input
                      type="number"
                      disabled={wipTarget.current.published}
                      value={Math.floor(wipTarget.current.statistics.subjects)}
                      readOnly
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label>Courses</label>
                    <input type="number" value={Math.floor(wipTarget.current.statistics.courses)} readOnly />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label>Content Quanta</label>
                    <input type="number" value={Math.floor(wipTarget.current.statistics.contentQuanta)} readOnly />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label>Exams</label>
                    <input type="number" value={Math.floor(wipTarget.current.statistics.exams)} readOnly />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label>Videos</label>
                    <input type="number" value={Math.floor(wipTarget.current.statistics.videoCount)} readOnly />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label>Effort</label>
                    <input type="number" value={Math.floor(wipTarget.current.statistics.netEffort)} readOnly />
                  </div>
                </div>
                <div className="row p-2 my-3 border rounded">
                  <div className="col-12 col-lg-12">
                    <h3>
                      Exams
                      <button className="btn btn-lg" onClick={(e) => setExpandExams(!expandExams)}>
                        {expandExams ? '-' : '+'}
                      </button>
                    </h3>
                    <hr />
                    <div className="container" style={expandExams ? { display: 'block' } : { display: 'none' }}>
                      <div className="row">
                        <div className="col-12 col-sm-4">
                          Frequency
                          <input
                            readOnly={wipTarget.current.published}
                            className="py-2 my-0 pb-1"
                            type={'number'}
                            value={examFrequency}
                            placeholder={'Frequency'}
                            onChange={(e) => setExamFrequency(parseInt(e.target.value))}
                          />
                        </div>
                        <div className="col-12 col-sm-4">
                          Offset
                          <input
                            readOnly={wipTarget.current.published}
                            className="py-2 my-0 pb-1"
                            type={'number'}
                            value={examCycleOffset}
                            placeholder={'Offset'}
                            onChange={(e) => setExamCycleOffset(parseInt(e.target.value))}
                          />
                        </div>
                        <div className="col-12 col-sm-4">
                          Count
                          <input
                            readOnly={wipTarget.current.published}
                            className="py-2 my-0 pb-1"
                            type={'number'}
                            value={examCount}
                            placeholder={'Count'}
                            onChange={(e) => setExamCount(parseInt(e.target.value))}
                          />
                        </div>
                        <div className="col-12 col-md-6 mt-2">
                          <Select
                            isDisabled={wipTarget.current.published}
                            options={Object.keys(config.current?.examConfigurations).map((id: string) => ({
                              value: id,
                              label: config.current?.examConfigurations[id]?.title,
                            }))}
                            value={{
                              value: newExam.current.type,
                              label: config.current?.examConfigurations[newExam.current.type]?.title,
                            }}
                            onChange={(e) => {
                              newExam.current.type = e?.value || '';
                              setRefreshCount(refreshCount + 1);
                            }}
                          />
                        </div>
                        <div className="col-12 col-md-6 right mt-2">
                          <button
                            className="btn btn-secondary btn-sm"
                            onClick={clearExamSchedule}
                            disabled={wipTarget.current.published}
                          >
                            Clear
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-secondary btn-sm"
                            onClick={addExamsToSchedule}
                            disabled={wipTarget.current.published}
                          >
                            Add Days
                          </button>
                        </div>
                        <div className="col-12">
                          <hr />
                        </div>
                        <div className="col-12">
                          <div className="coltainer">
                            <div className="row">
                              {wipTarget.current?.examList?.map((exam, i) => (
                                <>
                                  <div
                                    className="col-4 col-lg-2 right"
                                    style={
                                      clashes.current.includes(exam.date) ? { background: 'red' } : { background: '' }
                                    }
                                  >
                                    {exam.date}
                                  </div>
                                  <div className="col-6 col-lg-3 text-center">
                                    {config.current?.examConfigurations[exam.type]?.title}
                                  </div>
                                  <div className="col-2 col-lg-1 left">
                                    <button
                                      disabled={wipTarget.current.published}
                                      className="btn btn-outline-secondary btn-sm"
                                      onClick={() => {
                                        wipTarget.current.examList.splice(i, 1);
                                        checkClashes();
                                        setRefreshCount(refreshCount + 1);
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                </>
                              ))}
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <hr />
                              </div>
                              <div className="col-4">
                                <DatePicker
                                  disabled={wipTarget.current.published}
                                  className="py-2 my-0 pb-1"
                                  selected={new Date(newExam.current.date)}
                                  onChange={(date) => {
                                    newExam.current.date = getDateString(date?.getTime() || 0);
                                    setRefreshCount(refreshCount + 1);
                                  }}
                                />
                              </div>
                              <div className="col-6">
                                <Select
                                  isDisabled={wipTarget.current.published}
                                  options={Object.keys(config.current?.examConfigurations).map((id: string) => ({
                                    value: id,
                                    label: config.current?.examConfigurations[id]?.title,
                                  }))}
                                  value={{
                                    value: newExam.current.type,
                                    label: config.current?.examConfigurations[newExam.current.type]?.title,
                                  }}
                                  onChange={(e) => {
                                    newExam.current.type = e?.value || '';
                                    setRefreshCount(refreshCount + 1);
                                  }}
                                />
                              </div>
                              <div className="col-2">
                                <button
                                  className="btn btn-secondary"
                                  onClick={(e) => addNewExam()}
                                  disabled={wipTarget.current.published}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-12">
                    <hr />
                    <h3>
                      Holidays{' '}
                      <button className="btn btn-lg" onClick={(e) => setExpandHolidays(!expandHolidays)}>
                        {expandHolidays ? '-' : '+'}
                      </button>
                    </h3>
                    <hr />
                    <div className="comtainer" style={expandHolidays ? { display: 'block' } : { display: 'none' }}>
                      <div className="row">
                        <div className="col-12 right">
                          <button
                            className="btn btn-secondary btn-sm"
                            onClick={loadNationalHolidays}
                            disabled={wipTarget.current.published}
                          >
                            Load National Holidays
                          </button>
                        </div>
                        {wipTarget.current?.holidays?.map((holiday, i) => (
                          <>
                            <div
                              className="col-10 col-md-5 py-1"
                              style={
                                clashes.current.includes(holiday.date) ? { background: 'red' } : { background: '' }
                              }
                            >
                              {holiday.date} {holiday.name}
                            </div>
                            <div className="col-2 col-md-1 py-1 right">
                              <button
                                disabled={wipTarget.current.published}
                                className="btn btn-outline-secondary btn-sm"
                                onClick={() => {
                                  if (config.current?.hardHolidays.includes(holiday.date)) {
                                    alert(`Cannot remove ${holiday.date} as it is a compulsary holiday.`);
                                  } else {
                                    wipTarget.current.holidays.splice(i, 1);
                                    checkClashes();
                                    setRefreshCount(refreshCount + 1);
                                  }
                                }}
                              >
                                -
                              </button>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row p-2 my-3 border rounded">
                  <div className="col-12">
                    <h3>Courses</h3>
                    <hr />
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Subject</th>
                          <th>Course</th>
                          <th>Size</th>
                          <th>Content Quanta</th>
                          <th>Questions</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {wipTarget.current?.courseList.length ? (
                          wipTarget.current?.courseList?.map((course, i) => (
                            <tr>
                              <td>{allCourses.current[course]?.id.split('::')[0]}</td>
                              <td>{allCourses.current[course]?.name}</td>
                              <td>{allCourses.current[course]?.size}</td>
                              <td>{allCourses.current[course]?.contentQuanta}</td>
                              <td>{allCourses.current[course]?.questions}</td>
                              <td className="">
                                <button
                                  disabled={wipTarget.current.published}
                                  className="btn btn-secondary btn-sm"
                                  onClick={() => {
                                    wipTarget.current.courseList = wipTarget.current?.courseList.filter(
                                      (c: any) => c !== course,
                                    );
                                    setRefreshCount(refreshCount + 1);
                                  }}
                                >
                                  -
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7} className="text-center">
                              No course selected. Please select a course from the list below.
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td colSpan={7}>
                            <hr />
                          </td>
                        </tr>
                        {Object.values(allCourses.current)
                          .filter((course: any) => !wipTarget.current?.courseList?.includes(course.id))
                          .map((course: any) => (
                            <tr>
                              <td>{course.id.split('::')[0]}</td>
                              <td>{course.name}</td>
                              <td>{course.size}</td>
                              <td>{course.contentQuanta}</td>
                              <td>{course.questions}</td>
                              <td className="">
                                <button
                                  disabled={wipTarget.current.published}
                                  className="btn btn-secondary btn-sm"
                                  onClick={() => {
                                    wipTarget.current.courseList.push(course.id);
                                    setRefreshCount(refreshCount + 1);
                                  }}
                                >
                                  +
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-5">
                    <button
                      className="btn btn-secondary wide"
                      onClick={saveTarget}
                      disabled={wipTarget.current.published}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default TargetBuilder;
