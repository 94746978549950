/*
 * --------------------------------------------------------------------------- *
 * File: dashboard.tsx                                                         *
 * Project: faculty-dashboard                                                  *
 * Created Date: 18 Feb 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Sun Mar 12 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import React from 'react';
import { PublicConstants } from '../core/constants';
import { AppContext, GraphProvider } from '../core/context';
import Comprehensions from './comprehension/comprehensions';
import CourseBuilder from './course/coursebuilder';
import TargetBuilder from './course/target';
import LatexTaskDashboard from './latex-task/tasks';
import Questions from './question/questions';
import TemplateQuestion from './template-question/template-question';
import Admin from './user/admin';
import ChangePassword from './user/changepassword';
import ConfirmBatchHtml from './xlsx/confirmbatch';
import Graph from './xlsx/graph';
import Upload from './xlsx/upload';

function Dashboard() {
  const { state } = React.useContext(AppContext);
  if (state.showNow === PublicConstants.SHOW_NOW_ADMIN) return <Admin />;
  if (state.showNow === PublicConstants.SHOW_NOW_CHANGE_PASSWORD) return <ChangePassword />;
  if (state.showNow === PublicConstants.SHOW_NOW_COURSE) return <CourseBuilder />;
  if (state.showNow === PublicConstants.SHOW_NOW_UPLOAD) return <Upload />;
  if (state.showNow === PublicConstants.SHOW_NOW_QUESTION) return <Questions />;
  if (state.showNow === PublicConstants.SHOW_NOW_COMPREHENSION) return <Comprehensions />;
  if (state.showNow === PublicConstants.SHOW_NOW_TEMPLATE_QUESTION) return <TemplateQuestion />;
  if (state.showNow === PublicConstants.SHOW_NOW_GRAPH)
    return (
      <GraphProvider>
        <Graph batchId={state.batchId || ''} />
      </GraphProvider>
    );
  if (state.showNow === PublicConstants.SHOW_NOW_TARGET) return <TargetBuilder />;
  if (state.showNow === PublicConstants.SHOW_NOW_CONFIRM_CONTENT_HTML) return <ConfirmBatchHtml />;
  if (state.showNow === PublicConstants.SHOW_NOW_LATEX_TASKS_DASHBOARD) return <LatexTaskDashboard />;

  return <Welcome />;
}

function Welcome() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-2"></div>
        <div className="col-6 opacity-25 pt-5">
          <img src="/assets/logo.png" className="wide" alt="Welcome" />
        </div>
        {/* <SoundRecorder /> */}
      </div>
    </div>
  );
}

export default Dashboard;
