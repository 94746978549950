export function ProblemSaveEditStrip({
  readonly,
  editing,
  setEditing,
  save,
}: {
  readonly: boolean;
  editing: boolean;
  setEditing: (editing: boolean) => void;
  save: () => void;
}) {
  return (
    <div className="d-flex justify-content-between my-2">
      <h2>Problem</h2>
      <div className="d-flex justify-content-around">
        {editing && (
          <button className="btn btn-secondary mx-2" onClick={save}>
            <i className="bi bi-save2"></i> Save
          </button>
        )}
        {!readonly && (
          <button className="btn btn-secondary ml-2" onClick={() => setEditing(!editing)}>
            <i className="bi bi-pencil"></i> Edit
          </button>
        )}
      </div>
    </div>
  );
}
