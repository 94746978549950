/*
 * --------------------------------------------------------------------------- *
 * File: confirmbatch.tsx                                                      *
 * Project: faculty-dashboard                                                  *
 * Created Date: 09 Mar 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Tue Mar 14 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import React, { useContext, useEffect } from 'react';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import { AppContext } from '../../core/context';
import useKatex from '../../hooks/useKatex';

function ConfirmBatchHtml() {
  const { state } = useContext(AppContext);
  const [contentRecords, setContentRecords] = React.useState<ContentRecord[]>([]);

  useKatex();

  useEffect(() => {
    console.log('useEffect');
    const cids: string[] = [];
    const records: ContentRecord[] = [];
    Cloud.post(
      { action: PublicConstants.ACTION_GET_BATCH_CONTENTS, data: { batchId: state.batchId } },
      PublicConstants.API_CONTENT,
    )
      .then((response) => {
        if (response.success) {
          cids.push(...response.value.map((content: any) => content.id.split('::')[1]));
          console.log(cids);
          return Promise.all(
            // eslint-disable-next-line array-callback-return
            cids.map((contentId: string) => {
              console.log(contentId);
              return Cloud.getContentFile(state.batchId || '', 'en', contentId).then((response) => {
                console.log(response);
                const contentRecord = new ContentRecord();
                contentRecord.id = contentId;
                contentRecord.body = response.Content;
                contentRecord.title = response['Concept Title'];
                contentRecord.subTitle = response['Subtitle'];
                contentRecord.theoryType = response['Theory Type'];
                return contentRecord;
              });
            }),
          );
        } else {
          return [];
        }
      })
      .then((cr) => {
        records.push(...cr);
        return Promise.all(
          // eslint-disable-next-line array-callback-return
          cids.map((contentId: string) => {
            console.log(contentId);
            return Cloud.getContentFile(state.batchId || '', 'hi', contentId).then((response) => {
              console.log(response);
              const contentRecord = new ContentRecord();
              contentRecord.id = contentId;
              contentRecord.body = response.Content;
              contentRecord.title = response['Concept Title'];
              contentRecord.subTitle = response['Subtitle'];
              contentRecord.theoryType = response['Theory Type'];
              return contentRecord;
            });
          }),
        );
      })
      .then((cr) => {
        records.push(...cr);
        setContentRecords(records);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className="container">
      <div className="row">
        {contentRecords.map((cr) => (
          <div className="col-12">
            <div className="card pt-2 mt-4">
              <div className="card-body">
                <h5 className="card-title">Concept Title: {cr.title}</h5>
                <h6 className="card-subtitle mb-2 text-muted">Subtitle: {cr.subTitle}</h6>
                <h6 className="card-subtitle mb-2 text-muted">Theory Type: {cr.theoryType}</h6>
                <hr />
                <div className="card-text" dangerouslySetInnerHTML={{ __html: cr.body }}></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

class ContentRecord {
  id: string = '';
  body: string = '';
  title: string = '';
  subTitle: string = '';
  theoryType: string = '';
}

export default ConfirmBatchHtml;
