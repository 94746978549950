/*
 * --------------------------------------------------------------------------- *
 * File: context.ts                                                            *
 * Project: faculty-dashboard                                                  *
 * Created Date: 18 Feb 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Sun Feb 19 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import React, { useState } from 'react';
import { Edge, Node } from 'reactflow';
import { UserInfo } from './types';

export interface IProviderProps {
  children?: any;
}

type AppContextState = { showNow: string; userInfo?: UserInfo; batchId?: string };
type GraphContextState = { nodes: Node[]; edges: Edge[] };
type AppContextType = { state: AppContextState; setState: React.Dispatch<React.SetStateAction<AppContextState>> };
type GraphContextType = { state: GraphContextState; setState: React.Dispatch<React.SetStateAction<GraphContextState>> };

export const AppContext = React.createContext(null as unknown as AppContextType);
export const GraphContext = React.createContext(null as unknown as GraphContextType);

export const AppProvider = (props: IProviderProps) => {
  const [state, setState] = useState<AppContextState>({
    showNow: 'login',
  });

  return (
    <AppContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export const GraphProvider = (props: IProviderProps) => {
  const [state, setState] = useState<GraphContextState>({ nodes: initNodes, edges: initEdges });

  return (
    <GraphContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {props.children}
    </GraphContext.Provider>
  );
};

const initNodes: Node[] = [];
const initEdges: Edge[] = [];
