/*
 * --------------------------------------------------------------------------- *
 * File: login.tsx                                                             *
 * Project: faculty-dashboard                                                  *
 * Created Date: 18 Feb 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Tue Mar 07 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import React, { useEffect } from 'react';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import sha1 from 'sha1';
import { AppContext } from '../../core/context';

function Login() {
  const { state, setState } = React.useContext(AppContext);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  useEffect(() => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      setState({ ...state, userInfo: JSON.parse(userInfo) });
      Cloud.setAuthToken(JSON.parse(userInfo).token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginAttempt = async () => {
    if (email && password) {
      const response = await Cloud.post(
        { action: PublicConstants.ACTION_LOGIN, data: { email, password: sha1(password) } },
        PublicConstants.API_USER,
      );
      if (response.success) {
        setState({ ...state, userInfo: response.value, showNow: '' });
        localStorage.setItem('userInfo', JSON.stringify(response.value));
        Cloud.setAuthToken(response.value.token);
      }
    }
    return false;
  };

  return (
    <>
      <div className="modal" style={state.userInfo ? { display: 'none' } : { display: 'block', width: '100%' }}>
        <div className="modal-content modal-animate">
          <form className="d-flex" onSubmit={(e) => e.preventDefault()}>
            <div className="container p-5">
              <div className="row">
                <div className="col-lg-6">
                  <div className="text-center">
                    <img src="assets/logo.png" alt="Avatar" className="logo" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h1 className="text-center">Vyas Edification</h1>

                  <label>
                    <b>Username</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Username"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <label>
                    <b>Password</b>
                  </label>
                  <input
                    type="password"
                    placeholder="Enter Password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="d-grid gap-3">
                    <hr />
                    <button className="btn btn-primary btn-block" onClick={() => loginAttempt()}>
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
