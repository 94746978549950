import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import { AppContext } from '../../core/context';
import makeAnimated from 'react-select/animated';
import { Concept, IComprehension } from '../../../../lambda/question/question-schema';
import { ErrorMessage } from '../common/error-message';
import useConcepts from '../../hooks/useConcepts';
import { getQuestionsInfo } from '../../utils/questions';
import invariant from 'tiny-invariant';
import { handleComprehensionFormSubmission } from '../../utils/comprehension';
import { conceptsCanopy, validateConceptsIndependent } from '../../utils/utils';

export const animatedComponents = makeAnimated();

export function ConceptTagsForm({
  comprehension,
  refreshComprehension,
  idx,
}: {
  comprehension: IComprehension;
  refreshComprehension: () => void;
  idx: number;
}) {
  const { concepts, reachableNodes, weights, conceptsFetchError, dropDownOptions: options } = useConcepts();
  const [selectedOptions, setSelectedOptions] = React.useState<{ value: string; label: string }[]>(() => {
    let concepts: { value: string; label: string }[] = [];
    let questionsInfo = comprehension.questionsInfo;
    if (questionsInfo.length > idx) {
      let questionInfo = questionsInfo[idx];
      if (questionInfo.equationsDescription) {
        concepts = concepts.concat(
          questionInfo.equationsDescription
            .map((ed) => ed.concepts.map((c) => ({ value: c.id, label: c.label })))
            .flat(),
        );
      }
      if (questionInfo.concepts.length > 0) {
        concepts = concepts.concat(questionInfo.concepts.map((c: Concept) => ({ value: c.id, label: c.label })));
      }
    }

    return conceptsCanopy(concepts, reachableNodes);
  });
  const [edit, setEdit] = React.useState(false);

  const [formError, setFormError] = React.useState('');
  const { state } = React.useContext(AppContext);

  const equationConcepts = (): { value: string; label: string }[] => {
    let questionsInfo = comprehension.questionsInfo;
    if (questionsInfo.length > idx) {
      let questionInfo = questionsInfo[idx];
      if (questionInfo.equationsDescription) {
        return questionInfo.equationsDescription
          .map((ed) => ed.concepts.map((c) => ({ value: c.id, label: c.label })))
          .flat();
      }
    }
    return [];
  };

  // Whenever selected options change, verify that their is no error,
  // i.e. no child parent concepts are together.
  useEffect(() => {
    setFormError('');
    const error = validateConceptsIndependent(selectedOptions, reachableNodes);
    if (error) {
      setFormError(error);
      return;
    }

    const equationConceptsCanopy = conceptsCanopy(equationConcepts(), reachableNodes);
    for (let i = 0; i < equationConceptsCanopy.length; i++) {
      // In the selectedOptions atleast one should be an ancestor of the equationConceptsCanopy[i].
      let found = false;
      for (let j = 0; j < selectedOptions.length; j++) {
        if (reachableNodes && reachableNodes[equationConceptsCanopy[i].value]?.includes(selectedOptions[j].value)) {
          found = true;
          break;
        }
      }
      if (!found) {
        setFormError(
          `Concept ${equationConceptsCanopy[i].label} is not included in any of the selected concepts. Please select it or one of its ancestors.`,
        );
        return;
      }
    }
  }, [selectedOptions, concepts, reachableNodes]);

  const handleChange = (options: any) => {
    // Check the validity and if not show the error and disable the submit button.
    setSelectedOptions(options);
  };

  let editAllowed = false;

  let areConceptsAlreadyTagged: boolean = false;
  let conceptsAlreadyTagged: string[] = [];
  let conceptsAuthor: string | undefined = undefined;

  let questionsInfo = comprehension.questionsInfo;
  if (questionsInfo.length > idx) {
    let questionInfo = questionsInfo[idx];
    if (questionInfo.concepts.length > 0) {
      areConceptsAlreadyTagged = true;
      conceptsAlreadyTagged = questionInfo.concepts.map((c: Concept) => c.label);
      conceptsAuthor = questionInfo.conceptsAuthor;
    }

    if (questionInfo.numEquations !== undefined) {
      editAllowed = true;
    }
  }

  return (
    <div className="row border p-4 border-secondary">
      <ErrorMessage error={conceptsFetchError} />
      <ErrorMessage error={formError} />
      <div className="container">
        <div className="row">
          <div className="col-4">
            <h5>Concept Tags</h5>
          </div>
          <div className="col-7">
            {!editAllowed && <h6 className="text-warning">First submit number of equations information</h6>}
            {editAllowed &&
              (areConceptsAlreadyTagged ? (
                <h6 className="text-success">
                  Concepts: {conceptsAlreadyTagged.join(', ')} marked by {conceptsAuthor}
                </h6>
              ) : (
                <h6 className="text-warning">Unknown</h6>
              ))}
          </div>
          <div className="col-1">
            <button type="button" disabled={!editAllowed} className="btn btn-primary" onClick={() => setEdit(!edit)}>
              Edit
            </button>
          </div>
        </div>
      </div>

      {edit && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setFormError('');
            console.log(selectedOptions);
            if (selectedOptions.length === 0) {
              setFormError('Please select at least one option');
              return;
            } else {
              // An api call to set concepts and author.
              const questionsInfo = getQuestionsInfo(comprehension);
              invariant(questionsInfo.length > idx, 'Invalid index');
              questionsInfo[idx] = {
                ...questionsInfo[idx],
                concepts: selectedOptions.map((so) => ({
                  id: so.value,
                  label: so.label,
                  weight: [
                    {
                      weight: weights[so.value],
                      algorithm: '1 + sum-over-children',
                    },
                  ],
                })),
                conceptsAuthor: state.userInfo?.info.email,
              };

              Cloud.post(
                {
                  action: PublicConstants.ACTION_UPDATE_COMPREHENSION,
                  // @ts-ignore
                  data: {
                    id: comprehension._id,
                    update: PublicConstants.UPDATE_CONCEPTS,
                    // @ts-ignore
                    questionsInfo,
                  },
                },
                PublicConstants.API_QUESTION,
              ).then((response) => {
                handleComprehensionFormSubmission(response, refreshComprehension, setFormError, setEdit);
              });
            }
          }}
        >
          <label htmlFor="ConceptTags" className="form-label">
            Concept Tags
          </label>
          <Select
            isMulti
            name="colors"
            value={selectedOptions}
            options={options}
            components={animatedComponents}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleChange}
            required={true}
            id="ConceptTags"
          />
          <button type="submit" className="col-12 mt-3 btn btn-primary">
            Submit Concept Tags
          </button>
        </form>
      )}
    </div>
  );
}
