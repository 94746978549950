/*
 * --------------------------------------------------------------------------- *
 * File: Editor.tsx                                                            *
 * Project: faculty-dashboard                                                  *
 * Created Date: 02 Mar 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Thu Mar 02 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { $generateHtmlFromNodes } from '@lexical/html';
import {
  $getRoot,
  $getSelection,
  $insertNodes,
  DecoratorNode,
  EditorState,
  ElementNode,
  LexicalEditor,
  LexicalNode,
  RootNode,
} from 'lexical';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import * as React from 'react';
import { useState } from 'react';

import { useSharedHistoryContext } from './context/SharedHistoryContext';
import TableCellNodes from './nodes/TableCellNodes';
import AutoEmbedPlugin from './plugins/AutoEmbedPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import ClickableLinkPlugin from './plugins/ClickableLinkPlugin';
import CodeActionMenuPlugin from './plugins/CodeActionMenuPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import ComponentPickerPlugin from './plugins/ComponentPickerPlugin';
import DragDropPaste from './plugins/DragDropPastePlugin';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import EquationsPlugin from './plugins/EquationsPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
import ImagesPlugin from './plugins/ImagesPlugin';
import KeywordsPlugin from './plugins/KeywordsPlugin';
import LinkPlugin from './plugins/LinkPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import SpeechToTextPlugin from './plugins/SpeechToTextPlugin';
import TabFocusPlugin from './plugins/TabFocusPlugin';
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
import TableCellResizer from './plugins/TableCellResizer';
import { TablePlugin as NewTablePlugin } from './plugins/TablePlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import TreeViewPlugin from './plugins/TreeViewPlugin';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import ContentEditable from './ui/ContentEditable';
import Placeholder from './ui/Placeholder';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateNodesFromDOM } from '@lexical/html';
import { createHeadlessEditor } from '@lexical/headless';
import PlaygroundNodes from './nodes/PlaygroundNodes';
import { initial } from 'lodash-es';
import { $insertGeneratedNodes } from '@lexical/clipboard';

function extractEquations(html: string) {
  const parser = new DOMParser();
  const dom = parser.parseFromString(html, 'text/html');
  const spanElements = dom.querySelectorAll('span.math');
  const mathMap = new Map<string, string>();
  Array.from(spanElements).map((span) => {
    const randomNumber = Math.floor(Math.random() * 9000000000 + 1000000000);
    mathMap.set(randomNumber.toString(), span.outerHTML);
    span.outerHTML = `<span class=${randomNumber}>${randomNumber}</span>`;
  });

  return { htmlWithoutEq: dom.body.innerHTML, eqMap: mathMap };
}

function stitchEquations(html: string, eqMap: Map<string, string>) {
  const parser = new DOMParser();
  const dom = parser.parseFromString(html, 'text/html');
  // Get all elemenst which has className from mathMap
  // Iterate through mathmap
  // Replace the element with the value from mathMap
  eqMap.forEach((value, key) => {
    const spanElements = dom.querySelectorAll(`span[class="${key}"]`);
    Array.from(spanElements).map((span) => {
      span.outerHTML = value;
      // @ts-ignore
      span['data-type'] = 'equation';
    });
  });

  return dom.body.innerHTML;
}

function fixEquations(html: string) {
  const parser = new DOMParser();
  const dom = parser.parseFromString(html, 'text/html');
  const spanElements = dom.querySelectorAll('span.math');
  Array.from(spanElements).map((span) => {
    span.setAttribute('data-type', 'equation');
    span.setAttribute('data-inline', 'true');
    span.setAttribute('data-equation', span.innerHTML);
  });

  return dom.body.innerHTML;
}

export function ConvertToLexicalHTML(html: string) {
  const config = {
    editorState: undefined,
    namespace: 'Playground',
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  const editor = createHeadlessEditor({ ...config });

  editor.update(() => {
    // const { htmlWithoutEq, eqMap } = extractEquations(html);
    const htmlWithEq = fixEquations(html);
    const parser = new DOMParser();
    const dom = parser.parseFromString(htmlWithEq, 'text/html');

    // Once you have the DOM instance it's easy to generate LexicalNodes.
    const nodes = $generateNodesFromDOM(editor, dom);

    // Select the root
    $getRoot().select();

    $insertNodes(nodes);
    const htmlString = $generateHtmlFromNodes(editor, null);

    // Stich back the images
    // const htmlStringWithEq = stitchEquations(htmlString, eqMap);
    console.log('headless', html, '===>', htmlWithEq, '===>', htmlString);
  });
}

function $isDecoratorNode(node: LexicalNode) {
  return node instanceof DecoratorNode;
}

function $isElementNode(node: LexicalNode) {
  return node instanceof ElementNode;
}

function $isRootOrShadowRoot(node: LexicalNode) {
  return $isRootNode(node) || ($isElementNode(node) && node.isShadowRoot());
}

function $isRootNode(node: LexicalNode) {
  return node instanceof RootNode;
}

export default function Editor({
  updateHTML,
  initialHTML,
}: {
  updateHTML: React.Dispatch<React.SetStateAction<string>>;
  initialHTML: string;
}): JSX.Element {
  const { historyState } = useSharedHistoryContext();
  const [editor] = useLexicalComposerContext();
  const isRichText = true;
  const showTreeView = false;
  const text = isRichText
    ? 'Add Question Answer and Solution. Template parameters should end in ...Var_{number} , e.g. Var_1, Var_2, Var_3, etc. and functions should be of form ...Fn(Var_1, Var_2) etc'
    : 'Enter some plain text...';
  const placeholder = <Placeholder>{text}</Placeholder>;
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (initialHTML) {
      editor.update(() => {
        // In the browser you can use the native DOMParser API to parse the HTML string.
        const parser = new DOMParser();
        const htmlWithEq = fixEquations(initialHTML.trim());
        const dom = parser.parseFromString(htmlWithEq, 'text/html');

        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom);
        $insertGeneratedNodes(editor, nodes, $getRoot().select());
        const htmlString = $generateHtmlFromNodes(editor, null);
        console.log({ htmlString });
      });
    }
  }, []);

  const onChange = (editorState: EditorState, editor: LexicalEditor) => {
    editorState.read(() => {});

    editor.update(() => {
      const htmlString = $generateHtmlFromNodes(editor, null);
      updateHTML(htmlString);
    });
  };

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const cellEditorConfig = {
    namespace: 'Playground',
    nodes: [...TableCellNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  return (
    <>
      {isRichText && <ToolbarPlugin />}
      <div className={`editor-container ${showTreeView ? 'tree-view' : ''} ${!isRichText ? 'plain-text' : ''}`}>
        <DragDropPaste />
        <AutoFocusPlugin />
        <ClearEditorPlugin />
        <ComponentPickerPlugin />
        <AutoEmbedPlugin />
        <KeywordsPlugin />
        <SpeechToTextPlugin />
        <AutoLinkPlugin />
        {
          <>
            <HistoryPlugin externalHistoryState={historyState} />
            <RichTextPlugin
              contentEditable={
                <div className="editor-scroller">
                  <div className="editor" ref={onRef}>
                    <ContentEditable />
                  </div>
                </div>
              }
              placeholder={placeholder}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <CodeHighlightPlugin />
            <ListPlugin />
            <CheckListPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <TablePlugin />
            <TableCellResizer />
            <NewTablePlugin cellEditorConfig={cellEditorConfig}>
              <AutoFocusPlugin />
              <RichTextPlugin
                contentEditable={<ContentEditable className="TableNode__contentEditable" />}
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <HistoryPlugin />
              <ImagesPlugin captionsEnabled={false} />
              <LinkPlugin />
              <ClickableLinkPlugin />
              <FloatingTextFormatToolbarPlugin />
            </NewTablePlugin>
            <ImagesPlugin />
            <LinkPlugin />
            <ClickableLinkPlugin />
            <EquationsPlugin />
            <TabFocusPlugin />
            <TabIndentationPlugin />
            <OnChangePlugin onChange={onChange} />
            {floatingAnchorElem && (
              <>
                <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
                <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
                <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
                <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
              </>
            )}
          </>
        }
        {/* <ActionsPlugin isRichText={isRichText} /> */}
      </div>
      {showTreeView && <TreeViewPlugin />}
    </>
  );
}
