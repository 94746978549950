/*
 * --------------------------------------------------------------------------- *
 * File: admin.tsx                                                             *
 * Project: faculty-dashboard                                                  *
 * Created Date: 18 Feb 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Tue Mar 07 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import { nanoid } from 'nanoid';
import { useState, useEffect } from 'react';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import { UserInfo } from '../../core/types';
import sha1 from 'sha1';
import Select from 'react-select';

import { InputActionMeta } from 'react-select';

function Admin() {
  const [refreshCount, setRefreshCount] = useState(0);
  const [userList, setUserList] = useState<UserInfo[]>([]);
  const [newUserName, setNewUserName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPermissions, setNewUserPermissions] = useState({ admin: false, upload: false, course: false });
  const [masterSubjectList, setMasterSubjectList] = useState<string[]>([]);
  const [newUserSubject, setNewUserSubject] = useState<string>('');

  useEffect(() => {
    Cloud.post({ action: PublicConstants.ACTION_USER_LIST, data: {} }, PublicConstants.API_USER).then((response) => {
      if (response.success) {
        setUserList(response.value);
      }
    });
    Cloud.getConfig().then((c) => setMasterSubjectList(c.subjects));
    setNewUserName('');
    setNewUserEmail('');
    setNewUserPermissions({ admin: false, upload: false, course: false });
    setNewUserSubject('');
  }, [refreshCount]);

  const updatePermission = (index: number, permission: string, value: boolean) => {
    const ul = [...userList];
    // @ts-ignore
    ul[index].permissions[permission] = value;
    setUserList(ul);
    Cloud.post(
      {
        action: PublicConstants.ACTION_SET_PERMISSION,
        data: { id: ul[index].info.email, permissions: ul[index].permissions },
      },
      PublicConstants.API_USER,
    ).then((response) => {
      if (!response.success) {
        setRefreshCount(refreshCount + 1);
      }
    });
  };

  const updateSubject = (index: number, subject: string) => {
    const ul: UserInfo[] = [...userList];
    ul[index] = { ...ul[index], info: { ...ul[index].info, subject: subject } };
    setUserList(ul);

    Cloud.post(
      {
        action: PublicConstants.ACTION_SET_USER_SUBJECT,
        data: { id: ul[index].info.email, subject },
      },
      PublicConstants.API_USER,
    ).then((response) => {
      if (!response.success) {
        setRefreshCount(refreshCount + 1);
      }
    });
  };

  const addNewUser = async () => {
    const p = nanoid(10);
    const response = await Cloud.post(
      {
        action: PublicConstants.ACTION_ADD_USER,
        data: {
          info: { name: newUserName },
          id: newUserEmail,
          permissions: newUserPermissions,
          password: sha1(p),
        },
      },
      PublicConstants.API_USER,
    );
    if (response.success) {
      setRefreshCount(refreshCount + 1);
      alert(`User added. Password is ${p}`);
    }
  };

  const resetPassword = async (email: string) => {
    const response = await Cloud.post(
      {
        action: PublicConstants.ACTION_RESET_PASSWORD,
        data: { id: email },
      },
      PublicConstants.API_USER,
    );
    if (response.success) {
      alert(`Password reset. New password is ${response.value.newPassword}`);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 pt-4">
          <h1>Admin</h1>
          <hr />
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Subject</th>
                <th>Admin</th>
                <th>Upload</th>
                <th>Course</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {userList.map((user, i) => (
                <tr key={i}>
                  <td>{user.info.name}</td>
                  <td>{user.info.email}</td>
                  <td>
                    <Select
                      value={{ label: user.info.subject, value: user.info.subject }}
                      options={masterSubjectList.map((s) => ({ label: s, value: s }))}
                      onChange={(e) => {
                        // @ts-ignore
                        updateSubject(i, e.value);
                      }}
                    ></Select>
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={user.permissions.admin}
                      onChange={(e) => updatePermission(i, 'admin', e.target.checked)}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={user.permissions.upload}
                      onChange={(e) => updatePermission(i, 'upload', e.target.checked)}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={user.permissions.course}
                      onChange={(e) => updatePermission(i, 'course', e.target.checked)}
                    />
                  </td>
                  <td>
                    <button className="btn btn-warning btn-sm" onClick={() => resetPassword(user.info.email)}>
                      Password
                    </button>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={6}></td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    placeholder="Name"
                    className="p-2"
                    value={newUserName}
                    onChange={(e) => setNewUserName(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="Email"
                    className="p-2"
                    value={newUserEmail}
                    onChange={(e) => setNewUserEmail(e.target.value)}
                  />
                </td>
                <td>
                  <Select
                    className="my-2"
                    value={{ label: newUserSubject, value: newUserSubject }}
                    options={masterSubjectList.map((s) => ({ label: s, value: s }))}
                    onChange={(e) => {
                      // @ts-ignore
                      setNewUserSubject(e?.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="my-3"
                    checked={newUserPermissions.admin}
                    onChange={(e) => {
                      setNewUserPermissions({ ...newUserPermissions, admin: e.target.checked });
                    }}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="my-3"
                    checked={newUserPermissions.upload}
                    onChange={(e) => {
                      setNewUserPermissions({ ...newUserPermissions, upload: e.target.checked });
                    }}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="my-3"
                    checked={newUserPermissions.course}
                    onChange={(e) => {
                      setNewUserPermissions({ ...newUserPermissions, course: e.target.checked });
                    }}
                  />
                </td>
                <td>
                  {newUserEmail && newUserName && (
                    <button className="btn btn-primary btn-sm my-3" onClick={addNewUser}>
                      Add User
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Admin;
