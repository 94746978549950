import { useEffect, useState } from 'react';
import { htmlToString, languageCodeToString, parseAnswerHTML } from '../../../utils/utils';
import { ErrorMessage } from '../../common/error-message';
import type { MCQTranslatedQuestion } from '../schema';
import TaggedStmtV2 from './tagged-stmt-v2';
import { WarnMessage } from '../../common/warn-message';
import { ProblemSaveEditStrip } from './problem-save-edit-strip';
import invariant from 'tiny-invariant';

export default function MCQQuestion({
  q,
  readonly,
  updateQuestion,
}: {
  q: MCQTranslatedQuestion;
  readonly: boolean;
  updateQuestion: (question: MCQTranslatedQuestion, updateInfo: any) => void;
}) {
  const [question, setQuestion] = useState<MCQTranslatedQuestion>(q);
  const [editing, setEditing] = useState(false);
  const [unCommmitedChanges, setUnCommittedChanges] = useState(false);
  useEffect(() => {
    setQuestion(q);
  }, [q]);

  const setUnCommited = () => {
    if (!unCommmitedChanges) {
      setUnCommittedChanges(true);
    }
  };

  const ans = question.obj_ans.join(', ');

  const saveQuestion = () => {
    updateQuestion(question, { type: 'save' });
    setUnCommittedChanges(false);
  };
  return (
    <>
      <ErrorMessage error={question.err_conversion} />
      {unCommmitedChanges && <WarnMessage warning={'Uncommited Changes'} />}
      <ProblemSaveEditStrip
        readonly={readonly}
        editing={editing}
        setEditing={setEditing}
        save={() => {
          saveQuestion();
          setUnCommittedChanges(false);
        }}
      />
      <h3>English Problem</h3>
      <TaggedStmtV2
        tag="Problem"
        htmlContent={question.stmt}
        readonly={editing}
        onUpdate={(s: string) => {
          question.stmt = s;
          setQuestion(question);
          setUnCommited();
        }}
      />
      {question.options &&
        question.options.map((option, index) => (
          <TaggedStmtV2
            key={index}
            tag={`Option ${index + 1}`}
            htmlContent={option}
            readonly={editing}
            onUpdate={(s: string) => {
              invariant(question.options, 'question.options is null');
              question.options[index] = s;
              setQuestion(question);
              setUnCommited();
            }}
          />
        ))}
      {question.options && (
        <TaggedStmtV2
          tag="Answer"
          htmlContent={question.obj_ans.toString()}
          readonly={editing}
          onUpdate={(s: string) => {
            const resp = parseAnswerHTML(s || '', 'mcq');
            if (resp.error) {
              alert(resp.error);
              return;
            }
            invariant(resp.mcqAnswer, 'mcqAnswer is null');
            question.obj_ans = resp.mcqAnswer;
            setQuestion(question);
            setUnCommited();
          }}
        />
      )}
      {!question.options && (
        <div className="mx-4">
          <b>English Options are absent</b>
        </div>
      )}
      <TaggedStmtV2
        tag="Solution"
        htmlContent={question.sol}
        readonly={editing}
        onUpdate={(s: string) => {
          question.sol = s;
          setQuestion(question);
          setUnCommited();
        }}
      />
      {question.translations.map((translation, index) => (
        <div key={index}>
          <h3>{`${languageCodeToString(translation.lang)} Problem`}</h3>
          <TaggedStmtV2
            tag="Problem"
            htmlContent={translation.stmt}
            readonly={editing}
            onUpdate={(s: string) => {
              question.translations[index].stmt = s;
              setQuestion(question);
              setUnCommited();
            }}
          />
          {translation.options &&
            translation.options.map((option, optionIndex) => (
              <TaggedStmtV2
                key={optionIndex}
                tag={`Option ${optionIndex + 1}`}
                htmlContent={option}
                readonly={editing}
                onUpdate={(s: string) => {
                  // @ts-ignore
                  question.translations[index].options[optionIndex] = s;
                  setQuestion(question);
                  setUnCommited();
                }}
              />
            ))}
          {translation.options && (
            <TaggedStmtV2
              tag="Answer"
              htmlContent={translation.obj_ans.toString()}
              readonly={editing}
              onUpdate={(s: string) => {
                const resp = parseAnswerHTML(s || '', 'mcq');
                if (resp.error) {
                  alert(resp.error);
                  return;
                }
                invariant(resp.mcqAnswer, 'resp.scqAnswer is null');
                question.translations[index].obj_ans = resp.mcqAnswer;
                setQuestion(question);
                setUnCommited();
              }}
            />
          )}
          {!translation.options && (
            <div className="m-4">
              <b>{translation.lang} Options are absent</b>
            </div>
          )}
          <TaggedStmtV2
            tag="Solution"
            htmlContent={translation.sol}
            readonly={editing}
            onUpdate={(s: string) => {
              question.translations[index].sol = s;
              setQuestion(question);
              setUnCommited();
            }}
          />
        </div>
      ))}
    </>
  );
}
