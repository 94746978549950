import { useEffect } from 'react';
import { useKatexStore } from '../store';

// TODO(Utkarsh): This runs on every re-render, what can be done here?
// TODO(Utkarsh): Need to handle block mode.
export default function useKatex() {
  const refetchCount = useKatexStore((state) => state.refetchCount);

  useEffect(() => {
    let mathElements = document.getElementsByClassName('math');
    const macros = {};
    for (let i = 0; i < mathElements.length; i++) {
      const mathElement = mathElements[i];
      const firstChild = mathElement.firstChild;
      if (firstChild === null || firstChild === undefined) {
        continue;
      }
      const texText = firstChild;
      // @ts-ignore
      if (texText === null || texText === undefined || !texText.data) {
        continue;
      }
      // @ts-ignore
      if (typeof texText.data === 'string') {
        if (mathElement.tagName === 'SPAN') {
          // @ts-ignore
          katex.render(texText.data, mathElement, {
            displayMode: mathElement.classList.contains('display'),
            throwOnError: false,
            macros: macros,
            fleqn: false,
          });
        }
      }
    }
  }, [refetchCount]);

  useEffect(() => {
    // From lexical editor
    let mathElements2 = document.querySelectorAll("[data-type='equation']");
    const macros = {};
    for (let i = 0; i < mathElements2.length; i++) {
      const mathElement = mathElements2[i];
      const firstChild = mathElement.firstChild;
      if (firstChild === null || firstChild === undefined) {
        continue;
      }
      const texText = firstChild;
      // @ts-ignore
      if (texText === null || texText === undefined || !texText.data) {
        continue;
      }
      // @ts-ignore
      if (typeof texText.data === 'string') {
        if (mathElement.tagName === 'SPAN') {
          // @ts-ignore
          katex.render(texText.data, mathElement, {
            displayMode: mathElement.classList.contains('display'),
            throwOnError: false,
            macros: macros,
            fleqn: false,
          });
        }
      }
    }
  }, [refetchCount]);

  useEffect(() => {
    let mathElements = document.getElementsByClassName('math');
    const macros = {};
    for (let i = 0; i < mathElements.length; i++) {
      const mathElement = mathElements[i];
      const firstChild = mathElement.firstChild;
      if (firstChild === null || firstChild === undefined) {
        continue;
      }
      const texText = firstChild;
      // @ts-ignore
      if (texText === null || texText === undefined || !texText.data) {
        continue;
      }
      // @ts-ignore
      if (typeof texText.data === 'string') {
        if (mathElement.tagName === 'SPAN') {
          // @ts-ignore
          katex.render(texText.data, mathElement, {
            displayMode: mathElement.classList.contains('display'),
            throwOnError: false,
            macros: macros,
            fleqn: false,
          });
        }
      }
    }
  });

  useEffect(() => {
    // From lexical editor
    let mathElements2 = document.querySelectorAll("[data-type='equation']");
    const macros = {};
    for (let i = 0; i < mathElements2.length; i++) {
      const mathElement = mathElements2[i];
      const firstChild = mathElement.firstChild;
      if (firstChild === null || firstChild === undefined) {
        continue;
      }
      const texText = firstChild;
      // @ts-ignore
      if (texText === null || texText === undefined || !texText.data) {
        continue;
      }
      // @ts-ignore
      if (typeof texText.data === 'string') {
        if (mathElement.tagName === 'SPAN') {
          // @ts-ignore
          katex.render(texText.data, mathElement, {
            displayMode: mathElement.classList.contains('display'),
            throwOnError: false,
            macros: macros,
            fleqn: false,
          });
        }
      }
    }
  });
}
