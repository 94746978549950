// Declare a class, which has a static init method, which does an async fetch and after fetch succeeds it sets the state of the store. The store is then used in the component. The component is wrapped in a Suspense component, which shows a spinner until the store is initialized.

import { ConceptGraph } from '../../lib/globaltypes';
import { Cloud } from './core/cloud';
import { PublicConstants } from './core/constants';

export class InitStore {
  static initialized = false;
  static initializing = false;
  static concepts: ConceptGraph;
  static fetchError: string | null;
  // reachableNodes tracks for given node i the nodes that are ancestor of i.
  static reachableNodes: { [k: string]: string[] };
  static weights: { [k: string]: number };
  static dropDownOptions: { value: string; label: string }[];

  static init = async () => {
    if (this.initialized) {
      return;
    }

    if (this.initializing) {
      while (this.initializing) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      return;
    }

    InitStore.initializing = true;
    const response = await Cloud.post(
      { action: PublicConstants.ACTION_GET_CONCEPT_GRAPH, data: {} },
      PublicConstants.API_QUESTION,
    );
    console.log('concept graphs get', response);
    if (!response.success) {
      this.fetchError = 'Error fetching concepts';
      this.initializing = false;
      return;
    }

    if (response.value.graphs) {
      let concepts: ConceptGraph = response.value.graphs;
      let reachableNodes: { [k: string]: string[] } = {};
      let weights: { [k: string]: number } = {};

      // Calculate the reachable nodes and weights
      {
        let visited: { [k: string]: boolean } = {};

        // get nodes which has no previous nodes.
        let startNodes = Object.entries(concepts)
          .filter(([k, v]) => v.prev.length === 0)
          .map((v) => v[0]);

        const onlyUnique = (value: string, index: number, array: string[]) => array.indexOf(value) === index;

        const dfs = (node: string) => {
          if (visited[node]) return;
          visited[node] = true;
          reachableNodes[node] = [node];
          let stack = concepts[node].next;
          for (let i = 0; i < stack.length; i++) {
            let nextNode = stack[i];
            if (visited[nextNode]) {
              // TODO(Utkarsh): Uniqueness.
              reachableNodes[node] = reachableNodes[node].concat(reachableNodes[nextNode]).filter(onlyUnique);
            } else {
              dfs(nextNode);
              reachableNodes[node] = reachableNodes[node].concat(reachableNodes[nextNode]).filter(onlyUnique);
            }
          }
        };

        startNodes.forEach((c) => {
          dfs(c);
        });
      }

      // Calculate the weights
      {
        let visited: { [k: string]: boolean } = {};

        Object.keys(concepts).forEach((k) => {
          weights[k] = 0;
        });

        // get nodes which has no next nodes.
        let topNodes = Object.entries(concepts)
          .filter(([k, v]) => v.next.length === 0)
          .map((v) => v[0]);

        const dfs = (node: string) => {
          if (visited[node]) return;
          visited[node] = true;
          let stack = concepts[node].prev;
          weights[node] = 1;
          for (let i = 0; i < stack.length; i++) {
            let childNode = stack[i];
            if (!visited[childNode]) {
              dfs(childNode);
            }
            weights[node] += weights[childNode];
          }
        };

        topNodes.forEach((c) => {
          dfs(c);
        });
      }

      let dropDownOptions: { value: string; label: string }[] = Object.keys(concepts).map((c) => {
        return { value: c, label: concepts[c].label };
      });

      this.concepts = response.value.graphs;
      this.reachableNodes = reachableNodes;
      this.weights = weights;
      this.dropDownOptions = dropDownOptions;
    }

    this.initialized = true;
    this.initializing = false;
  };
}
