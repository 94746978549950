import { useState } from 'react';
import invariant from 'tiny-invariant';
import useKatex from '../../hooks/useKatex';
import { languageCodeToString } from '../../utils/utils';
import { ErrorMessage } from '../common/error-message';
import { WarnMessage } from '../common/warn-message';
import { ProblemSaveEditStrip } from '../question/question/problem-save-edit-strip';
import RenderQuestion from '../question/question/render-question';
import TaggedStmt from '../question/question/tagged-stmt';
import TaggedStmtV2 from '../question/question/tagged-stmt-v2';
import { TranslatedComprehension, TranslatedQuestion } from '../question/schema';

export default function RenderComprehension({
  c,
  passageOnly,
  readonly,
  updateComprehension,
}: {
  c: TranslatedComprehension;
  passageOnly: boolean;
  readonly: boolean;
  updateComprehension: (comprehension: TranslatedComprehension) => void;
}) {
  useKatex();
  const [comprehension, setComprehension] = useState<TranslatedComprehension>(c);
  const [editing, setEditing] = useState(false);
  const [unCommmitedChanges, setUnCommittedChanges] = useState(false);

  const setUnCommited = () => {
    if (!unCommmitedChanges) {
      setUnCommittedChanges(true);
    }
  };

  const saveComprehension = () => {
    updateComprehension(comprehension);
    setUnCommittedChanges(false);
  };

  invariant(readonly || passageOnly, 'Questions can not be edited from RenderComprehension');
  return (
    <>
      <ErrorMessage error={comprehension.err_conversion} />
      <WarnMessage warning={unCommmitedChanges ? 'Uncommited Changes' : ''} />
      <ProblemSaveEditStrip editing={editing} setEditing={setEditing} readonly={readonly} save={saveComprehension} />
      <TaggedStmtV2
        tag="English Passage"
        htmlContent={comprehension.passage}
        readonly={editing}
        onUpdate={(s: string) => {
          comprehension.passage = s;
          setUnCommited();
          setComprehension({ ...comprehension });
        }}
      />
      {comprehension.translations.map((translation, index) => (
        <div key={index}>
          <TaggedStmtV2
            tag={`${languageCodeToString(translation.lang)} Passage`}
            htmlContent={translation.passage}
            readonly={editing}
            onUpdate={(s: string) => {
              comprehension.translations[index].passage = s;
              setUnCommited();
              setComprehension({ ...comprehension });
            }}
          />
        </div>
      ))}
      {!passageOnly &&
        comprehension.questions?.map((question, index) => (
          <div key={index}>
            <h3>{`Question ${index + 1}`}</h3>
            <RenderQuestion
              question={question}
              readonly={true}
              updateQuestion={(q: TranslatedQuestion) => {
                invariant(false, 'Questions can not be edited from RenderComprehension');
              }}
            />
          </div>
        ))}
    </>
  );
}
