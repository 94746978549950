/*
 * --------------------------------------------------------------------------- *
 * File: navbar.tsx                                                            *
 * Project: faculty-dashboard                                                  *
 * Created Date: 18 Feb 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Sat Mar 04 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import React from 'react';
import { AppContext } from '../core/context';
import { PublicConstants } from '../core/constants';
import { Cloud } from '../core/cloud';

function Navbar() {
  const { state, setState } = React.useContext(AppContext);

  const logout = () => {
    localStorage.removeItem('userInfo');
    Cloud.setAuthToken('');
    setState({ ...state, userInfo: null as unknown as any, showNow: '' });
  };

  const changePassword = () => {
    setState({ ...state, showNow: PublicConstants.SHOW_NOW_CHANGE_PASSWORD });
  };

  if (!state.userInfo) return <div></div>;

  return (
    <nav className="navbar navbar-expand-lg bg-secondary text-white">
      <div className="container-fluid">
        <h2
          className="click"
          onClick={() => {
            setState({ ...state, showNow: PublicConstants.SHOW_NOW_HOME });
          }}
        >
          Vyas
        </h2>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-5">
            {state && state.userInfo && state.userInfo.permissions.admin && (
              <li className="nav-item">
                <button
                  className="btn mx-1 btn-secondary"
                  onClick={() => setState({ ...state, showNow: PublicConstants.SHOW_NOW_ADMIN })}
                >
                  Admin
                </button>
              </li>
            )}
            {state && state.userInfo && state.userInfo.permissions.upload && (
              <li className="nav-item">
                <button
                  className="btn mx-1 btn-secondary"
                  onClick={() => setState({ ...state, showNow: PublicConstants.SHOW_NOW_UPLOAD })}
                >
                  Upload
                </button>
              </li>
            )}
            {state && state.userInfo && state.userInfo.permissions.course && (
              <li className="nav-item">
                <button
                  className="btn mx-1 btn-secondary"
                  onClick={() => setState({ ...state, showNow: PublicConstants.SHOW_NOW_COURSE })}
                >
                  Course
                </button>
              </li>
            )}
            {/* TODO(Utkarsh): Add permissions for questions improvement */}
            {state && state.userInfo && (
              <li className="nav-item">
                <button
                  className="btn mx-1 btn-secondary"
                  onClick={() => setState({ ...state, showNow: PublicConstants.SHOW_NOW_QUESTION })}
                >
                  Questions
                </button>
              </li>
            )}
            {/* TODO(Utkarsh): Add permissions for questions improvement */}
            {state && state.userInfo && (
              <li className="nav-item">
                <button
                  className="btn mx-1 btn-secondary"
                  onClick={() => setState({ ...state, showNow: PublicConstants.SHOW_NOW_COMPREHENSION })}
                >
                  Comprehensions
                </button>
              </li>
            )}
            {state && state.userInfo && (
              <li className="nav-item">
                <button
                  className="btn mx-1 btn-secondary"
                  onClick={() => setState({ ...state, showNow: PublicConstants.SHOW_NOW_TEMPLATE_QUESTION })}
                >
                  Template Questions
                </button>
              </li>
            )}
            {state && state.userInfo && state.userInfo.permissions.course && (
              <li className="nav-item">
                <button
                  className="btn mx-1 btn-secondary"
                  onClick={() => setState({ ...state, showNow: PublicConstants.SHOW_NOW_TARGET })}
                >
                  Target
                </button>
              </li>
            )}
            {state && state.userInfo && (
              <li className="nav-item">
                <button
                  className="btn mx-1 btn-secondary"
                  onClick={() => setState({ ...state, showNow: PublicConstants.SHOW_NOW_LATEX_TASKS_DASHBOARD })}
                >
                  Latex Tasks
                </button>
              </li>
            )}
          </ul>
          {/* <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
          <button className="btn btn-outline-success" type="submit">
            Search
          </button>
          <button className="btn">Profile</button> */}
          <button className="btn btn-light btn-sm mx-1" onClick={changePassword}>
            Change Password
          </button>
          <button className="btn btn-light btn-sm mx-1" onClick={logout}>
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
