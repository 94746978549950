import invariant from 'tiny-invariant';
import z from 'zod';
import {
  ARTranslatedQuestion,
  FIBTranslatedQuestion,
  IntegerTranslatedQuestion,
  MCQTranslatedQuestion,
  SCQTranslatedQuestion,
  SubjectiveTranslatedQuestion,
  TFTranslatedQuestion,
  TranslatedQuestion,
} from '../components/question/schema';
import TemplatesConfig from '../components/template-question/template-classes';

export const languageCodeToString = (code: string): string => {
  switch (code) {
    case 'hi':
      return 'Hindi';
    case 'te':
      return 'Telugu';
    case 'en':
      return 'English';
  }

  invariant(false, 'Invalid language code');
};

export function numberToUpperCaseAlphabet(num: number) {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  let result = '';
  while (num > 0) {
    let remainder = (num - 1) % 26;
    result = alphabet[remainder] + result;
    num = Math.floor((num - 1) / 26);
  }
  return result;
}

export function htmlToString(s: string) {
  // Parse html through DOM
  const parser = new DOMParser();
  const doc = parser.parseFromString(s, 'text/html');
  return doc.body.textContent;
}

export function conceptsCanopy(
  concepts: {
    value: string;
    label: string;
  }[],
  reachableNodes: { [k: string]: string[] },
) {
  if (!reachableNodes) {
    return concepts;
  }

  // Make a canopy out of it.
  // First remove duplicates
  const conceptsMap = new Map<string, { value: string; label: string }>();
  concepts.forEach((c) => conceptsMap.set(c.value, c));
  const conceptsArray = Array.from(conceptsMap.values());

  console.log({ conceptsArray });

  // Next remove descendants of any available ancestor.
  while (true) {
    let removed = false;
    for (let i = 0; i < conceptsArray.length; i++) {
      for (let j = i + 1; j < conceptsArray.length; j++) {
        const concept1Id = conceptsArray[i].value;
        const concept2Id = conceptsArray[j].value;
        if (reachableNodes[concept1Id]?.includes(concept2Id)) {
          conceptsArray.splice(j, 1);
          removed = true;
          break;
        }
        if (reachableNodes[concept2Id]?.includes(concept1Id)) {
          conceptsArray.splice(i, 1);
          removed = true;
          break;
        }
      }
      if (removed) {
        break;
      }
    }
    if (!removed) {
      break;
    }
  }

  return conceptsArray;
}

export function validateConceptsIndependent(
  concepts: {
    value: string;
    label: string;
  }[],
  reachableNodes: { [k: string]: string[] },
) {
  // Check if concepts are independent.
  if (!reachableNodes) {
    return null;
  }

  for (let i = 0; i < concepts.length; i++) {
    for (let j = i + 1; j < concepts.length; j++) {
      const concept1Id = concepts[i].value;
      const concept2Id = concepts[j].value;
      if (reachableNodes[concept1Id]?.includes(concept2Id)) {
        return `Concept ${concepts[j].label} is ancestor of concept ${concepts[i].label}`;
      }
      if (reachableNodes[concept2Id]?.includes(concept1Id)) {
        return `Concept ${concepts[i].label} is ancestor of concept ${concepts[j].label}`;
      }
    }
  }

  return null;
}

export type ChunkType =
  | 'Question'
  | 'Answer'
  | 'Solution'
  | 'Unknown'
  | 'Option 1'
  | 'Option 2'
  | 'Option 3'
  | 'Option 4'
  | 'Option 5'
  | 'Option 6'
  | 'Assertion'
  | 'Reason';

export type QuestionType =
  | 'scq'
  | 'mcq'
  | 'fillInTheBlank'
  | 'subjective'
  | 'integerType'
  | 'assertionReason'
  | 'fillInTheBlanks'
  | 'matrixMatch'
  | 'trueFalse';

export const chunkTypes = [
  'Question',
  'Answer',
  'Solution',
  'Option 1',
  'Option 2',
  'Option 3',
  'Option 4',
  'Option 5',
  'Option 6',
  'Assertion',
  'Reason',
];

export function chunkify(html: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const elements = doc.body.childNodes;

  let chunks = new Map<ChunkType, string>();
  let currentChunk = '';
  let currentType: ChunkType = 'Unknown';

  const parameters: { name: string; class: string }[] = [];
  const formulae: string[] = [];

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];

    if (element.nodeType === Node.TEXT_NODE) {
      // Ignore text nodes that are just whitespace
      invariant(element.textContent !== null, 'text node should have text content');
      if (element.textContent.trim() === '') {
        continue;
      }
    }

    if (element.nodeType === Node.ELEMENT_NODE) {
      //@ts-ignore
      const tagName = element.tagName.toLowerCase();

      if (tagName === 'p') {
        invariant(element.textContent !== null, 'p tag should have text content');
        const text = element.textContent.trim();

        for (let i = 0; i < chunkTypes.length; i++) {
          if (text.startsWith(chunkTypes[i])) {
            element.textContent = element.textContent.replace(chunkTypes[i], '');
            // If we have a chunk in progress, end it and push it to the chunks array.
            if (currentChunk !== '') {
              chunks.set(currentType, currentChunk);
              currentChunk = '';
            }

            currentType = chunkTypes[i] as ChunkType;
          }
        }
      }
    }

    // @ts-ignore
    currentChunk += element.outerHTML;

    // @ts-ignore
    element.outerHTML.match(/\b\w*Var_\d+/g)?.forEach((match: string) => {
      if (parameters.findIndex((p) => p.name === match) === -1) {
        const prefixIndex = match.indexOf('Var');
        let classType = match.substring(0, prefixIndex) ? match.substring(0, prefixIndex) : 'WildString';
        parameters.push({ name: match, class: classType });
      }
    });

    // @ts-ignore
    element.outerHTML.match(/\b\w+Fn\(\s*\w+Var_\d+\s*(?:,\s*\w+Var_\d+\s*)*\s*\)/g)?.forEach((match: string) => {
      console.log(match, formulae);
      if (formulae.findIndex((f) => f === match) === -1) {
        console.log(match);
        formulae.push(match);
      }
    });
  }

  // If we have a chunk in progress, end it and push it to the chunks array.
  if (currentChunk !== '') {
    chunks.set(currentType, currentChunk);
  }

  return { chunks, parameters, formulae };
}

export const evaluateFunctions = (
  formulae: string[],
  parameters: { name: string; class: string }[],
  paramValues: string[],
) => {
  let results: string[] = [];
  formulae.forEach((formula) => {
    let fnName = formula.substring(0, formula.indexOf('('));
    let fnParams = formula.substring(formula.indexOf('(') + 1, formula.indexOf(')'));
    let fnParamsArray = fnParams.split(',');
    let fnParamsValues = fnParamsArray.map((param) => {
      let paramIndex = parameters.findIndex((p) => p.name === param.trim());
      // Convert to number
      let intVal = parseInt(paramValues[paramIndex]);
      return intVal;
    });

    // Find the function in TemplateConfig
    const functionConfig = TemplatesConfig.functions.find((f) => f.id === fnName);
    invariant(functionConfig !== undefined, `Function not found in TemplateConfig ${fnName}`);
    const value = functionConfig.evaluate(fnParamsValues);

    results.push(value);
  });
  return results;
};

const verifyParametersAndFormulae = (parameters: { name: string; class: string }[], formulae: string[]): string => {
  for (let i = 0; i < parameters.length; i++) {
    const parameter = parameters[i];
    // find the class in TemplateConfig.classes
    const classObj = TemplatesConfig.classes.find((c) => c.id === parameter.class);
    console.log(parameter.class, classObj, !classObj);
    if (!classObj) {
      return `Template Variable of class ${parameter.class} not found`;
    }
  }

  for (let i = 0; i < formulae.length; i++) {
    const formula = formulae[i];
    let fnName = formula.substring(0, formula.indexOf('('));
    let fnParams = formula.substring(formula.indexOf('(') + 1, formula.indexOf(')'));
    let fnParamsArray = fnParams.split(',');
    // Validate that the parameters are numerical type and the number of parameters is correct
    const fnObj = TemplatesConfig.functions.find((f) => f.id === fnName);
    if (!fnObj) {
      return `Template Function ${fnName} not found`;
    }

    if (!fnObj.validNumParams(fnParamsArray.length)) {
      return `Template Function ${fnName} has invalid number of parameters`;
    }

    for (let j = 0; j < fnParamsArray.length; j++) {
      const param = fnParamsArray[j];
      const paramName = param.trim();

      const paramObj = parameters.find((p) => p.name === paramName);
      if (!paramObj) {
        return `Template Variable ${paramName} in function ${fnName} not found`;
      }

      const classObj = TemplatesConfig.classes.find((c) => c.id === paramObj.class);
      if (!classObj) {
        return `Template Variable of class ${paramObj.class} not found`;
      }

      if (!classObj.isNumber) {
        return `Template Variable ${param} in function ${fnName} is not a number`;
      }
    }
  }

  return '';
};

export const castChunksToQuestion = (
  chunks: Map<ChunkType, string>,
  questionType: QuestionType,
): TranslatedQuestion | Error => {
  // Verify that the answer types match the question type.
  const resp = parseAnswerHTML(chunks.get('Answer') || '', questionType);
  if (resp.error) return new Error(resp.error);

  const commonAssigns = {
    type: questionType,
    common: {
      source: 'template-system',
      revise: false,
      pyq: 0,
      pyq_year: 0,
      pyq_details: '',
    },
    err_conversion: '',
    translations: [],
    sol: chunks.get('Solution') || '',
  };

  if (questionType === 'scq') {
    return {
      ...commonAssigns,
      stmt: chunks.get('Question') || '',
      options: [
        chunks.get('Option 1') || '',
        chunks.get('Option 2') || '',
        chunks.get('Option 3') || '',
        ...([chunks.get('Option 4'), chunks.get('Option 5'), chunks.get('Option 6')].filter(
          (o) => o !== undefined,
        ) as string[]),
      ],
      obj_ans: resp.scqAnswer,
    } as SCQTranslatedQuestion;
  } else if (questionType === 'mcq') {
    return {
      ...commonAssigns,
      stmt: chunks.get('Question') || '',
      options: [
        chunks.get('Option 1') || '',
        chunks.get('Option 2') || '',
        chunks.get('Option 3') || '',
        ...([chunks.get('Option 4'), chunks.get('Option 5'), chunks.get('Option 6')].filter(
          (o) => o !== undefined,
        ) as string[]),
      ],
      obj_ans: resp.mcqAnswer || [],
    } as MCQTranslatedQuestion;
  } else if (questionType === 'fillInTheBlank') {
    return {
      ...commonAssigns,
      stmt: chunks.get('Question') || '',
      ans: resp.fbAnswer,
    } as FIBTranslatedQuestion;
  } else if (questionType === 'subjective') {
    return {
      ...commonAssigns,
      stmt: chunks.get('Question') || '',
      ans: resp.subjectiveAnswer,
    } as SubjectiveTranslatedQuestion;
  } else if (questionType === 'integerType') {
    return {
      ...commonAssigns,
      stmt: chunks.get('Question') || '',
      int_ans: resp.intAnswer,
      ans_absent: false,
    } as IntegerTranslatedQuestion;
  } else if (questionType === 'assertionReason') {
    return {
      ...commonAssigns,
      assr: chunks.get('Assertion') || '',
      rsn: chunks.get('Reason') || '',
      ar_ans: resp.arAnswer,
    } as ARTranslatedQuestion;
  } else if (questionType === 'matrixMatch') {
    invariant(false, 'Not yet implemented');
  } else if (questionType === 'trueFalse') {
    return {
      ...commonAssigns,
      stmt: chunks.get('Question') || '',
      tf_ans: resp.tfAnswer,
      ans_absent: false,
    } as TFTranslatedQuestion;
  } else {
    throw new Error('Invalid question type');
  }
};

const checkAllChunks = (
  chunks: Map<ChunkType, string>,
  parameters: { name: string; class: string }[],
  questionType: QuestionType,
): string => {
  if (chunks.get('Question') === undefined) return 'Question is missing';
  if (chunks.get('Answer') === undefined) return 'Answer is missing';
  if (chunks.get('Solution') === undefined) return 'Solution is missing';
  if (chunks.get('Unknown') !== undefined) return 'Unknown chunk is present';

  if (htmlToString(chunks.get('Question')?.trim() || '') === '') return 'Question is empty';
  if (htmlToString(chunks.get('Answer')?.trim() || '') === '') return 'Answer is empty';
  if (htmlToString(chunks.get('Solution')?.trim() || '') === '') return 'Solution is empty';

  if (questionType === 'scq' || questionType === 'mcq') {
    // Atleast 3 options must be present.
    if (chunks.get('Option 1') === undefined) return 'Option 1 is missing for mcq, scq question type';
    if (chunks.get('Option 2') === undefined) return 'Option 2 is missing for mcq, scq question type';
    if (chunks.get('Option 3') === undefined) return 'Option 3 is missing for mcq, scq question type';

    if (chunks.get('Option 4') === undefined && chunks.get('Option 5') !== undefined)
      return 'Option 4 is missing, but Option 5 is present for mcq, scq question';
    if (chunks.get('Option 5') === undefined && chunks.get('Option 6') !== undefined)
      return 'Option 5 is missing, but Option 6 is present for mcq, scq question';
  }

  if (questionType === 'assertionReason') {
    if (chunks.get('Assertion') === undefined) return 'Assertion is missing';
    if (chunks.get('Reason') === undefined) return 'Reason is missing';

    if (htmlToString(chunks.get('Assertion')?.trim() || '') === '') return 'Assertion is empty';
    if (htmlToString(chunks.get('Reason')?.trim() || '') === '') return 'Reason is empty';
  }

  // Verify that the answer types match the question type.
  const resp = parseAnswerHTML(chunks.get('Answer') || '', questionType);
  if (resp.error) return resp.error;

  // Get the answer string and see if it matches the question type.
  if (parameters.length === 0) {
    return 'No parameters found';
  }

  return '';
};

export const checkTemplateQuestion = (
  chunks: Map<ChunkType, string>,
  parameters: { name: string; class: string }[],
  formulae: string[],
  questionType: QuestionType,
): string => {
  const paramsError = verifyParametersAndFormulae(parameters, formulae);
  if (paramsError) {
    return paramsError;
  }

  const chunksError = checkAllChunks(chunks, parameters, questionType);
  if (chunksError) {
    return chunksError;
  }
  return '';
};

const parsedAnswerSchema = z.object({
  answer: z.string(),
  type: z.enum([
    'scq',
    'mcq',
    'fillInTheBlank',
    'subjective',
    'integerType',
    'assertionReason',
    'fillInTheBlanks',
    'matrixMatch',
    'trueFalse',
  ]),
  scqAnswer: z.number().min(1).max(6).nullable(),
  mcqAnswer: z.array(z.number().min(1).max(6)).nullable(),
  arAnswer: z.number().min(1).max(5).nullable(),
  fbAnswer: z.string().nullable(),
  subjectiveAnswer: z.string().nullable(),
  tfAnswer: z.union([z.literal(true), z.literal(false)]).nullable(),
  intAnswer: z.number().nullable(),
  mmAnswer: z.array(z.array(z.number().min(1).max(10))).nullable(),
  error: z.string().nullable(),
});

export type ParsedAnswer = z.infer<typeof parsedAnswerSchema>;

export function parseAnswerHTML(html: string, questionType: QuestionType): ParsedAnswer {
  const result: ParsedAnswer = {
    answer: htmlToString(html) || '',
    type: questionType,
    scqAnswer: null,
    mcqAnswer: null,
    arAnswer: null,
    fbAnswer: null,
    subjectiveAnswer: null,
    tfAnswer: null,
    intAnswer: null,
    mmAnswer: null,
    error: null,
  };

  const ans = htmlToString(html);
  if (!ans) {
    result.error = 'Answer is empty';
    return result;
  }

  if (questionType === 'scq') {
    const scqAnswer = parseInt(ans);
    if (isNaN(scqAnswer)) {
      result.error = 'Answer is not a number';
      return result;
    }

    if (scqAnswer < 1 || scqAnswer > 6) {
      result.error = 'Answer is not a valid SCQ answer, valid SCQ answers are 1, 2, 3, 4, 5, 6';
      return result;
    }

    result.scqAnswer = scqAnswer;
  } else if (questionType === 'mcq') {
    const mcqAnswer = ans.split(',').map((a) => parseInt(a));
    if (mcqAnswer.some((a) => isNaN(a))) {
      result.error = 'Answer is not a comma separated list of numbers';
      return result;
    }

    if (mcqAnswer.some((a) => a < 1 || a > 6)) {
      result.error = 'Answer is not a valid MCQ answer, valid MCQ answers are 1, 2, 3, 4, 5, 6';
    }

    result.mcqAnswer = mcqAnswer;
  } else if (questionType === 'assertionReason') {
    const arAnswer = parseInt(ans);
    if (isNaN(arAnswer)) {
      result.error = 'Answer is not a number';
    }

    if (arAnswer < 1 || arAnswer > 5) {
      result.error = 'Answer is not a valid Assertion Reason answer, valid Assertion Reason answers are 1, 2, 3, 4, 5';
    }

    result.arAnswer = arAnswer;
  } else if (questionType === 'fillInTheBlank') {
    result.fbAnswer = ans;
  } else if (questionType === 'subjective') {
    result.subjectiveAnswer = ans;
  } else if (questionType === 'trueFalse') {
    if (ans === 'true') {
      result.tfAnswer = true;
    } else if (ans === 'false') {
      result.tfAnswer = false;
    } else {
      result.error = 'Answer is not a valid True False answer, valid True False answers are true or false';
    }
  } else if (questionType === 'integerType') {
    const intAnswer = parseInt(ans);
    if (isNaN(intAnswer)) {
      result.error = 'Answer is not a number';
    }

    result.intAnswer = intAnswer;
  } else if (questionType === 'matrixMatch') {
    invariant(false, 'Not yet implemented');
  }

  return result;
}
