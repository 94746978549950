/*
 * --------------------------------------------------------------------------- *
 * File: App.tsx                                                               *
 * Project: faculty-dashboard                                                  *
 * Created Date: 18 Feb 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Sat Feb 18 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import './App.css';
import Navbar from './components/navbar';
import { AppProvider } from './core/context';
import Login from './components/user/login';
import Dashboard from './components/dashboard';

function App() {
  return (
    <AppProvider>
      <Navbar />
      <Login />
      <Dashboard />
    </AppProvider>
  );
}

export default App;
