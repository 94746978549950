/*
 * --------------------------------------------------------------------------- *
 * File: constants.tsx                                                         *
 * Project: faculty-dashboard                                                  *
 * Created Date: 18 Feb 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Mon Mar 20 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

export class PublicConstants {
  static BASE_DOMAIN: string = 'app.vyasedification.com';
  static CONTENT_SUB_DOMAIN: string = '5c08f948-4fa3-4708-b439-6cb85096301d';

  static API_USER = 'facultyuser';
  static ACTION_LOGIN = 'login';
  static ACTION_USER_LIST = 'userlist';
  static ACTION_SET_PERMISSION = 'setpermission';
  static ACTION_RESET_PASSWORD = 'resetpassword';
  static ACTION_CHANGE_PASSWORD = 'changepassword';
  static ACTION_ADD_USER = 'adduser';
  static ACTION_SET_USER_SUBJECT = 'setsubject';

  static API_CONTENT = 'content';
  static ACTION_SIGNED_URL = 'signedurl';
  static ACTION_SIGNED_URL_TRANSLATION = 'signedurltranslation';
  static ACTION_GET_BATCH_LIST = 'getbatchlist';
  static ACTION_PROCESS_XLSX = 'processxlsx';
  static ACTION_GET_CHAPTERS = 'getchapters';
  static ACTION_GET_SUBJECTS = 'getsubjects';
  static ACTION_ADD_CHAPTER = 'addchapter';
  static ACTION_GET_BATCH_GRAPH = 'getbatchgraph';
  static ACTION_SAVE_GRAPH = 'savegraph';
  static ACTION_GET_XLSX_DOWNLOAD_URL = 'getxlsxdownloadurl';
  static ACTION_GET_SUBJECT_QUANTAS = 'getsubjectquantas';
  static ACTION_GET_CONTENT_QUANTUM = 'getcontentquantum';
  static ACTION_DELETE_BATCH = 'deletebatch';
  static ACTION_CLEAR_GRAPH = 'cleargraph';
  static ACTION_GET_BATCH_CONTENTS = 'getbatchcontents';

  static API_COURSE = 'course';
  static ACTION_LIST_COURSES = 'getcourses';
  static ACTION_GET_COURSE = 'getcourse';
  static ACTION_SAVE_COURSE = 'savecourse';

  static ACTION_GET_SECTION = 'getsection';
  static ACTION_GET_LESSON = 'getlesson';

  static ACTION_GET_TARGET_LIST = 'gettargetlist';
  static ACTION_GET_TARGET = 'gettarget';
  static ACTION_SAVE_TARGET = 'savetarget';
  static ACTION_PUBLISH_TARGET = 'publishtarget';
  static ACTION_GET_ALL_COURSES = 'getallcourses';

  static ACTION_GET_IMAGE_SIGNED_URL = 'getimagesignedurl';
  static ACTION_GET_AUDIO_SIGNED_URL = 'getaudiosignedurl';

  static SHOW_NOW_ADMIN = 'admin';
  static SHOW_NOW_UPLOAD = 'upload';
  static SHOW_NOW_COURSE = 'course';
  static SHOW_NOW_GRAPH = 'graph';
  static SHOW_NOW_QUESTION = 'question';
  static SHOW_NOW_COMPREHENSION = 'comprehension';
  static SHOW_NOW_TARGET = 'target';
  static SHOW_NOW_TEMPLATE_QUESTION = 'templatequestion';
  static SHOW_NOW_CHANGE_PASSWORD = 'changepassword';
  static SHOW_NOW_CONFIRM_CONTENT_HTML = 'confirmcontenthtml';
  static SHOW_NOW_LATEX_TASKS_DASHBOARD = 'latexTasksDashboard';

  static CONTEXT_COURSE = 'Course';
  static CONTEXT_SECTION = 'Section';
  static CONTEXT_LESSON = 'Lesson';
  static CONTEXT_CONTENT_QUANTUM = 'ContentQuantum';
  static CONTEXT_CHAPTER = 'Chapter';
  static CONTEXT_SUBJECT = 'Subject';
  static CONTEXT_BATCH = 'Batch';
  static CONTEXT_UPLOADS = 'Uploads';
  static CONTEXT_CONCEPT = 'Concept';
  static CONTEXT_QUESTION_CONTENT_QUANTUM_MAPPING = 'QuestionContentQuantumMapping';
  static CONTEXT_CONTENT_QUANTUM_QUESTION_MAPPING = 'ContentQuantumQuestionMapping';

  static CONTEXT_TARGET = 'Target';
  static SHOW_NOW_HOME = 'string';

  static EXAM_TYPES = ['Type 1', 'Type 2', 'Type 3'];
  static EXAM_MODES = ['Mode 1', 'Mode 2', 'Mode 3'];

  static API_QUESTION = 'question';
  static ACTION_GET_QUESTIONS = 'getquestions';
  static ACTION_GET_QUESTION = 'getquestion';
  static ACTION_UPDATE_QUESTION = 'updatequestion';
  static ACTION_GET_CONCEPT_GRAPH = 'getconceptgraph';
  static ACTION_FINALIZE_QUESTION = 'finalizequestion';
  static ACTION_GET_CONTENT_QUANTUMS = 'getcontentquantums';
  static ACTION_UPDATE_QUESTION_CONTENT_QUANTUM_LINKS = 'updatequestioncontentquantumlinks';
  static ACTION_GET_COMPREHENSIONS = 'getcomprehensions';
  static ACTION_GET_COMPREHENSION = 'getcomprehension';
  static ACTION_UPDATE_COMPREHENSION = 'updatecomprehension';
  static ACTION_FINALIZE_COMPREHENSION = 'finalizecomprehension';
  static ACTION_UPDATE_COMPREHENSION_CONTENT_QUANTUM_LINKS = 'updatecomprehensioncontentquantumlinks';
  static UPDATE_CORRECTNESS = 'updatecorrectness';
  static UPDATE_CONCEPTS = 'updateconcepts';
  static UPDATE_DIFFICULTY = 'updatedifficulty';
  static UPDATE_SUBJECT_CHAPTER = 'updatesubjectchapter';
  static UPDATE_NUM_EQUATIONS = 'updatenumequations';
  static UPDATE_SUBJECTIVE_DIFFICULTY = 'updatesubjectivedifficulty';
  static UPDATE_ANSWER_TEMPLATE = 'updateanswertemplate';
  static UPDATE_IMAGE_CONSISTENCY = 'updateimageconsistency';
  static UPDATE_CONTENT = 'updatecontent';
  static ACTION_ADD_TEMPLATE_QUESTION = 'addtemplatequestion';
  static ACTION_GET_LATEX_TASKS = 'getlatextasks';
  static ACTION_CONVERT_LATEX_TASKS = 'convertlatextasks';

  static API_STUDENT = 'student';
  static ACTION_STUDENT_LOGIN = 'studentlogin';
  static ACTION_JWT_VERIFY = 'jwtverify';
  static ACTION_SAVE_PROFILE = 'saveprofile';
  static ACTION_GET_STUDENT_TARGETS = 'getstudenttargets';
  static ACTION_GET_CONTENT_QUANTUM_NODES = 'getcontentquantumnodes';
  static ACTION_GET_CONTENT_QUANTUM_VIDEOS = 'getcontentquantumvideos';

  static API_EXAM = 'exam';
  static ACTION_GENERATE_EXAM = '';

  static LatexTaskStatus = {
    CREATED: 'CREATED',
    ASSIGNED: 'ASSIGNED',
    SUBMITTED: 'SUBMITTED',
    VERIFIED: 'VERIFIED',
  };
}
