import React, { useEffect } from 'react';
import { Cloud } from '../core/cloud';

export default function useSubjectChapters() {
  const [subjectList, setSubjectList] = React.useState<{ value: string; label: string }[]>([]);
  const [chapterList, setChapterList] = React.useState<{ value: string; label: string }[]>([]);

  // Fetch the subjects and chapters from the server.
  useEffect(() => {
    Cloud.getConfig().then((c) => {
      setSubjectList([
        { value: 'default', label: 'Select an option' },
        ...c.subjects.map((s: string) => ({ value: s, label: s })),
      ]);
      let chapters: { value: string; label: string }[] = [{ value: 'default', label: 'Select an option' }];
      Object.keys(c.subjectChapters).forEach((s: string) => {
        for (const chap of c.subjectChapters[s]) {
          chapters.push({ value: `${s}::${chap}`, label: `${s}::${chap}` });
        }
      });
      setChapterList(chapters);
    });
  }, []);

  return { subjectList, chapterList };
}
