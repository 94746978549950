/*
 * --------------------------------------------------------------------------- *
 * File: changepassword.tsx                                                    *
 * Project: faculty-dashboard                                                  *
 * Created Date: 18 Feb 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Sat Mar 04 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import React, { useEffect } from 'react';
import sha1 from 'sha1';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';

function ChangePassword() {
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [error, setError] = React.useState('');

  useEffect(() => {
    if (newPassword.length < 8) setError('Password must be at least 8 characters long');
    else if (newPassword.search(/[a-z]/) === -1) setError('Password must contain at least one lowercase character');
    else if (newPassword.search(/[A-Z]/) === -1) setError('Password must contain at least one uppercase character');
    else if (newPassword.search(/[0-9]/) === -1) setError('Password must contain at least one digit');
    else if (newPassword.search(/[!@#$%^&*]/) === -1) setError('Password must contain at least one special character');
    else if (newPassword !== confirmPassword) setError('Passwords do not match');
    else setError('');
  }, [newPassword, confirmPassword]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 pt-4">
          <h1>Change Password</h1>
          <hr />
        </div>
        <div className="col-12 col-sm-8 col-md-6 col-lg-4">
          <label>
            <b>New Password</b>
          </label>
          <input
            type="password"
            placeholder="New Password"
            required
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <label>
            <b>Confirm Password</b>
          </label>
          <input
            type="password"
            placeholder="Confirm Password"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div className="text-danger text-sm">{error}</div>
          <div className="d-grid gap-3">
            <hr />
            <button
              className="btn btn-primary btn-block"
              disabled={error !== ''}
              onClick={async () => {
                if (!error) {
                  const response = await Cloud.post(
                    {
                      action: PublicConstants.ACTION_CHANGE_PASSWORD,
                      data: { password: sha1(newPassword) },
                    },
                    PublicConstants.API_USER,
                  );
                  if (response.success) {
                    alert('Password changed successfully');
                  }
                }
              }}
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
