import React, { useEffect } from 'react';
import { IQuestion } from '../../../../lambda/question/question-schema';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import { AppContext } from '../../core/context';
import { handleQuestionFormSubmission } from '../../utils/questions';
import { ErrorMessage } from '../common/error-message';

export default function AnswerTemplateForm({
  question,
  refreshQuestion,
}: {
  question: IQuestion;
  refreshQuestion: () => void;
}) {
  const [formRrror, setFormError] = React.useState('');
  const { state } = React.useContext(AppContext);
  const [edit, setEdit] = React.useState(false);
  const [answerTemplatesList, setAnswerTemplatesList] = React.useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    Cloud.getConfig().then((c) => {
      setAnswerTemplatesList(c.answerTemplates.map((s: string) => ({ value: s, label: s })));
    });
  }, []);

  const userEmail = state.userInfo?.info.email;
  const answerTemplate = question?.answerTemplate;
  const answerTemplateAuthor = question?.answerTemplateAuthor;

  return (
    <div className="row border p-4 border-secondary">
      <ErrorMessage error={formRrror} />
      <div className="container">
        <div className="row">
          <div className="col-4">
            <h5>Answer Template</h5>
          </div>
          <div className="col-7">
            {!answerTemplate ? (
              <h6 className="text-warning">Answer Template not selected</h6>
            ) : (
              <h6 className="text-success">
                Answer template selected: {answerTemplate} by {answerTemplateAuthor}
              </h6>
            )}
          </div>
          <div className="col-1">
            <button type="button" className="btn btn-primary" onClick={() => setEdit(!edit)}>
              Edit
            </button>
          </div>
        </div>
        {edit && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setFormError('');
              const formData = Object.fromEntries(new FormData(e.target as HTMLFormElement));
              const answerTemplate = formData['answer-template'] as string;
              if (answerTemplate === 'default') {
                setFormError('Please select a valid option');
                return;
              }

              Cloud.post(
                {
                  action: PublicConstants.ACTION_UPDATE_QUESTION,
                  // @ts-ignore
                  data: {
                    id: question._id,
                    update: PublicConstants.UPDATE_ANSWER_TEMPLATE,
                    answerTemplate,
                    author: userEmail,
                  },
                },
                PublicConstants.API_QUESTION,
              ).then((response) => {
                handleQuestionFormSubmission(response, refreshQuestion, setFormError, setEdit);
              });
            }}
          >
            <div className="row">
              <div className="col-12">
                <label htmlFor="AnswerTemplate" className="form-label">
                  Answer Template
                </label>
                <select defaultValue={'default'} id="AnswerTemplate" className="form-select" name="answer-template">
                  {answerTemplatesList.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <button type="submit" className="col-12 mt-3 btn btn-primary">
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
