import parse from 'html-react-parser';
import { useState } from 'react';
import { useKatexStore } from '../../../store';
import QuestionEditor from '../../editor/QuestionEditor';

export default function TaggedStmtV2({
  tag,
  htmlContent,
  readonly,
  onUpdate,
}: {
  tag: string;
  htmlContent: string;
  readonly: boolean;
  onUpdate: (htmlContent: string) => void;
}) {
  const [editing, setEditing] = useState(false);
  const [html, setHTML] = useState(htmlContent);
  const reRenderKatex = useKatexStore((state) => state.reRenderKatex);

  return (
    <div className="mx-4 my-2">
      <hr className="mt-1 mb-1" />
      {htmlContent && (
        <>
          <div className="d-flex justify-content-between">
            <h5 className="mt-2">{tag} </h5>
            {readonly && (
              <div className="d-flex justify-content-around">
                <button
                  className="btn btn-secondary mx-2"
                  onClick={() => {
                    reRenderKatex();
                    onUpdate(html);
                    setEditing(false);
                  }}
                >
                  <i className="bi bi-save2"></i>
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    reRenderKatex();
                    setEditing(!editing);
                  }}
                >
                  <i className="bi bi-pencil"></i>
                </button>
              </div>
            )}
          </div>
          {!editing && parse(htmlContent)}
          {editing && <QuestionEditor initialHTML={htmlContent} updateHTML={setHTML} />}
        </>
      )}
      {!htmlContent && (
        <div className="d-flex justify-content-between">
          <h5 className="mt-2">{`${tag} is absent`} </h5>
          {readonly && (
            <button className="btn btn-secondary">
              <i className="bi bi-pencil"></i>
            </button>
          )}
        </div>
      )}
    </div>
  );
}
