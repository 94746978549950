/*
 * --------------------------------------------------------------------------- *
 * File: graph.tsx                                                             *
 * Project: faculty-dashboard                                                  *
 * Created Date: 18 Feb 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Thu Mar 09 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import { useCallback, useContext, useEffect, useState } from 'react';
import ReactFlow, {
  Node,
  addEdge,
  Connection,
  Edge,
  MarkerType,
  applyEdgeChanges,
  applyNodeChanges,
  EdgeChange,
  NodeChange,
} from 'reactflow';

import 'reactflow/dist/style.css';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import { GraphContext } from '../../core/context';
import ButtonEdge from './ButtonEdge';

const edgeTypes = {
  buttonedge: ButtonEdge,
};

const defaultEdgeOptions = {
  type: 'buttonedge',
  markerEnd: { type: MarkerType.ArrowClosed },
  animated: true,
};

function Graph({ batchId }: { batchId: string }) {
  const { state, setState } = useContext(GraphContext);

  const [conceptNodes, setConceptNodes] = useState(state.nodes);
  const [conceptEdges, setConceptEdges] = useState(state.edges);

  useEffect(() => {
    if (batchId) {
      Cloud.post(
        { action: PublicConstants.ACTION_GET_BATCH_GRAPH, data: { batchId: batchId } },
        PublicConstants.API_CONTENT,
      ).then((response) => {
        if (response.success) {
          if (response.value.type === 'S3') {
            const data = response.value.data;
            setState({ nodes: data.nodes, edges: data.edges });
          } else {
            let nodes: Node[] = [];
            let edges: Edge[] = [];
            for (let i = 0; i < response.value.data.length; i++) {
              const item = response.value.data[i];
              const id = item.id;
              // Split the id into theory type and nanoId
              const [type, nid] = id.split('::');
              const label = `${type}-${item.concept}`;

              nodes.push({
                id: nid,
                type: type,
                data: { label: label },
                position: { x: Math.random() * 300, y: Math.random() * 300 },
              });
            }
            setState({ nodes, edges });
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    setState({ nodes: conceptNodes, edges: conceptEdges });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conceptEdges]);

  useEffect(() => {
    setConceptNodes(state.nodes);
    setConceptEdges(state.edges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const checkConnected = (dbRecord: any) => {
    const nodes = JSON.parse(JSON.stringify(dbRecord.nodes));
    const visit = (node: any) => {
      if (node.visited) return;
      node.visited = true;
      node.next.forEach((n: string) => {
        visit(nodes[n]);
      });
      node.prev.forEach((n: string) => {
        visit(nodes[n]);
      });
    };
    visit(nodes[Object.keys(nodes)[0]]);
    for (let i = 0; i < Object.keys(nodes).length; i++) {
      const node = nodes[Object.keys(nodes)[i]];
      if (!node.visited) {
        return false;
      }
    }
    return true;
  };

  const checkCyclic = (dbRecord: any) => {
    const nodes = JSON.parse(JSON.stringify(dbRecord.nodes));
    const visit = (node: any) => {
      if (node.visited) return true;
      node.visited = true;
      for (let i = 0; i < node.next.length; i++) {
        const n = node.next[i];
        if (visit(nodes[n])) return true;
      }
      node.visited = false;
      return false;
    };
    for (let i = 0; i < Object.keys(nodes).length; i++) {
      const node = nodes[Object.keys(nodes)[i]];
      if (visit(node)) return true;
    }
  };

  const save = async () => {
    const dbRecord: { batchId: string; nodes: any } = {
      batchId: batchId,
      nodes: {},
    };

    conceptNodes.forEach((node: Node) => {
      const { id, type } = node;
      dbRecord.nodes[id] = {
        type: type,
        next: [],
        prev: [],
      };
    });

    conceptEdges.forEach((edge: Edge) => {
      const { source, target } = edge;
      dbRecord.nodes[source].next.push(target);
      dbRecord.nodes[target].prev.push(source);
    });

    // Okay if it is not connected.
    // if (!checkConnected(dbRecord)) {
    //   alert('Graph is not connected');
    //   return;
    // }

    if (checkCyclic(dbRecord)) {
      alert('Graph is cyclic');
      return;
    }

    const s3Dump = {
      nodes: conceptNodes,
      edges: conceptEdges,
    };

    const response = await Cloud.post(
      { action: PublicConstants.ACTION_SAVE_GRAPH, data: { ...dbRecord, s3Dump } },
      PublicConstants.API_CONTENT,
    );
    if (response.success) {
      alert('Saved');
    }
  };

  const onConnect = useCallback(
    (params: Connection | Edge) => setConceptEdges((eds) => addEdge(params, eds)),
    [setConceptEdges],
  );
  const onNodesChange = useCallback(
    (changes: NodeChange[]) => setConceptNodes((nods) => applyNodeChanges(changes, nods)),
    [],
  );
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => setConceptEdges((edges) => applyEdgeChanges(changes, edges)),
    [],
  );

  return (
    <>
      <button className="wide btn btn-dark mt-2" onClick={save}>
        Save
      </button>
      <div className="Flow" style={{ height: '80vh' }}>
        <ReactFlow
          nodes={conceptNodes}
          onNodesChange={onNodesChange}
          edges={conceptEdges}
          onEdgesChange={onEdgesChange}
          defaultEdgeOptions={defaultEdgeOptions}
          onConnect={onConnect}
          edgeTypes={edgeTypes}
          fitView
        />
      </div>
    </>
  );
}

export default Graph;
