import React, { useState, useEffect } from 'react';
import { ConceptGraph } from '../../../lib/globaltypes';
import { InitStore } from '../initStore';

export default function useConcepts() {
  const [concepts, setConcepts] = useState<ConceptGraph>(InitStore.concepts);
  const [conceptsFetchError, setConceptsFetchError] = useState<string | null>(InitStore.fetchError);
  const [reachableNodes, setReachableNodes] = useState<{ [k: string]: string[] }>(InitStore.reachableNodes);
  const [weights, setWeights] = useState<{ [k: string]: number }>(InitStore.weights);
  const [dropDownOptions, setDropDownOptions] = useState<{ value: string; label: string }[]>(InitStore.dropDownOptions);
  const [initialized, setInitialzed] = React.useState(InitStore.initialized);

  useEffect(() => {
    if (initialized) return;
    InitStore.init().then(() => {
      setInitialzed(true);
      setConcepts(InitStore.concepts);
      setConceptsFetchError(InitStore.fetchError);
      setReachableNodes(InitStore.reachableNodes);
      setWeights(InitStore.weights);
      setDropDownOptions(InitStore.dropDownOptions);
    });
  }, [initialized]);

  return { concepts, reachableNodes, weights, conceptsFetchError, dropDownOptions };
}
