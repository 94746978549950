import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import { IQuestion } from '../../../../lambda/question/question-schema';
import { ErrorMessage } from '../common/error-message';
import { AppContext } from '../../core/context';
import makeAnimated from 'react-select/animated';
import useConcepts from '../../hooks/useConcepts';
import { handleQuestionFormSubmission } from '../../utils/questions';
import { conceptsCanopy, validateConceptsIndependent } from '../../utils/utils';

export const animatedComponents = makeAnimated();

export function ConceptTagsForm({ question, refreshQuestion }: { question: IQuestion; refreshQuestion: () => void }) {
  const { concepts, reachableNodes, weights, conceptsFetchError, dropDownOptions: options } = useConcepts();
  const equationConcepts = () => {
    return question.equationsDescription.map((ed) => ed.concepts.map((c) => ({ value: c.id, label: c.label }))).flat();
  };

  const [selectedOptions, setSelectedOptions] = React.useState<{ value: string; label: string }[]>(() => {
    let concepts = equationConcepts();
    if (question.concepts.length > 0) {
      concepts = concepts.concat(question.concepts.map((c) => ({ value: c.id, label: c.label })));
    }
    return conceptsCanopy(concepts, reachableNodes);
  });
  const [edit, setEdit] = React.useState(false);

  const [formError, setFormError] = React.useState('');
  const { state } = React.useContext(AppContext);

  // Whenever selected options change, verify that their is no error,
  // i.e. no child parent concepts are together.
  useEffect(() => {
    setFormError('');
    const error = validateConceptsIndependent(selectedOptions, reachableNodes);
    if (error) {
      setFormError(error);
      return;
    }

    const equationConceptsCanopy = conceptsCanopy(equationConcepts(), reachableNodes);
    for (let i = 0; i < equationConceptsCanopy.length; i++) {
      // In the selectedOptions atleast one should be an ancestor of the concepts3[i].
      let found = false;
      for (let j = 0; j < selectedOptions.length; j++) {
        if (reachableNodes && reachableNodes[equationConceptsCanopy[i].value]?.includes(selectedOptions[j].value)) {
          found = true;
          break;
        }
      }
      if (!found) {
        setFormError(
          `Concept ${equationConceptsCanopy[i].label} is not included in any of the selected concepts. Please select it or one of its ancestors.`,
        );
        return;
      }
    }

    // Check that the equations concepts are honored.
  }, [selectedOptions, concepts, reachableNodes]);

  const handleChange = (options: any) => {
    // Check the validity and if not show the error and disable the submit button.
    setSelectedOptions(options);
  };

  let editAllowed = question.numEquations !== undefined;

  return (
    <div className="row border p-4 border-secondary">
      <ErrorMessage error={conceptsFetchError} />
      <ErrorMessage error={formError} />
      <div className="container">
        <div className="row">
          <div className="col-4">
            <h5>Concept Tags</h5>
          </div>
          <div className="col-7">
            {!editAllowed && <h6 className="text-warning">First submit number of equations information</h6>}
            {editAllowed &&
              (question.concepts.length === 0 ? (
                <h6 className="text-warning">Unknown</h6>
              ) : (
                <h6 className="text-success">
                  Concepts: {question.concepts.map((c) => c.label).join(', ')} marked by {question.conceptsAuthor}
                </h6>
              ))}
          </div>
          <div className="col-1">
            <button type="button" disabled={!editAllowed} className="btn btn-primary" onClick={() => setEdit(!edit)}>
              Edit
            </button>
          </div>
        </div>
      </div>

      {edit && (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setFormError('');
              if (selectedOptions.length === 0) {
                setFormError('Please select at least one option');
                return;
              } else {
                // An api call to set concepts and author.
                Cloud.post(
                  {
                    action: PublicConstants.ACTION_UPDATE_QUESTION,
                    // @ts-ignore
                    data: {
                      id: question._id,
                      update: PublicConstants.UPDATE_CONCEPTS,
                      // @ts-ignore
                      value: selectedOptions.map((so) => ({
                        id: so.value,
                        label: so.label,
                        weight: [
                          {
                            weight: weights[so.value],
                            algorithm: '1 + sum-over-children',
                          },
                        ],
                      })),
                      author: state.userInfo?.info.email,
                    },
                  },
                  PublicConstants.API_QUESTION,
                ).then((response) => {
                  handleQuestionFormSubmission(response, refreshQuestion, setFormError, setEdit);
                });
              }
            }}
          >
            <label htmlFor="ConceptTags" className="form-label">
              Concept Tags
            </label>
            <Select
              isMulti
              name="colors"
              value={selectedOptions}
              options={options}
              components={animatedComponents}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleChange}
              required={true}
              id="ConceptTags"
            />
            <button type="submit" className="col-12 mt-3 btn btn-primary">
              Submit Concept Tags
            </button>
          </form>
        </>
      )}
    </div>
  );
}
