import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { ILatexTask } from '../../../../lambda/question/task-schema';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import { ErrorMessage } from '../common/error-message';
import TanstackTable from '../common/tanstack-table';

export default function LatexTaskDashboard() {
  const [tasks, setTasks] = useState<ILatexTask[]>([]);
  const [error, setError] = useState('');
  const [convertError, setConvertError] = useState('');

  useEffect(() => {
    setError('');
    Cloud.post(
      {
        action: PublicConstants.ACTION_GET_LATEX_TASKS,
        data: {},
      },
      PublicConstants.API_QUESTION,
    ).then((response) => {
      console.log(response);
      if (!response.success) {
        setError('Error while fetching tasks');
        return;
      }

      setTasks(response.value.tasks);
    });
  }, []);

  const convert = () => {
    setConvertError('');
    Cloud.post(
      {
        action: PublicConstants.ACTION_CONVERT_LATEX_TASKS,
        data: {},
      },
      PublicConstants.API_QUESTION,
    ).then((response) => {
      console.log(response);
      if (!response.success) {
        setConvertError('Error while converting tasks');
        return;
      }
    });
  };

  return (
    <div>
      <h1>Latex Tasks Dashboard</h1>
      <hr />
      <ErrorMessage error={error} />
      <TasksTable tasks={tasks} />
      <TasksStats tasks={tasks} />
      <hr />
      <h3>Task Actions</h3>
      <ErrorMessage error={convertError} />
      <button
        className="btn btn-primary w-full"
        onClick={() => {
          console.log(tasks);
          convert();
        }}
      >
        Convert some tasks
      </button>
    </div>
  );
}

function TasksTable({ tasks }: { tasks: ILatexTask[] }) {
  const columnHelper = createColumnHelper<ILatexTask>();

  const defaultColumns = [
    // Grouping Column
    columnHelper.group({
      id: 'Info',
      header: 'Info',
      footer: (props) => props.column.id,
      columns: [
        columnHelper.accessor(
          (row) => `${row._id}`,
          // @ts-ignore
          {
            // @ts-ignore
            id: 'Id',
            // @ts-ignore
            header: 'Id',
            // @ts-ignore
            footer: (props) => props.column.id,
            // @ts-ignore
            cell: (info) => info.getValue(),
          },
        ),
        columnHelper.accessor((row) => `${row.title}`, {
          id: 'Name',
          header: 'Name',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue(),
        }),

        columnHelper.accessor((row) => `${row.numQuestions}`, {
          id: 'Questions',
          header: '#Questions',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue(),
        }),

        columnHelper.accessor((row) => `${row.status}`, {
          id: 'State',
          header: 'State',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue(),
        }),
      ],
    }),
  ];

  return <TanstackTable data={tasks} columns={defaultColumns} />;
}

function TasksStats({ tasks }: { tasks: ILatexTask[] }) {
  const totalNumQuestion = tasks.reduce((acc, task) => acc + (task.numQuestions ?? 0), 0);
  const totalConvertibleQuestion = tasks
    .filter(
      (task) =>
        task.status === PublicConstants.LatexTaskStatus.VERIFIED && task.class && task.chapter && task.taskSource,
    )
    .reduce((acc, task) => acc + (task.numQuestions ?? 0), 0);

  const totalVerifiedQuestions = tasks
    .filter((task) => task.status === PublicConstants.LatexTaskStatus.VERIFIED)
    .reduce((acc, task) => acc + (task.numQuestions ?? 0), 0);
  return (
    <>
      <h2>Tasks Stats</h2>
      <table className="table-auto">
        <thead>
          <tr>
            <th className="px-4 py-2">Metric</th>
            <th className="px-4 py-2">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-4 py-2">All Tasks</td>
            <td className="border px-4 py-2">{tasks.length}</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">Submitted tasks (including verified)</td>
            <td className="border px-4 py-2">
              {
                tasks.filter(
                  (t) =>
                    t.status === PublicConstants.LatexTaskStatus.SUBMITTED ||
                    t.status === PublicConstants.LatexTaskStatus.VERIFIED,
                ).length
              }
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">Verified tasks</td>
            <td className="border px-4 py-2">
              {tasks.filter((t) => t.status === PublicConstants.LatexTaskStatus.VERIFIED).length}
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">Convertible tasks</td>
            <td className="border px-4 py-2">
              {
                tasks.filter(
                  (task) =>
                    task.status === PublicConstants.LatexTaskStatus.VERIFIED &&
                    task.class &&
                    task.chapter &&
                    task.taskSource,
                ).length
              }
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">Questions (submitted + verified)</td>
            <td className="border px-4 py-2">{totalNumQuestion}</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">Verified questions</td>
            <td className="border px-4 py-2">{totalVerifiedQuestions}</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">Convertible questions</td>
            <td className="border px-4 py-2">{totalConvertibleQuestion}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
