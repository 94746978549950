/*
 * --------------------------------------------------------------------------- *
 * File: types.ts                                                              *
 * Project: faculty-dashboard                                                  *
 * Created Date: 19 Feb 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Fri Mar 24 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import { nanoid } from 'nanoid';

export interface UserInfo {
  token: string;
  info: { email: string; name: string; subject: string };
  permissions: { admin: boolean; upload: boolean; course: boolean };
}

export interface Course {
  id: string;
  name: string;
  description: string;
  keywords: string[];
  sectionList: Section[];
  lastUpdated: number;
  contentQuanta: number;
  questions: number;
  size: number;
  logo: string;
  videoCount: number;
}

export interface Section {
  id: string;
  name: string;
  description: string;
  lessonList: Lesson[];
}

export interface Lesson {
  id: string;
  name: string;
  description: string;
  contentQuanta: string[];
}

export interface Exam {
  id: string;
  type: string;
  date: string;
}

export interface Holiday {
  id: string;
  name: string;
  date: string;
}

export interface Target {
  id: string;
  name: string;
  description: string;
  courseList: string[];
  targetDate: number;
  lastUpdated: number;
  startDate: number;
  published: boolean;
  statistics: {
    students: number;
    revenue: number;
    subjects: number;
    courses: number;
    contentQuanta: number;
    exams: number;
    questions: number;
    netEffort: number;
    videoCount: number;
  };
  examList: Exam[];
  holidays: Holiday[];
  logo: string;
  targetExam: string;
}

export const BlankTarget = (): Target => ({
  courseList: [],
  description: '',
  id: '',
  name: '',
  startDate: new Date().getTime(),
  examList: [],
  holidays: [],
  published: false,
  statistics: {
    revenue: 0,
    students: 0,
    subjects: 0,
    courses: 0,
    contentQuanta: 0,
    exams: 0,
    questions: 0,
    netEffort: 0,
    videoCount: 0,
  },
  targetDate: new Date().getTime() + 1000 * 60 * 60 * 24 * 365,
  lastUpdated: 0,
  logo: '',
  targetExam: '',
});

export const BlankCourse: Course = {
  id: '',
  name: '',
  description: '',
  keywords: [],
  sectionList: [],
  lastUpdated: Date.now(),
  questions: 0,
  contentQuanta: 0,
  size: 0,
  videoCount: 0,
  logo: '',
};

export const BlankExam = (): Exam => ({
  id: nanoid(),
  type: '',
  date: '2023-01-01',
});

export const BlankHoliday = (): Holiday => ({
  id: nanoid(),
  name: '',
  date: '2023-01-01',
});
