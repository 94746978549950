import React from 'react';

import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';

import type { IQuestion } from '../../../../lambda/question/question-schema';
import RenderQuestion from './question/render-question';
import { questionTranslatedSchema, TranslatedQuestion } from './schema';
import { ErrorMessage } from '../common/error-message';
import { QuestionsTable } from './QuestionsTable';
import { CorrectnessForm } from './CorrectnessForm';
import { ConceptTagsForm } from './ConceptTagsForm';
import { NumberEquationsForm } from './NumberEquationsForm';
import { DifficultyLevelsForm } from './DifficultyLevelsForm';
import { SubjectChapterForm } from './SubjectChapterForm';
import { languageCodeToString } from '../../utils/utils';
import AnswerTemplateForm from './AnswerTemplateForm';
import { isAnsPresent, isAnswerTemplatable, isSolutionPresent, substituteTemplate } from '../../utils/questions';
import ImageConsistentForm from './ImageConsistentForm';

export default function Questions() {
  const [error, setError] = React.useState<string>('');
  const [searchValue, setSearchValue] = React.useState('');
  const [questions, setQuestions] = React.useState<IQuestion[]>([]);
  const [selectedQuestionId, setSelectedQuestionId] = React.useState<string>('');
  const [englishCorrect, setEnglishCorrect] = React.useState<boolean>(true);
  const [englishIncorrect, setEnglishIncorrect] = React.useState<boolean>(true);
  const [englishUnknown, setEnglishUnknown] = React.useState<boolean>(true);
  const [hindiCorrect, setHindiCorrect] = React.useState<boolean>(true);
  const [hindiIncorrect, setHindiIncorrect] = React.useState<boolean>(true);
  const [hindiUnknown, setHindiUnknown] = React.useState<boolean>(true);
  const [finalized, setFinalized] = React.useState<boolean>(true);
  const [notFinalized, setNotFinalized] = React.useState<boolean>(true);
  const [conceptsTagged, setConceptsTagged] = React.useState<boolean>(true);
  const [conceptsNotTagged, setConceptsNotTagged] = React.useState<boolean>(true);
  const [numEquationsPresent, setNumEquationsPresent] = React.useState<boolean>(true);
  const [numEquationsAbsent, setNumEquationsAbsent] = React.useState<boolean>(true);
  const [template, setTemplate] = React.useState<boolean>(false);

  const onQuestionRefetch = (q: IQuestion) => {
    const index = questions.findIndex((question) => question._id === q._id);
    if (index === -1) {
      return;
    }
    questions[index] = q;
    setQuestions([...questions]);
  };

  return (
    <div>
      <div className="container p-2 mt-4">
        <div className="row">
          <ErrorMessage error={error} />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setError('');
              console.log('Submitted search', Object.fromEntries(new FormData(e.target as HTMLFormElement)));
              Cloud.post(
                {
                  action: PublicConstants.ACTION_GET_QUESTIONS,
                  data: {
                    searchValue,
                    englishCorrect,
                    englishIncorrect,
                    englishUnknown,
                    hindiCorrect,
                    hindiIncorrect,
                    hindiUnknown,
                    finalized,
                    notFinalized,
                    conceptsTagged,
                    conceptsNotTagged,
                    numEquationsPresent,
                    numEquationsAbsent,
                    template,
                  },
                },
                PublicConstants.API_QUESTION,
              ).then((response) => {
                if (response.value.error) {
                  setError(response.value.error);
                  return;
                }

                setQuestions(response.value.questions);
              });
            }}
          >
            <div className="row">
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={englishCorrect}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setEnglishCorrect(e.target.checked);
                  }}
                />
                <span className="m-1">English version correct</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={englishIncorrect}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setEnglishIncorrect(e.target.checked);
                  }}
                />
                <span className="m-1">English version Incorrect</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={englishUnknown}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setEnglishUnknown(e.target.checked);
                  }}
                />
                <span className="m-1">English version unverified</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={hindiCorrect}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setHindiCorrect(e.target.checked);
                  }}
                />
                <span className="m-1">Hindi version correct</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={hindiIncorrect}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setHindiIncorrect(e.target.checked);
                  }}
                />
                <span className="m-1">Hindi version Incorrect</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={hindiUnknown}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setHindiUnknown(e.target.checked);
                  }}
                />
                <span className="m-1">Hindi version unverified</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={finalized}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setFinalized(e.target.checked);
                  }}
                />
                <span className="m-1">Finalized</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={notFinalized}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setNotFinalized(e.target.checked);
                  }}
                />
                <span className="m-1">Not finalized</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={conceptsTagged}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setConceptsTagged(e.target.checked);
                  }}
                />
                <span className="m-1">Concepts Tagged</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={conceptsNotTagged}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setConceptsNotTagged(e.target.checked);
                  }}
                />
                <span className="m-1">Concepts Unknown</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={numEquationsPresent}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setNumEquationsPresent(e.target.checked);
                  }}
                />
                <span className="m-1">Number of Equations Set</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={numEquationsAbsent}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setNumEquationsAbsent(e.target.checked);
                  }}
                />
                <span className="m-1">Number of Equations Unknown</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={template}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setTemplate(e.target.checked);
                  }}
                />
                <span className="m-1">Template Question</span>
              </div>
            </div>
            <div className="input-group">
              <input
                type="search"
                name="search"
                className="form-control mt-2"
                placeholder="Search..."
                aria-label="Search"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <button type="submit" className="btn btn-primary mt-2">
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="container p-2">
        <div className="row">
          <h1>Questions</h1>
          <QuestionsTable
            questions={questions}
            questionAction={setSelectedQuestionId}
            onQuestionRefetch={onQuestionRefetch}
          />
        </div>
      </div>
      {selectedQuestionId !== '' && (
        <SelectedQuestion questionId={selectedQuestionId} onQuestionRefetch={onQuestionRefetch} />
      )}
    </div>
  );
}

function SelectedQuestion({
  questionId,
  onQuestionRefetch,
}: {
  questionId: string;
  onQuestionRefetch: (q: IQuestion) => void;
}) {
  console.log('SelectedQuestion', questionId);
  const [error, setError] = React.useState<string>('');
  const [question, setQuestion] = React.useState<IQuestion | null>(null);
  const [version, setVersion] = React.useState(0);
  const [saveQuestionError, setSaveQuestionError] = React.useState<string>('');
  const [templateSubtitution, setTemplateSubtitution] = React.useState<number>(-1);

  React.useEffect(() => {
    setError('');

    Cloud.post(
      {
        action: PublicConstants.ACTION_GET_QUESTION,
        data: { id: questionId },
      },
      PublicConstants.API_QUESTION,
    ).then((response) => {
      if (!response.value.question) {
        setError(`Question for id ${questionId} not found, error: ${response.value.error}`);
        return;
      }
      setQuestion(response.value.question);
    });
  }, [questionId, version]);

  React.useEffect(() => {
    if (question) onQuestionRefetch(question);
  }, [question]);

  if (!question) {
    return null;
  }

  const finalize = async (e: any) => {
    e.preventDefault();
    setError('');
    console.log('Finalizing question', questionId);
    // Check correctness of across languages
    if (!question.correctness) {
      setError('English version correctness is not set');
      return;
    }

    // translation languages
    const translatedQuestion = questionTranslatedSchema.parse(question.content);
    const langs: string[] = [];
    translatedQuestion.translations.forEach((t) => {
      langs.push(t.lang);
    });
    question.translationCorrectness.forEach((c) => {
      if (c.correctness) {
        langs.splice(langs.indexOf(c.lang), 1);
      }
    });

    if (langs.length > 0) {
      setError(
        'Correctness of translations is not set for languages: ' + langs.map((l) => languageCodeToString(l)).join(', '),
      );
      return;
    }

    // Check concepts are tagged
    if (!question.concepts || question.concepts.length === 0) {
      setError('concepts are not tagged');
      return;
    }

    // Check number of equations is set
    if (question.numEquations === undefined) {
      setError('Number of equations is not set');
      return;
    }

    if (!question.imageConsistent) {
      setError('Question is not image consistent');
      return;
    }

    if (translatedQuestion.type === 'subjective') {
      if (!question.answerTemplate) {
        setError('Answer template is not set');
        return;
      }
    }

    // Check if answer is present
    if (!isAnsPresent(question)) {
      setError('Answer is not present');
      return;
    }

    if (!isSolutionPresent(question)) {
      setError('Solution is not present');
      return;
    }

    Cloud.post(
      {
        action: PublicConstants.ACTION_FINALIZE_QUESTION,
        data: { id: questionId },
      },
      PublicConstants.API_QUESTION,
    ).then((response) => {
      console.log('finalize', response);
      if (!response.success || response.value.error) {
        setError('Finalizing failed for question due to error: ' + (response.value.error ?? 'Unknown error'));
        return;
      }

      setVersion(version + 1);
    });
  };

  const saveQuestion = async (q: TranslatedQuestion) => {
    setSaveQuestionError('');
    Cloud.post(
      {
        action: PublicConstants.ACTION_UPDATE_QUESTION,
        data: {
          id: questionId,
          update: PublicConstants.UPDATE_CONTENT,
          content: q,
        },
      },
      PublicConstants.API_QUESTION,
    ).then((response) => {
      if (!response.success) {
        setSaveQuestionError('Updating question failed please retry');
        return;
      }

      setVersion(version + 1);
    });
  };

  let questionDisplayContent: TranslatedQuestion;
  try {
    questionDisplayContent = questionTranslatedSchema.parse(question.content);
  } catch (e) {
    console.log('Error parsing question', e, JSON.stringify(question.content, null, 2));
    return <div>Invalid question</div>;
  }

  if (templateSubtitution >= 0 && question.template) {
    // Get the parameter values
    const parameterValues = question.templateParameterValues[templateSubtitution];
    const parameterNames = question.templateParameters;
    const parameterMap: { [key: string]: string } = {};
    parameterNames.forEach((p, i) => {
      parameterMap[p.name] = parameterValues.values[i];
    });

    // Substitute template
    const substitutedQuestion = substituteTemplate(questionDisplayContent, parameterMap, {});
    questionDisplayContent = { ...substitutedQuestion };
  }

  if (question.finalized) {
    return (
      <div className="container p-2">
        <div className="row">
          <h1 className="my-2">Question has been finalized and freezed</h1>
          <ErrorMessage error={error} />
          <RenderQuestion
            question={questionDisplayContent}
            readonly={false}
            updateQuestion={(q: TranslatedQuestion) => {}}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {question && (
        <div className="container p-4">
          <div className="row">
            <h1 className="my-2">{`Question Improvement!!`}</h1>
          </div>
          <div className="row ">
            <button className="btn btn-primary col-2 my-3" onClick={() => setVersion(version + 1)}>
              Refresh
            </button>
            <form className="d-inline p-0 m-0 col-10" onSubmit={finalize}>
              <button className="btn btn-primary col-2 m-3" type="submit">
                Finalize
              </button>
            </form>
            <ErrorMessage error={error} />
            <div className="col-12">
              <SubjectChapterForm question={question} refreshQuestion={() => setVersion(version + 1)} />
              <CorrectnessForm question={question} lang="en" refreshQuestion={() => setVersion(version + 1)} />
              <CorrectnessForm question={question} lang="hi" refreshQuestion={() => setVersion(version + 1)} />
              <CorrectnessForm question={question} lang="te" refreshQuestion={() => setVersion(version + 1)} />
              <ConceptTagsForm question={question} refreshQuestion={() => setVersion(version + 1)} />
              <NumberEquationsForm question={question} refreshQuestion={() => setVersion(version + 1)} />
              <DifficultyLevelsForm question={question} refreshQuestion={() => setVersion(version + 1)} />
              <ImageConsistentForm question={question} refreshQuestion={() => setVersion(version + 1)} />
              {isAnswerTemplatable(question) && (
                <AnswerTemplateForm question={question} refreshQuestion={() => setVersion(version + 1)} />
              )}
            </div>
          </div>
          <div className="row">
            {question.template && (
              <div className="col-12">
                {question.templateParameterValues &&
                  question.templateParameterValues.map((p, i) => {
                    return (
                      <div className="border" key={i}>
                        <div className="row">
                          {p.values.map((v, j) => {
                            return (
                              <div className="col-2" key={j}>
                                {question.templateParameters[j].name}: {v}
                              </div>
                            );
                          })}
                          <div className="d-flex justify-content-end">
                            <button className="btn btn-primary" onClick={() => setTemplateSubtitution(i)}>
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
            <div className="col-12 my-2">
              <ErrorMessage error={saveQuestionError} />
              <RenderQuestion
                question={questionDisplayContent}
                readonly={question.template ? true : question.finalized}
                updateQuestion={saveQuestion}
              />
            </div>
          </div>
          <div className="row">
            <div className="container"></div>
          </div>
        </div>
      )}
    </>
  );
}
