/*
 * --------------------------------------------------------------------------- *
 * File: recorder.tsx                                                          *
 * Project: faculty-dashboard                                                  *
 * Created Date: 12 Mar 2023                                                   *
 * Author: Vikas K Solegaonkar (vikas.solegaonkar@thinkprosystems.com)         *
 * Copyright(c) ThinkPro Systems Pty Ltd, 2023                                 *
 *                                                                             *
 * Last Modified: Sun Mar 12 2023                                              *
 * Modified By: Vikas K Solegaonkar                                            *
 *                                                                             *
 * HISTORY:                                                                    *
 * --------------------------------------------------------------------------- *
 * Date         By     Comments                                                *
 * --------------------------------------------------------------------------- *
 */

import React from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';

function SoundRecorder({ onUpload }: { onUpload: (url: string) => void }) {
  const [audioURL, setAudioURL] = React.useState<string | undefined>(undefined);
  const recorderControls = useAudioRecorder();
  const uploadAudio = async () => {
    const buffer = (await recorderControls.recordingBlob?.stream().getReader().read())?.value;
    Cloud.uploadAudioBlob('webm', buffer).then((res) => {
      const url = `https://${PublicConstants.CONTENT_SUB_DOMAIN}.${PublicConstants.BASE_DOMAIN}/${res}`;
      setAudioURL(url);
      onUpload(url);
    });
  };

  return (
    <div>
      <AudioRecorder onRecordingComplete={uploadAudio} recorderControls={recorderControls} />
      {audioURL && <audio src={audioURL} controls />}
    </div>
  );
}

export default SoundRecorder;
