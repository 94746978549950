import React, { useEffect } from 'react';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import { IQuestion } from '../../../../lambda/question/question-schema';
import { ErrorMessage } from '../common/error-message';
import { AppContext } from '../../core/context';
import useConcepts from '../../hooks/useConcepts';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { equationsDescriptionString, handleQuestionFormSubmission } from '../../utils/questions';
import { validateConceptsIndependent } from '../../utils/utils';

export const animatedComponents = makeAnimated();

export function NumberEquationsForm({
  question,
  refreshQuestion,
}: {
  question: IQuestion;
  refreshQuestion: () => void;
}) {
  const [formError, setFormError] = React.useState('');
  const { state } = React.useContext(AppContext);
  const [edit, setEdit] = React.useState(false);
  const [numEquations, setNumEquations] = React.useState<number>(0);
  const [selectedConceptsErrors, setSelectedConceptsErrors] = React.useState<string[]>([]);

  const { concepts, reachableNodes, weights, conceptsFetchError, dropDownOptions } = useConcepts();

  const [selectedOptionsArray, setSelectedOptionsArray] = React.useState<{ value: string; label: string }[][]>([]);
  useEffect(() => {
    if (isNaN(numEquations) || numEquations <= 0) return;
    const result = [];
    for (let i = 0; i < numEquations; i++) {
      result.push([]);
    }
    setSelectedOptionsArray(result);

    // Also set the errors to empty.
    setSelectedConceptsErrors(new Array(numEquations).fill(''));
  }, [numEquations]);

  useEffect(() => {
    if (!isNaN(numEquations) && numEquations > 0) {
      setSelectedConceptsErrors(new Array(numEquations).fill(''));
    }

    selectedOptionsArray.forEach((selectedOptions, index) => {
      const error = validateConceptsIndependent(selectedOptions, reachableNodes);
      if (error) {
        setSelectedConceptsErrors((prev) => {
          prev[index] = error;
          return prev;
        });
      }
    });
  }, [selectedOptionsArray, concepts, reachableNodes, numEquations]);

  const handleChange = (options: any, idx: number) => {
    // Check the validity and if not show the error and disable the submit button.
    const newSelectedOptions = [...selectedOptionsArray];
    newSelectedOptions[idx] = options;
    console.log(newSelectedOptions);
    setSelectedOptionsArray(newSelectedOptions);
  };

  return (
    <div className="row border p-4 border-secondary">
      <ErrorMessage error={formError} />
      <div className="container">
        <div className="row">
          <div className="col-4">
            <h5>Number of Equations</h5>
            <p>
              Number of fundamental equations for solving the given question (excluding any equation in the question)
            </p>
          </div>
          <div className="col-7">
            {question.numEquations === null || question.numEquations === undefined ? (
              <h6 className="text-warning">Unknown</h6>
            ) : (
              <h6 className="text-success">
                {question.numEquations} equations identified by {question.numEquationsAuthor}, description:{' '}
                {equationsDescriptionString(question.equationsDescription)}
              </h6>
            )}
          </div>
          <div className="col-1">
            <button type="button" className="btn btn-primary" onClick={() => setEdit(!edit)}>
              Edit
            </button>
          </div>
        </div>
      </div>
      {edit && (
        <div className="col-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setFormError('');
              const formData = Object.fromEntries(new FormData(e.target as HTMLFormElement));

              if (numEquations < 0 || isNaN(numEquations)) {
                setFormError(`Please enter a valid non-negative number, ${numEquations} is not valid`);
                return;
              }

              const equationsDescription: {
                description: string;
                concepts: { id: string; label: string; weight: { weight: number; algorithm: string }[] }[];
              }[] = [];
              for (let i = 0; i < numEquations; i++) {
                equationsDescription.push({
                  description: formData[`equations${i}`] as string,
                  concepts: selectedOptionsArray[i].map((c) => {
                    return {
                      id: c.value,
                      label: c.label,
                      weight: [
                        {
                          weight: weights[c.value],
                          algorithm: '1 + sum-over-children',
                        },
                      ],
                    };
                  }),
                });
              }

              Cloud.post(
                {
                  action: PublicConstants.ACTION_UPDATE_QUESTION,
                  // @ts-ignore
                  data: {
                    id: question._id,
                    update: PublicConstants.UPDATE_NUM_EQUATIONS,
                    numEquations,
                    equationsDescription,
                    author: state.userInfo?.info.email,
                  },
                },
                PublicConstants.API_QUESTION,
              ).then((response) => {
                handleQuestionFormSubmission(response, refreshQuestion, setFormError, setEdit);
              });
            }}
          >
            <div className="m-1">
              <ErrorMessage error={conceptsFetchError} />
              <label htmlFor="FormControlInput1" className="form-label">
                Number of Equations
              </label>
              <input
                type="number"
                name="numEquations"
                value={numEquations}
                onChange={(e) => setNumEquations(parseInt(e.target.value))}
                className="form-control"
                id="FormControlInput1"
                placeholder="1,2,3..."
                required={true}
              />
            </div>
            {Array.from({ length: numEquations }, (_, i) => i).map((i) => {
              return (
                <div key={i}>
                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <label htmlFor={`FormControlInput${i}`} className="form-label">
                          Description of equation {i + 1}
                        </label>
                        <input
                          type="text"
                          name={`equations${i}`}
                          className="form-control mt-0 py-2"
                          id={`FormControlInput${i}`}
                          placeholder="1,2,3..."
                          required={true}
                        />
                      </div>
                      <div className="col-6">
                        <ErrorMessage error={selectedConceptsErrors[i]} />
                        <label htmlFor="ConceptTags" className="form-label">
                          Concept Tags for equation ${i + 1}
                        </label>
                        <Select
                          defaultValue={[]}
                          isMulti
                          name="colors"
                          options={dropDownOptions}
                          components={animatedComponents}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(options: any) => handleChange(options, i)}
                          required={true}
                          id="ConceptTags"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <button type="submit" className="col-12  btn btn-primary">
              Submit Number of equations details
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
