import React, { useEffect } from 'react';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import { IComprehension } from '../../../../lambda/question/question-schema';
import { ErrorMessage } from '../common/error-message';
import { handleComprehensionFormSubmission } from '../../utils/comprehension';

export function SubjectChapterForm({
  comprehension,
  refreshComprehension,
}: {
  comprehension: IComprehension;
  refreshComprehension: () => void;
}) {
  const [formError, setFormError] = React.useState('');
  const [edit, setEdit] = React.useState(false);
  const [subjectList, setSubjectList] = React.useState<{ value: string; label: string }[]>([]);
  const [chapterList, setChapterList] = React.useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    Cloud.getConfig().then((c) => {
      setSubjectList(c.subjects.map((s: string) => ({ value: s, label: s })));
      let chapters: { value: string; label: string }[] = [];
      Object.keys(c.subjectChapters).forEach((s: string) => {
        for (const chap of c.subjectChapters[s]) {
          chapters.push({ value: `${s}::${chap}`, label: `${s}::${chap}` });
        }
      });
      setChapterList(chapters);
    });
  }, []);

  let subject = comprehension.subject;
  let chapter = comprehension.chapter;
  if (!comprehension) return null;
  return (
    <div className="row border p-4 border-secondary">
      <ErrorMessage error={formError} />
      <div className="container">
        <div className="row">
          <div className="col-4">
            <h5>Subject Chapter</h5>
          </div>
          <div className="col-7">
            {!subject ? (
              <h6 className="text-warning">Subject Unknown</h6>
            ) : (
              <h6 className="text-success">Subject: {subject}</h6>
            )}
            {'  '}
            {!chapter ? (
              <h6 className="text-warning">Chapter Unknown</h6>
            ) : (
              <h6 className="text-success">Chapter: {chapter}</h6>
            )}
          </div>
          <div className="col-1">
            <button type="button" className="btn btn-primary" onClick={() => setEdit(!edit)}>
              Edit
            </button>
          </div>
        </div>
        {edit && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setFormError('');
              const formData = Object.fromEntries(new FormData(e.target as HTMLFormElement));
              const subject = formData['subject'] as string;
              let chapter = formData['chapter'] as string;
              console.log(subject, chapter);
              if (chapter === 'default' || subject === 'default') {
                setFormError('Please select a valid option');
                return;
              }
              chapter = chapter.split('::')[1];
              Cloud.post(
                {
                  action: PublicConstants.ACTION_UPDATE_COMPREHENSION,
                  // @ts-ignore
                  data: {
                    id: comprehension._id,
                    update: PublicConstants.UPDATE_SUBJECT_CHAPTER,
                    subject,
                    chapter,
                  },
                },
                PublicConstants.API_QUESTION,
              ).then((response) => {
                handleComprehensionFormSubmission(response, refreshComprehension, setFormError, setEdit);
              });
            }}
          >
            <div className="row">
              <div className="col-6">
                <label htmlFor="Subject" className="form-label">
                  Subject
                </label>
                <select defaultValue={'default'} id="Subject" className="form-select" name="subject">
                  {subjectList.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-6">
                <label htmlFor="Chapter" className="form-label">
                  Chapter
                </label>
                <select defaultValue={'default'} id="Chapter" className="form-select" name="chapter">
                  {chapterList.map((o: { value: string; label: string }) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <button type="submit" className="col-12 mt-3 btn btn-primary">
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
