import useKatex from '../../../hooks/useKatex';
import { ErrorMessage } from '../../common/error-message';
import type { TranslatedQuestion } from '../schema';
import ARQuestion from './ar-question';
import FIBQuestion from './fib-question';
import IntegerQuestion from './integer-question';
import MatrixMatchQuestion from './matrixmatch-question';
import MCQQuestion from './mcq-question';
import SCQQuestion from './scq-question';
import SubjectiveQuestion from './subjective-question';
import TFQuestion from './tf-question';

export default function RenderQuestion({
  question,
  readonly,
  updateQuestion,
}: {
  question: TranslatedQuestion;
  readonly: boolean;
  updateQuestion: (question: TranslatedQuestion) => void;
}) {
  useKatex();

  switch (question.type) {
    case 'scq':
      return <SCQQuestion q={question} readonly={readonly} updateQuestion={updateQuestion} />;
    case 'mcq':
      return <MCQQuestion q={question} readonly={readonly} updateQuestion={updateQuestion} />;
    case 'fillInTheBlank':
      return <FIBQuestion q={question} readonly={readonly} updateQuestion={updateQuestion} />;
    case 'trueFalse':
      return <TFQuestion q={question} readonly={readonly} updateQuestion={updateQuestion} />;
    case 'subjective':
      return <SubjectiveQuestion q={question} readonly={readonly} updateQuestion={updateQuestion} />;
    case 'matrixMatch':
      return <MatrixMatchQuestion q={question} readonly={readonly} updateQuestion={updateQuestion} />;
    case 'integerType':
      return <IntegerQuestion q={question} readonly={readonly} updateQuestion={updateQuestion} />;
    case 'assertionReason':
      return <ARQuestion q={question} readonly={readonly} updateQuestion={updateQuestion} />;
  }

  return (
    <>
      <ErrorMessage error={`Unknown question type`} />
    </>
  );
}
