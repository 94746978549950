import React from 'react';
import invariant from 'tiny-invariant';
import { IComprehension } from '../../../../lambda/question/question-schema';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import { languageCodeToString } from '../../utils/utils';
import { ErrorMessage } from '../common/error-message';
import RenderQuestion from '../question/question/render-question';
import {
  comprehensionTranslatedSchema,
  questionTranslatedSchema,
  TranslatedComprehension,
  TranslatedQuestion,
} from '../question/schema';
import AnswerTemplateForm from './AnswerTemplateForm';
import { ComprehensionsTable } from './ComprehensionsTable';
import { ConceptTagsForm } from './ConceptTagsForm';
import { CorrectnessForm } from './CorrectnessForm';
import { DifficultyLevelsForm } from './DifficultyLevelsForm';
import ImageConsistentForm from './ImageConsistentForm';
import { NumberEquationsForm } from './NumberEquationsForm';
import RenderComprehension from './render-comprehension';
import { SubjectChapterForm } from './SubjectChapterForm';

export default function Comphrensions() {
  const [error, setError] = React.useState<string>('');
  const [searchValue, setSearchValue] = React.useState('');
  const [comprehensions, setComprehensions] = React.useState<IComprehension[]>([]);
  const [selectedComprehensionId, setSelectedComprehensionId] = React.useState<string>('');
  const [englishCorrect, setEnglishCorrect] = React.useState<boolean>(true);
  const [englishIncorrect, setEnglishIncorrect] = React.useState<boolean>(true);
  const [englishUnknown, setEnglishUnknown] = React.useState<boolean>(true);
  const [hindiCorrect, setHindiCorrect] = React.useState<boolean>(true);
  const [hindiIncorrect, setHindiIncorrect] = React.useState<boolean>(true);
  const [hindiUnknown, setHindiUnknown] = React.useState<boolean>(true);
  const [finalized, setFinalized] = React.useState<boolean>(true);
  const [notFinalized, setNotFinalized] = React.useState<boolean>(true);
  const [conceptsTagged, setConceptsTagged] = React.useState<boolean>(true);
  const [conceptsNotTagged, setConceptsNotTagged] = React.useState<boolean>(true);
  const [numEquationsPresent, setNumEquationsPresent] = React.useState<boolean>(true);
  const [numEquationsAbsent, setNumEquationsAbsent] = React.useState<boolean>(true);

  const onComprehensionRefetch = (c: IComprehension) => {
    const index = comprehensions.findIndex((comprehension) => comprehension._id === c._id);
    if (index === -1) {
      return;
    }
    comprehensions[index] = c;
    setComprehensions([...comprehensions]);
  };

  return (
    <div>
      <div className="container p-2 mt-4">
        <div className="row">
          <ErrorMessage error={error} />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              console.log('Submitted search', Object.fromEntries(new FormData(e.target as HTMLFormElement)));
              Cloud.post(
                {
                  action: PublicConstants.ACTION_GET_COMPREHENSIONS,
                  data: {
                    searchValue,
                    englishCorrect,
                    englishIncorrect,
                    englishUnknown,
                    hindiCorrect,
                    hindiIncorrect,
                    hindiUnknown,
                    finalized,
                    notFinalized,
                    conceptsTagged,
                    conceptsNotTagged,
                    numEquationsPresent,
                    numEquationsAbsent,
                  },
                },
                PublicConstants.API_QUESTION,
              ).then((response) => {
                if (!response.success) {
                  setError('Error while fetching comprehensions');
                  return;
                }

                setError('');
                setComprehensions(response.value.comprehensions);
              });
            }}
          >
            <div className="row">
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={englishCorrect}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setEnglishCorrect(e.target.checked);
                  }}
                />
                <span className="m-1">English version correct</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={englishIncorrect}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setEnglishIncorrect(e.target.checked);
                  }}
                />
                <span className="m-1">English version Incorrect</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={englishUnknown}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setEnglishUnknown(e.target.checked);
                  }}
                />
                <span className="m-1">English version correctness Unknown</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={hindiCorrect}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setHindiCorrect(e.target.checked);
                  }}
                />
                <span className="m-1">Hindi version correct</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={hindiIncorrect}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setHindiIncorrect(e.target.checked);
                  }}
                />
                <span className="m-1">Hindi version Incorrect</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={hindiUnknown}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setHindiUnknown(e.target.checked);
                  }}
                />
                <span className="m-1">Hindi version correctness Unknown</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={finalized}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setFinalized(e.target.checked);
                  }}
                />
                <span className="m-1">Finalized</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={notFinalized}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setNotFinalized(e.target.checked);
                  }}
                />
                <span className="m-1">Not finalized</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={conceptsTagged}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setConceptsTagged(e.target.checked);
                  }}
                />
                <span className="m-1">Concepts Tagged</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={conceptsNotTagged}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setConceptsNotTagged(e.target.checked);
                  }}
                />
                <span className="m-1">Concepts Unknown</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={numEquationsPresent}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setNumEquationsPresent(e.target.checked);
                  }}
                />
                <span className="m-1">Number of Equations Set</span>
              </div>
              <div className="col-3">
                <input
                  className="form-check-input p-0 mr-1"
                  type="checkbox"
                  checked={numEquationsAbsent}
                  aria-label="Checkbox for following text input"
                  onChange={(e) => {
                    setNumEquationsAbsent(e.target.checked);
                  }}
                />
                <span className="m-1">Number of Equations Unknown</span>
              </div>
            </div>
            <div className="input-group">
              <input
                type="search"
                name="search"
                className="form-control mt-2"
                placeholder="Search..."
                aria-label="Search"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <button type="submit" className="btn btn-primary mt-2">
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="container p-2">
        <div className="row">
          <h1>Comprehensions</h1>
          <ComprehensionsTable
            comprehensions={comprehensions}
            comprehensionAction={setSelectedComprehensionId}
            onComprehensionRefetch={onComprehensionRefetch}
          />
        </div>
      </div>
      {selectedComprehensionId !== '' && (
        <SelectedComprehension
          comprehensionId={selectedComprehensionId}
          onComprehensionRefetch={onComprehensionRefetch}
        />
      )}
    </div>
  );
}

function SelectedComprehension({
  comprehensionId,
  onComprehensionRefetch,
}: {
  comprehensionId: string;
  onComprehensionRefetch: (q: IComprehension) => void;
}) {
  const [error, setError] = React.useState<string>('');
  const [comprehension, setComprehension] = React.useState<IComprehension | null>(null);
  const [version, setVersion] = React.useState(0);
  React.useEffect(() => {
    Cloud.post(
      {
        action: PublicConstants.ACTION_GET_COMPREHENSION,
        data: { id: comprehensionId },
      },
      PublicConstants.API_QUESTION,
    ).then((response) => {
      if (!response.success) {
        setError(`Failed to fetch comprehension, please retry`);
        return;
      }
      setComprehension(response.value.comprehension);
    });
  }, [comprehensionId, version]);

  React.useEffect(() => {
    if (comprehension) onComprehensionRefetch(comprehension);
  }, [comprehension]);

  const [comprehensionSaveError, setComprehensionSaveError] = React.useState<string>('');

  if (!comprehension) {
    return null;
  }

  const translatedComprehension: TranslatedComprehension = comprehensionTranslatedSchema.parse(comprehension.content);

  const finalize = async (e: any) => {
    e.preventDefault();
    console.log('Finalizing comprehension', comprehensionId);
    // Check correctness of across languages
    if (!comprehension.correctness) {
      setError('English version correctness is not set');
      return;
    }

    // translation languages
    const translatedComprehension = comprehensionTranslatedSchema.parse(comprehension.content);
    const langs: string[] = [];
    translatedComprehension.translations.forEach((t) => {
      langs.push(t.lang);
    });
    comprehension.translationCorrectness.forEach((c) => {
      if (c.correctness) {
        langs.splice(langs.indexOf(c.lang), 1);
      }
    });

    if (langs.length > 0) {
      setError(
        'Correctness of translations is not set for languages: ' + langs.map((l) => languageCodeToString(l)).join(', '),
      );
      return;
    }

    if (!comprehension.conceptsTagged) {
      setError('Concepts for all the questions are not tagged');
      return;
    }

    if (!comprehension.numEquationsTagged) {
      setError('Number of equations are not tagged for all the questions');
      return;
    }

    if (!comprehension.imageConsistent) {
      setError('Comprehension is not image consistent');
      return;
    }

    // If code reachs here all questionInfo should be present
    invariant(comprehension.questionsInfo, 'questionInfo should be present');
    invariant(
      comprehension.questionsInfo.length === translatedComprehension.questions.length,
      'questionInfo length should be same as the number of questions',
    );

    // Check if the subjective questions have answer templates
    for (let i = 0; i < translatedComprehension.questions.length; i++) {
      const q = translatedComprehension.questions[i];
      if (q.type !== 'subjective') {
        continue;
      }

      const questionInfo = comprehension.questionsInfo[i];
      if (!questionInfo.answerTemplate) {
        setError(`Answer template not set for question ${i + 1}`);
        return;
      }
    }

    Cloud.post(
      {
        action: PublicConstants.ACTION_FINALIZE_COMPREHENSION,
        data: { id: comprehensionId },
      },
      PublicConstants.API_QUESTION,
    ).then((response) => {
      console.log('finalize', response);
      if (!response.success) {
        setError('Finalizing failed for question, please retry');
        return;
      }
      setError('');
      setVersion(version + 1);
    });
  };

  const saveComprehension = async (tc: TranslatedComprehension) => {
    setComprehensionSaveError('');
    Cloud.post(
      {
        action: PublicConstants.ACTION_UPDATE_COMPREHENSION,
        data: { id: comprehensionId, content: tc, update: PublicConstants.UPDATE_CONTENT },
      },
      PublicConstants.API_QUESTION,
    ).then((response) => {
      console.log('update', response);
      if (!response.success) {
        setComprehensionSaveError('Save failed for comprehension, please retry');
        return;
      }

      setVersion(version + 1);
    });
  };

  if (comprehension.finalized) {
    return (
      <div className="container p-2">
        <div className="row">
          <h1 className="my-2">Comprhension has been finalized and freezed</h1>
          <ErrorMessage error={error} />
          <RenderComprehension
            c={translatedComprehension}
            passageOnly={false}
            readonly={true}
            updateComprehension={(c: TranslatedComprehension) => {
              invariant(false, 'comprehension update not allowed');
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {comprehension && (
        <div className="container p-4">
          <div className="row">
            <h1 className="my-2">Comprehension Improvement!!</h1>
          </div>
          <div className="row ">
            <button className="btn btn-primary col-2 my-3" onClick={() => setVersion(version + 1)}>
              Refresh
            </button>
            <form className="d-inline p-0 m-0 col-10" onSubmit={finalize}>
              <button className="btn btn-primary col-2 m-3" type="submit">
                Finalize
              </button>
            </form>
            <ErrorMessage error={error} />
            <div className="col-12">
              <SubjectChapterForm comprehension={comprehension} refreshComprehension={() => setVersion(version + 1)} />
              <CorrectnessForm
                comprehension={comprehension}
                lang="en"
                refreshComprehension={() => setVersion(version + 1)}
              />
              <CorrectnessForm
                comprehension={comprehension}
                lang="hi"
                refreshComprehension={() => setVersion(version + 1)}
              />
              <CorrectnessForm
                comprehension={comprehension}
                lang="te"
                refreshComprehension={() => setVersion(version + 1)}
              />
              <ImageConsistentForm comprehension={comprehension} refreshComprehension={() => setVersion(version + 1)} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 my-2">
              <ErrorMessage error={comprehensionSaveError} />
              <RenderComprehension
                c={translatedComprehension}
                passageOnly={true}
                readonly={false}
                updateComprehension={saveComprehension}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {translatedComprehension.questions?.map((q: any, idx: number) => {
                const tq: TranslatedQuestion = questionTranslatedSchema.parse(q);
                return (
                  <>
                    <hr className="mt-1 mb-0 px-4 bg-black" />
                    <hr className="mb-0 mt-0 px-4 bg-black" />
                    <hr className="mb-1 mt-0 px-4 bg-black" />

                    <ConceptTagsForm
                      comprehension={comprehension}
                      refreshComprehension={() => setVersion(version + 1)}
                      idx={idx}
                    />
                    <NumberEquationsForm
                      comprehension={comprehension}
                      refreshComprehension={() => setVersion(version + 1)}
                      idx={idx}
                    />
                    {tq.type === 'subjective' && (
                      <AnswerTemplateForm
                        comprehension={comprehension}
                        refreshComprehension={() => setVersion(version + 1)}
                        idx={idx}
                      />
                    )}
                    <DifficultyLevelsForm
                      comprehension={comprehension}
                      refreshComprehension={() => setVersion(version + 1)}
                      idx={idx}
                    />
                    <RenderQuestion
                      question={tq}
                      readonly={false}
                      updateQuestion={(q: TranslatedQuestion) => {
                        translatedComprehension.questions[idx] = q;
                        saveComprehension(translatedComprehension);
                      }}
                    />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
