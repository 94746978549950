import { useEffect, useState } from 'react';
import { ErrorMessage } from '../../common/error-message';
import { MatrixMatchTranslatedQuestion } from '../schema';
import { htmlToString, languageCodeToString, numberToUpperCaseAlphabet, parseAnswerHTML } from '../../../utils/utils';
import TaggedStmtV2 from './tagged-stmt-v2';
import { WarnMessage } from '../../common/warn-message';
import { ProblemSaveEditStrip } from './problem-save-edit-strip';
import invariant from 'tiny-invariant';

export default function MatrixMatchQuestion({
  q,
  readonly,
  updateQuestion,
}: {
  q: MatrixMatchTranslatedQuestion;
  readonly: boolean;
  updateQuestion: (question: MatrixMatchTranslatedQuestion, updateInfo: any) => void;
}) {
  const [question, setQuestion] = useState<MatrixMatchTranslatedQuestion>(q);
  const [editing, setEditing] = useState(false);
  const [unCommittedChanges, setUnCommittedChanges] = useState(false);
  useEffect(() => {
    setQuestion(q);
  }, [q]);

  const setUnCommitted = () => {
    if (!unCommittedChanges) {
      setUnCommittedChanges(true);
    }
  };
  const matrixAns = question.mat_ans
    .map((row, index) => `${index + 1}: ${row.map((x) => String.fromCharCode(64 + x)).join(', ')}`)
    .join(' | ');

  const saveQuestion = () => {
    updateQuestion(question, { type: 'save' });
    setUnCommittedChanges(false);
  };
  return (
    <>
      <ErrorMessage error={question.err_conversion} />
      {unCommittedChanges && <WarnMessage warning={'Uncommitted Changes'} />}
      <ProblemSaveEditStrip
        readonly={readonly}
        editing={editing}
        setEditing={setEditing}
        save={() => {
          saveQuestion();
          setUnCommittedChanges(false);
        }}
      />
      <h3>English Problem</h3>
      <div className="row">
        <div className="col-6">
          {question.colA?.map((colA, colAIndex) => (
            <TaggedStmtV2
              key={colAIndex}
              tag={`${colAIndex + 1}`}
              htmlContent={colA}
              readonly={editing}
              onUpdate={(s: string) => {
                // @ts-ignore
                question.translations[index].colA[colAIndex] = s;
                setQuestion(question);
                setUnCommitted();
              }}
            />
          ))}
        </div>
        <div className="col-6">
          {question.colB?.map((colB, colBIndex) => (
            <TaggedStmtV2
              key={colBIndex}
              tag={`${numberToUpperCaseAlphabet(colBIndex + 1)}`}
              htmlContent={colB}
              readonly={editing}
              onUpdate={(s: string) => {
                // @ts-ignore
                question.translations[index].colB[colBIndex] = s;
                setQuestion(question);
                setUnCommitted();
              }}
            />
          ))}
        </div>
      </div>
      <TaggedStmtV2
        tag="Answer"
        htmlContent={JSON.stringify(question.mat_ans)}
        readonly={editing}
        onUpdate={(s: string) => {
          const resp = parseAnswerHTML(s || '', 'matrixMatch');
          if (resp.error) {
            alert(resp.error);
            return;
          }
          invariant(resp.mmAnswer, 'resp.mmAnswer is null');
          question.mat_ans = resp.mmAnswer;
          question.ans_absent = false;
          setQuestion(question);
          setUnCommitted();
        }}
      />
      <TaggedStmtV2
        tag="Solution"
        htmlContent={question.sol}
        readonly={editing}
        onUpdate={(s: string) => {
          question.sol = s;
          setQuestion(question);
          setUnCommitted();
        }}
      />
      {question.translations.map((translation, index) => (
        <div key={index}>
          <h3>{languageCodeToString(translation.lang)} Problem</h3>
          <div className="row">
            <div className="col-6">
              {translation.colA?.map((colA, colAIndex) => (
                <TaggedStmtV2
                  key={colAIndex}
                  tag={`${colAIndex + 1}`}
                  htmlContent={colA}
                  readonly={editing}
                  onUpdate={(s: string) => {
                    // @ts-ignore
                    question.translations[index].colA[colAIndex] = s;
                    setQuestion(question);
                    setUnCommitted();
                  }}
                />
              ))}
            </div>
            <div className="col-6">
              {translation.colB?.map((colB, colBIndex) => (
                <TaggedStmtV2
                  key={colBIndex}
                  tag={`${numberToUpperCaseAlphabet(colBIndex + 1)}`}
                  htmlContent={colB}
                  readonly={editing}
                  onUpdate={(s: string) => {
                    // @ts-ignore
                    question.translations[index].colB[colBIndex] = s;
                    setQuestion(question);
                    setUnCommitted();
                  }}
                />
              ))}
            </div>
          </div>
          <TaggedStmtV2
            tag="Answer"
            htmlContent={translation.mat_ans.toString()}
            readonly={editing}
            onUpdate={(s: string) => {
              const resp = parseAnswerHTML(s || '', 'matrixMatch');
              if (resp.error) {
                alert(resp.error);
                return;
              }
              invariant(resp.mmAnswer, 'resp.mmAnswer is null');
              question.translations[index].mat_ans = resp.mmAnswer;
              question.ans_absent = false;
              setQuestion(question);
              setUnCommitted();
            }}
          />
          <TaggedStmtV2
            tag="Solution"
            htmlContent={translation.sol}
            readonly={editing}
            onUpdate={(s: string) => {
              question.translations[index].sol = s;
              setQuestion(question);
              setUnCommitted();
            }}
          />
        </div>
      ))}
    </>
  );
}
