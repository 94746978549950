import { Concept, IComprehension, IQuestion } from '../../../lambda/question/question-schema';
import {
  comprehensionTranslatedSchema,
  questionTranslatedSchema,
  TranslatedQuestion,
} from '../components/question/schema';

export const isAnsPresent = (question: IQuestion) => {
  let content: TranslatedQuestion = questionTranslatedSchema.parse(question.content);
  switch (content.type) {
    case 'scq':
      return content.obj_ans !== 0;
    case 'mcq':
      return content.obj_ans.length !== 0;
    case 'assertionReason':
      return content.ar_ans !== 0;
    case 'fillInTheBlank':
      return content.ans.length !== 0;
    case 'subjective':
      return content.ans.length !== 0;
    case 'integerType':
      return !content.ans_absent;
    case 'matrixMatch':
      return !content.ans_absent;
    case 'trueFalse':
      return !content.ans_absent;
  }
};

export const isSolutionPresent = (question: IQuestion) => {
  const content: TranslatedQuestion = questionTranslatedSchema.parse(question.content);
  return content.sol.length !== 0;
};

export const isAnswerTemplatable = (question: IQuestion) => {
  const content: TranslatedQuestion = questionTranslatedSchema.parse(question.content);
  return content.type === 'subjective';
};

export const equationsDescriptionString = (
  description: {
    description: string;
    concepts: Concept[];
  }[],
) => {
  return description.map((e) => `${e.description}, concepts: ${e.concepts.map((c) => c.label).join(' ')}`).join(', ');
};

export const getQuestionsInfo = (comprehension: IComprehension) => {
  let questionsInfo = comprehension.questionsInfo;
  let numQuestions = comprehensionTranslatedSchema.parse(comprehension.content).questions.length;
  if (questionsInfo.length < numQuestions) {
    questionsInfo = questionsInfo.concat(
      Array(numQuestions - questionsInfo.length).fill({
        statementLength: [],
        solutionLength: [],
        calculationDifficulty: [],
        overallDifficulty: [],
        concepts: [],
        subjectiveOverallDifficulty: [],
        subjectiveCalculationDifficulty: [],
      }),
    );
  }

  return questionsInfo;
};

export const handleQuestionFormSubmission = (
  response: any,
  refreshQuestion: () => void,
  setFormError: React.Dispatch<React.SetStateAction<string>>,
  setEdit: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  refreshQuestion();
  if (!response.success) {
    setFormError('Error updating number of equations, please retry');
    return;
  }

  setEdit(false);
};

export const substituteTemplate = (
  question: TranslatedQuestion,
  parameterMap: {
    [key: string]: string;
  },
  evalMap: {
    [key: string]: string;
  },
): TranslatedQuestion => {
  // helper function to substitute template parameters in a string
  console.log('parameterMap', parameterMap);
  console.log('evalMap', evalMap);
  const substitute = (str: string) => {
    for (const key in evalMap) {
      let found = str.includes(key);
      while (found) {
        str = str.replace(key, evalMap[key]);
        found = str.includes(key);
      }
      // const regex = new RegExp(`AddFn(SingleDigitVar_1, SingleDigitVar_2)`, 'g');
      // str = str.replace(regex, evalMap[key]);
      // console.log(key, evalMap[key], str, str.includes(key), str.replace(regex, evalMap[key]));
    }

    for (const key in parameterMap) {
      const regex = new RegExp(`${key}`, 'g');
      str = str.replace(regex, parameterMap[key]);
    }
    return str;
  };

  if (question.type === 'subjective') {
    question.stmt = substitute(question.stmt);
    question.ans = substitute(question.ans);
    question.sol = substitute(question.sol);

    // Do in translations as well
    question.translations.forEach((translation, index) => {
      question.translations[index].stmt = substitute(translation.stmt);
      question.translations[index].ans = substitute(translation.ans);
      question.translations[index].sol = substitute(translation.sol);
    });
  } else if (question.type === 'scq') {
    question.stmt = substitute(question.stmt);
    if (question.options) {
      question.options = question.options.map((option) => substitute(option));
    }
    question.sol = substitute(question.sol);
    question.translations.forEach((translation, index) => {
      question.translations[index].stmt = substitute(translation.stmt);
      if (translation.options) {
        question.translations[index].options = translation.options.map((option) => substitute(option));
      }
      question.translations[index].sol = substitute(translation.sol);
    });
  } else if (question.type === 'mcq') {
    question.stmt = substitute(question.stmt);
    if (question.options) {
      question.options = question.options.map((option) => substitute(option));
    }
    question.sol = substitute(question.sol);
    question.translations.forEach((translation, index) => {
      question.translations[index].stmt = substitute(translation.stmt);
      if (translation.options) {
        question.translations[index].options = translation.options.map((option) => substitute(option));
      }
      question.translations[index].sol = substitute(translation.sol);
    });
  } else if (question.type === 'assertionReason') {
    question.assr = substitute(question.assr);
    question.rsn = substitute(question.rsn);
    question.sol = substitute(question.sol);
    question.translations.forEach((translation, index) => {
      question.translations[index].assr = substitute(translation.assr);
      question.translations[index].rsn = substitute(translation.rsn);
      question.translations[index].sol = substitute(translation.sol);
    });
  } else if (question.type === 'fillInTheBlank') {
    question.stmt = substitute(question.stmt);
    question.ans = substitute(question.ans);
    question.sol = substitute(question.sol);

    // Do in translations as well
    question.translations.forEach((translation, index) => {
      question.translations[index].stmt = substitute(translation.stmt);
      question.translations[index].ans = substitute(translation.ans);
      question.translations[index].sol = substitute(translation.sol);
    });
  } else if (question.type === 'integerType') {
    question.stmt = substitute(question.stmt);
    question.sol = substitute(question.sol);

    // Do in translations as well
    question.translations.forEach((translation, index) => {
      question.translations[index].stmt = substitute(translation.stmt);
      question.translations[index].sol = substitute(translation.sol);
    });
  } else if (question.type === 'matrixMatch') {
    if (question.colA) {
      question.colA = question.colA.map((col) => substitute(col));
    }
    if (question.colB) {
      question.colB = question.colB.map((col) => substitute(col));
    }
    question.sol = substitute(question.sol);
    question.translations.forEach((translation, index) => {
      if (translation.colA) {
        question.translations[index].colA = translation.colA.map((col) => substitute(col));
      }
      if (translation.colB) {
        question.translations[index].colB = translation.colB.map((col) => substitute(col));
      }
      question.translations[index].sol = substitute(translation.sol);
    });
  }

  return question;
};
