import { createColumnHelper } from '@tanstack/react-table';
import React, { useEffect } from 'react';
import Select from 'react-select';
import { Cloud } from '../../core/cloud';
import { PublicConstants } from '../../core/constants';
import TanstackTable from '../common/tanstack-table';
import { IComprehension } from '../../../../lambda/question/question-schema';
import { ErrorMessage } from '../common/error-message';
import makeAnimated from 'react-select/animated';

export const animatedComponents = makeAnimated();

export function ComprehensionsTable({
  comprehensions,
  comprehensionAction,
  onComprehensionRefetch,
}: {
  comprehensions: any[];
  comprehensionAction: React.Dispatch<React.SetStateAction<string>>;
  onComprehensionRefetch: (c: IComprehension) => void;
}) {
  const columnHelper = createColumnHelper<any>();
  const [attachComprehensionId, setAttachComprehensionId] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');
  const [editModal, setEditModal] = React.useState<boolean>(false);
  const [showAttachModal, setShowAttachModal] = React.useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = React.useState<{ value: string; label: string }[]>([]);
  const [options, setOptions] = React.useState<{ value: string; label: string }[]>([]);

  // When rendering first time, fetch the content quantums
  useEffect(() => {
    Cloud.post(
      {
        action: PublicConstants.ACTION_GET_CONTENT_QUANTUMS,
        data: {},
      },
      PublicConstants.API_QUESTION,
    ).then((response) => {
      console.log('ACTION_GET_CONTENT_QUANTUMS', response);
      if (response.value.quantums) {
        if (response.value.quantums.length === 0) {
          return;
        }
        setOptions(
          response.value.quantums.map((c: any) => {
            // remove batch Id in label
            let label = c.id;
            const parts = label.split('::');
            if (parts.length === 4) {
              label = parts[0] + '::' + parts[1] + '::' + parts[3];
            }
            return { value: c.id, label: label };
          }),
        );
      }
    });
  }, []);

  const [attachComprehension, setAttachedComprehension] = React.useState<IComprehension | null>(null);
  const [version, setVersion] = React.useState(0);
  React.useEffect(() => {
    if (!attachComprehensionId) return;
    console.log('fetching comprehension', attachComprehensionId);
    Cloud.post(
      {
        action: PublicConstants.ACTION_GET_COMPREHENSION,
        data: { id: attachComprehensionId },
      },
      PublicConstants.API_QUESTION,
    ).then((response) => {
      if (!response.value.comprehension) {
        setError(`comprehension for id ${attachComprehensionId} not found, please retry`);
        return;
      }
      setAttachedComprehension(response.value.comprehension);
    });
  }, [attachComprehensionId, version]);

  React.useEffect(() => {
    if (attachComprehension) onComprehensionRefetch(attachComprehension);
  }, [attachComprehension]);

  let selectedQuantums: string[] = [];
  if (attachComprehensionId) {
    const comprehension = comprehensions.find((c) => c._id === attachComprehensionId);
    if (comprehension) {
      selectedQuantums = comprehension.contentQuantumIds || [];
    }
  }

  const handleChange = (options: any) => {
    // Check the validity and if not show the error and disable the submit button.
    setSelectedOptions(options);
  };

  const hindiCorrectness = (c: IComprehension) => {
    c.translationCorrectness = c.translationCorrectness || [];
    const hindiCorrectness = c.translationCorrectness.find((t) => t.lang === 'hi');
    if (!hindiCorrectness) return 'Unknown';
    return hindiCorrectness.correctness ? 'Correct' : 'Incorrect';
  };

  const englishCorrectness = (c: IComprehension) => {
    if (c.correctness === undefined) return 'Unknown';
    return c.correctness ? 'Correct' : 'Incorrect';
  };

  const englishCorrectnessAuthor = (c: IComprehension) => {
    if (c.correctness === undefined) return 'N/A';
    return c.correctnessAuthor;
  };

  const hindiCorrectnessAuthor = (c: IComprehension) => {
    c.translationCorrectness = c.translationCorrectness || [];
    const hindiCorrectness = c.translationCorrectness.find((t) => t.lang === 'hi');
    if (!hindiCorrectness) return 'N/A';
    return hindiCorrectness.author;
  };

  const imageConsistent = (i: undefined | boolean) => {
    if (i === undefined) return 'Unknown';
    return i ? 'Yes' : 'No';
  };

  const defaultColumns = [
    columnHelper.group({
      id: 'Info',
      header: 'Info',
      footer: (props) => props.column.id,
      columns: [
        columnHelper.accessor((row) => `${row.chapter ?? 'Unknown'}`, {
          id: 'Chapter',
          header: 'Chapter',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue(),
        }),
        columnHelper.accessor((row) => `${row.conceptsTagged ? 'Yes' : 'No'}`, {
          id: 'Concepts Tagged',
          header: 'Concepts Tagged',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue(),
        }),
        columnHelper.accessor((row) => `${row.numEquationsTagged ? 'Yes' : 'No'}`, {
          id: 'Num Equations Set',
          header: 'Num Equations Set',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue(),
        }),
        columnHelper.accessor((row) => `${imageConsistent(row.imageConsistent)}`, {
          id: 'Image Consistent',
          header: 'Image Consistent',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue(),
        }),
        columnHelper.accessor((row) => `${row.finalized ? 'Yes' : 'No'}`, {
          id: 'Finalized',
          header: 'Finalized',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue(),
        }),
      ],
    }),
    // group column.
    columnHelper.group({
      id: 'Correctness',
      header: 'Correctness',
      footer: (props) => props.column.id,
      columns: [
        columnHelper.accessor((row) => englishCorrectness(row), {
          id: 'English',
          header: 'English',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue(),
        }),
        columnHelper.accessor((row) => englishCorrectnessAuthor(row), {
          id: 'English Checker',
          header: 'English Checker',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue(),
        }),
        columnHelper.accessor((row) => hindiCorrectness(row), {
          id: 'Hindi',
          header: 'Hindi',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue(),
        }),
        columnHelper.accessor((row) => hindiCorrectnessAuthor(row), {
          id: 'Hindi Checker',
          header: 'Hindi Checker',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue(),
        }),
      ],
    }),
    columnHelper.display({
      id: 'View',
      header: 'View',
      footer: (props) => props.column.id,
      cell: (props) => (
        <button
          className="btn btn-primary"
          onClick={() => {
            console.log(props.row.original._id);
            comprehensionAction(props.row.original._id);
          }}
        >
          View
        </button>
      ),
    }),
    columnHelper.group({
      id: 'Attachment with content quanta',
      header: 'Attachment with content quanta',
      footer: (props) => props.column.id,
      columns: [
        columnHelper.accessor((row) => `${row.contentQuantumIds && row.contentQuantumIds.length ? 'Yes' : 'No'}`, {
          id: 'Attached',
          header: 'Attached',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue(),
        }),
        columnHelper.display({
          id: 'Attach',
          header: 'Attach',
          footer: (props) => props.column.id,
          cell: (props) => (
            <button
              className="btn btn-primary"
              onClick={() => {
                setAttachComprehensionId(props.row.original._id);
                setShowAttachModal(true);
              }}
            >
              Attach
            </button>
          ),
        }),
      ],
    }),
    columnHelper.group({
      id: 'Remaining Info',
      header: 'Remaining Info',
      footer: (props) => props.column.id,
      columns: [
        columnHelper.accessor((row) => `${row._id}`, {
          id: 'Id',
          header: 'Id',
          footer: (props) => props.column.id,
          cell: (info) => info.getValue(),
        }),
      ],
    }),
  ];
  return (
    <>
      <div className="modal" style={showAttachModal ? { display: 'block', width: 'w-full' } : { display: 'none' }}>
        <form className="modal-content modal-animate">
          <div className="modal-header">
            <div className="container">
              <div className="row">
                <div className="col-5">
                  <h6 className="modal-title">Attach Content Quantums</h6>
                </div>
                <div className="col-5">
                  {selectedQuantums.length === 0 ? (
                    <span className="text-warning">Unknown</span>
                  ) : (
                    selectedQuantums.map((q: string) => (
                      <span key={q} className="text-success">
                        {q}
                      </span>
                    ))
                  )}
                </div>
                <div className="col-2">
                  <button type="button" className="btn btn-primary" onClick={() => setEditModal(!editModal)}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={() => setShowAttachModal(false)}
              aria-label="Close"
            ></button>
          </div>
          {editModal && (
            <>
              <div className="modal-body">
                <label htmlFor="ConceptTags" className="form-label">
                  <h6>Content Quantums</h6>
                </label>
                <Select
                  defaultValue={[]}
                  isMulti
                  name="colors"
                  options={options}
                  components={animatedComponents}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleChange}
                  required={true}
                  id="ConceptTags"
                />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowAttachModal(false)}>
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    Cloud.post(
                      {
                        action: PublicConstants.ACTION_UPDATE_COMPREHENSION_CONTENT_QUANTUM_LINKS,
                        data: {
                          id: attachComprehensionId,
                          contentQuantumIds: selectedOptions.map((so) => so.value),
                        },
                      },
                      PublicConstants.API_QUESTION,
                    ).then((response) => {
                      if (!response.success) {
                        setError('Failed to attach comprehension to content quanta, please retry');
                        return;
                      }

                      setError('');
                      console.log('ACTION_UPDATE_COMPREHENSION_CONTENT_QUANTUM_LINKS', response);
                      setShowAttachModal(false);
                      setEditModal(false);
                      setVersion(version + 1);
                    });
                  }}
                >
                  Save
                </button>
              </div>
            </>
          )}
        </form>
      </div>
      <ErrorMessage error={error} />
      <TanstackTable columns={defaultColumns} data={comprehensions} />
    </>
  );
}
